function Checkbox({ id,title, ...rest }) {
  return (
    <div className="flex py-2">
      <input
      id={id}
        type="checkbox"
        {...rest}
        className="checked:bg-forange-400 indeterminate:bg-forange-400 rounded focus:outline-none my-1"
        onClick={(e) => e.stopPropagation()}
      />
      <label for={id} className="px-2 cursor-pointer">{title && title}</label>
    </div>
  );
}

export default Checkbox;

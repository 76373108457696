import { isEqual } from "lodash";
import { useState, useEffect, useContext, useCallback } from "react";
import { getApi } from "../tools/axiosInstances";
import { PortalContext } from "../PortalContext";

const initialize = () => {
  const allCategories = JSON.parse(sessionStorage.getItem("allCategories"));
  return allCategories || false;
};

export const useCategory = () => {
  const [categories, setCategories] = useState(initialize);
  const { refresh } = useContext(PortalContext);
  const handleFetchCategories = useCallback(async () => {
    getApi("/category/", {}).then((list) => {
      if (!isEqual(list.data, sessionStorage.getItem("allCategories"))) {
        setCategories(list.data);
        sessionStorage.setItem("allCategories", JSON.stringify(list.data));
      }
    });
  }, []);
  useEffect(() => {
    handleFetchCategories();
  }, [handleFetchCategories, refresh]);

  return categories;
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getApi } from "../../tools/axiosInstances";
function OrganizationInfo() {
  const { t } = useTranslation("global");
  const [orgInfo, setOrgInfo] = useState(false);
  useEffect(() => {
    getApi("/settings/org-info/", {}).then((data) => {
      setOrgInfo(data.data);
    });
  }, []);

  return (
    <>
      <div className="w-full p-4 align-items-center">
        <div className="py-4 flex">
          <p className="w-1/3 px-2 py-2 mx-2">
            Tenant Id
          </p>
          {orgInfo ? (
            <div className="w-2/3 px-2 py-2 mx-2 border rounded border-fgray-200">
              {" "}
              {orgInfo.id}
            </div>
          ) : (
            <div className="w-2/3 px-2 py-2 mx-2 h-8 bg-fgray-200 border rounded border-fgray-200 animate-pulse" />
          )}
        </div>
        <div className="py-4 flex">
          <p className="w-1/3 px-2 py-2 mx-2">
            {t("pages.settings.org_info.tenant_name")}
          </p>
          {orgInfo ? (
            <div className="w-2/3 px-2 py-2 mx-2 border rounded border-fgray-200">
              {" "}
              {orgInfo.displayName}
            </div>
          ) : (
            <div className="w-2/3 px-2 py-2 mx-2 h-8 bg-fgray-200 border rounded border-fgray-200 animate-pulse" />
          )}
        </div>
        <div className="py-4 flex">
          <p className="w-1/3 px-2 py-2 mx-2">
            {t("pages.settings.org_info.total_devices")}
          </p>
          {orgInfo ? (
            <div className="w-2/3 px-2 py-2 mx-2 border rounded border-fgray-200">
              {" "}
              {orgInfo.devicesCount}
            </div>
          ) : (
            <div className="w-2/3 px-2 py-2 mx-2 h-8 bg-fgray-200 border rounded border-fgray-200 animate-pulse" />
          )}
        </div>
      </div>
    </>
  );
}

export default OrganizationInfo;

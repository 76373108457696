import React from 'react'

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type='checkbox' ref={resolvedRef} {...rest} className="checked:bg-forange-400 indeterminate:bg-forange-400 rounded focus:outline-none" onClick={(e) => e.stopPropagation()} />
    </>
  )
})

export default IndeterminateCheckbox
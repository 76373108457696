import React from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "./BaseButton";

function StandardButton({ title, loading, children, ...attributes }) {
  const { t } = useTranslation("global");
  return (
    <BaseButton
      className={`${loading && " animate-pulse cursor-progress "}`}
      {...attributes}
    >
      {loading ? t("words.submitting") : title || children}
    </BaseButton>
  );
}

export default StandardButton;

import React, { useState } from "react";
import PageTitle from "../components/layout/PageTitle";
import { useNavigate } from "react-router-dom";
import ImportDeviceTable from "../components/device/importDevice/ImportDeviceTable";
import { ReactComponent as ArrobBackIcon } from "../components/icons/arrowBack.svg";
import { ReactComponent as ImportIcon } from "../components/icons/add.svg";
import { ReactComponent as DownloadLogo } from "../components/icons/download.svg";
import DropzoneUpload from "../components/device/importDevice/DropzoneUpload";
import { useTranslation } from "react-i18next";
import IconButton from "../components/layout/buttons/IconButton";
import Tooltip from "../components/layout/tooltip/Tooltip";
import { postApi } from "../tools/axiosInstances";

function AddDevice({ refresh }) {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const [loadedData, setLoadedData] = useState([]);
  const [valid, setValid] = useState(false);
  const [validData, setValidData] = useState([]);
  const handleUploadButton = ()=>{
    document.getElementById("dropzone").click()
  }
  const handleSubmitImport = () => {
    let task_payload = { kind: "cre", target: "dev", detail: [] };
    if (validData.length) {
      validData.map((req) => {
        switch (req.valid) {
          case "hhs":
            task_payload.detail.push({
              method: "hhs",
              hardware_hash: req.hhs,
              product_key: req.msid,
              serial_number: req.serialnumber,
              group_tag: req.category.selectedValue,
            });
            break;
          case "mpi":
            task_payload.detail.push({
              method: "mpi",
              product_id: req.msid,
              group_tag: req.category.selectedValue,
            });
            break;
          case "smm":
            task_payload.detail.push({
              method: "smm",
              model: req.model.selectedValue,
              serial_number: req.serialnumber,
              group_tag: req.category.selectedValue,
            });
            break;
          default:
            break;
        }
      });
      postApi("/tasks/",task_payload).then(()=>navigate("/progress"))
    }
  };
  return (
    <>
      <PageTitle
        title={t("pages.adddevice.title")}
        Icon={ArrobBackIcon}
        iconOnClick={() => navigate(-1)}
      >
        <IconButton
            Icon={DownloadLogo}
            iconClass="rotate-180"
            onClick={handleUploadButton}
            subtle
          >
            {t("pages.adddevice.upload_csv")}
          </IconButton>
        <Tooltip
          position="bottom"
          title={
            valid
              ? t("tooltip.adddevice.submit")
              : t("tooltip.adddevice.submit_disabled")
          }
        >
          <IconButton
            Icon={ImportIcon}
            disabled={!valid}
            onClick={handleSubmitImport}
          >
            {t("pages.adddevice.submit")}
          </IconButton>
        </Tooltip>
      </PageTitle>

      <div className="mx-auto p-5 flex h-[calc(100vh-12rem)] overflow-hidden ">
        <div className="min-w-[calc((100%-72rem)/2)]"></div>
        <div className="w-[72rem] justify-end mr-5 mb-5 overflow-y-auto">
          <DropzoneUpload setLoadedData={setLoadedData} />
          <ImportDeviceTable
            loadedData={loadedData}
            setValidData={setValidData}
            setValid={setValid}
          />
        </div>
      </div>
    </>
  );
}

export default AddDevice;

import { useContext } from "react";
import { PortalContext } from "../PortalContext";

export const useOnboarded = () => {
  const {
    deviceMode,
    ipadOnboarded,
    ipadPolicyManagement,
    macosOnboarded,
    macosPolicyManagement,
    windowsPolicyManagement,
  } = useContext(PortalContext);
  const showWindows = deviceMode === "windows";
  const showIpad =
    deviceMode === "ipad" && ipadOnboarded === "requested"
      ? "requested"
      : ipadOnboarded;
  const showMacos =
    deviceMode === "macos" && macosOnboarded === "requested"
      ? "requested"
      : macosOnboarded;
  const showIpadPolicy = deviceMode === "ipad" && ipadPolicyManagement;
  const showMacosPolicy = deviceMode === "macos" && macosPolicyManagement;
  const showWindowsPolicy = deviceMode === "windows" && windowsPolicyManagement;
  const onboarded = () => {
    switch (deviceMode) {
      case "ipad":
        return showIpad;
        break;
      case "macos":
        return showMacos;
        break;
      case "windows":
      default:
        return true;
        break;
    }
  };
  return {
    showWindows,
    showIpad,
    showMacos,
    showIpadPolicy,
    showMacosPolicy,
    showWindowsPolicy,
    onboarded,
  };
};

import React from "react";
import Tooltip from "../tooltip/Tooltip";

function ListItem({ label, children, border, labelTooltip, valueTooltip }) {
  return (
    <div className="justify-start items-center my-4 flex">
      <div className="w-1/5  text-base text-fgray-400">
        {labelTooltip ? (
          <Tooltip title={labelTooltip}>{label} </Tooltip>
        ) : (
          label
        )}
      </div>

      <div
        className={`justify-start items-center p-2 rounded-lg flex ${
          border && "border min-w-[400px]"
        } `}
      >
        {valueTooltip ? (
          <Tooltip title={valueTooltip}>{children}</Tooltip>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default ListItem;

import React,{useEffect,useRef} from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../layout/loading/Spinner";
import { postApi,getApi } from "../../tools/axiosInstances";
// import { grantAuthorization } from '../../tools/authConfig';
import axios from "axios";
import MultilineTranslation from "../layout/MultilineTranslation";
import { Providers } from "@microsoft/mgt-element";
function FirstOnboarding({ authURL,setIsAuthorized }) {
  const { t } = useTranslation("global");
  async function grantAuthorization(){
    postApi("/authenticate/authorize/",{})
  .then((response) => {
    if(response.status == 200){
    getApi("/category/onboard/",{})
    .then(()=>{
    sessionStorage.setItem("isAuthorized",true)
    setIsAuthorized(true)
    window.location.replace(process.env.REACT_APP_REDIRECT_URI)});
    return response
  }
  })
  
  
}
useInterval(()=>{
  
    const me = JSON.parse(sessionStorage.getItem("me"));
    Providers.globalProvider.getAccessToken().then((token) => {
      let apiBody = {
        username: me.userPrincipalName,
        password: me.id,
        msToken: token,
      };
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/authenticate/`,
          apiBody
        )
        .then((response) => {
          if (response.data["adminConsent"]){
            sessionStorage.setItem("apiAuth", JSON.stringify(response.data));
            grantAuthorization();
          }
        });
    });
},10000)



  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between">
          <div className="text-2xl font-medium flex">
          <span className="mx-5"><Spinner/> </span> 
           {t("pages.authentication.onboarding.title")}
          </div>
        </div>
      </div>
      {/*Main Container*/}
      <div className="p-6 max-w-6xl mx-auto space-y-6 overflow-y-auto overflow-x-hidden">
        <MultilineTranslation text={t("pages.authentication.onboarding.body")} />
      </div>
    </>
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default FirstOnboarding;

import React, { useEffect, useState, useRef } from "react";
import { useToggleComponent } from "../../tools/hooks";
import { ReactComponent as ColumnIcon } from "../icons/columns.svg";
import { useTranslation } from "react-i18next";
import Dropdown from "../layout/design/Dropdown";
import DropdownItem from "../layout/design/DropdownItem";
function DeviceTableColumnSelect({ columns, setColumns }) {
  const { t } = useTranslation("global");
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded(!expanded);
  const wrapperRef = useRef(null);
  useToggleComponent(wrapperRef, setExpanded);
  
  return (
    <>
      <div className="m-auto" ref={wrapperRef}>
        <div className={`flex px-2 py-1 cursor-pointer`} onClick={toggle}>
          <ColumnIcon className="h-5 w-5 m-auto" />
          <p className="text-center text-sm font-medium px-2 truncate m-auto">
            {t("words.columns")}
          </p>
        </div>
        {expanded && (
          // <div className="absolute z-20">
            <Dropdown>
              {columns &&
                columns.map((column,index) => (
                  <DropdownItem key={`ddi${index}`} checkbox checked={column.visible} onClick={setColumns} id={column.id}>
                    {t(column.label,column.label)}
                  </DropdownItem>
                ))}
            </Dropdown>
          // </div>
        )}
      </div>
    </>
  );
}

export default DeviceTableColumnSelect;

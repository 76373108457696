import React, { useState } from "react";

function PasswordBox({ definition, mode, profileId, visible }) {
  const [valid, setValid] = useState(true);
  const [textValue, setTextValue] = useState(definition.selected || "");
  const [boxType, setBoxType] = useState(() =>
    definition.selected ? true : false
  );
  const [show, setShow] = useState(false);
  function handleChange(e) {
      setTextValue(e.target.value);
      if (e.target.value.match(definition.validator) != null) {
        setValid(true);
        const input = document.getElementById(profileId);
        Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "checked"
        ).set.call(input, true);
        input.dispatchEvent(new Event("click", { bubbles: true }));
      } else {
        setValid(false);
        const input = document.getElementById(profileId);
        Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "checked"
        ).set.call(input, false);
        input.dispatchEvent(new Event("click", { bubbles: true }));
      }
  }
  const onFocusHandler = () => {
    !definition?.visible && setTextValue("");
    setBoxType(false);
  };
  return (
    <input
      type={boxType ? "password" : "text"}
      name={definition.name}
      id={definition.id}
      required
      placeholder={definition.hint}
      className={`p-2 ml-4 mr-8 my-2 w-full flex-1 text-sm rounded-lg border border-fgray-400 ${
        valid ? "" : " bg-red-200 text-red-800 "
      } `}
      onChange={handleChange}
      disabled={!mode}
      value={textValue}
      onFocus={onFocusHandler}
    />
  );
}

export default PasswordBox;

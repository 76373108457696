import React from "react";
import {ReactComponent as ArrowIcon} from "../../icons/arrow.svg"
function Chip({ arrow,children, ...attributes }) {
  return (
    <div
      {...attributes}
      className="rounded-lg border border-fgray-300 justify-center px-5 py-1.5 align-middle hover:bg-forange-300 focus:border-forange-400 focus:border-2 disabled:text-fgray-300 disabled:bg-fwhite flex space-x-1 text-base font-normal"
    >
      {children}
      {arrow && <ArrowIcon className="rotate-180"/>}
    </div>
  );
}

export default Chip;

import React from "react";
import BaseButton from "./BaseButton";

function IconButton({ Icon,iconClass, children, ...attributes }) {
  return (
    <BaseButton {...attributes}>
      <span className="flex space-x-1">
        {Icon && <Icon className={`h-6 w-6 ${iconClass}`} />}
        <span>{children}</span>
      </span>
    </BaseButton>
  );
}

export default IconButton;

import React from "react";
import { useTranslation } from "react-i18next";
import { useRoom } from "../../hooks/useRoom";

function RoomCombo({ setSelected, includeUnassigned }) {
  const { t } = useTranslation("global");
  const roomList = useRoom()

  function handleChange(event) {
    setSelected(event.target.value);
  }
  return (
    <select
      name="Rooms"
      id="rooms"
      onChange={handleChange}
      defaultValue={t("rooms.modal.choose")}
      className="w-full rounded-xl border border-fgray-300 py-2 px-4 text-fblack"
    >
      <option value="0">{t("rooms.modal.choose")}</option>
      {roomList &&
        roomList.map(({ id, displayName }) => (
          <option value={id} key={`rom_${id}`}>
            {displayName}
          </option>
        ))}
      {includeUnassigned && (
        <option
          value={"00000000-0000-0000-0000-000000000000"}
          key={"rom_00000000-0000-0000-0000-000000000000"}
        >
          {t("rooms.name.no_room")}
        </option>
      )}
    </select>
  );
}

export default RoomCombo;

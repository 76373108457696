import React from "react";
import { useTranslation } from "react-i18next";
import MultilineTranslation from "./MultilineTranslation";
function NotificationModal({ title, body, toggle }) {
  const { t } = useTranslation("global");

  return (
    <>
      {/* overlay */}
      <div className="fixed inset-0 bg-fgray-400 bg-opacity-50 overflow-none h-full z-0 w-full">
        {/* Modal content  */}
        <div
          className="relative top-60 mx-auto p-5 border w-96 shadow-lg rounded-lg bg-white z-20"
          onClick={(e) => e.stopPropagation}
        >
          {/* Title */}
          <div className="mt-3 flex text-center">
            <h1 className="text-lg font-medium text-black">
              {t(title)}
            </h1>
            <div className="justify-end" onClick={toggle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          {/* Content */}
          <div className="mt-2 text-justify">
            <p className=" text-fgray-300 text-left py-2">
            <MultilineTranslation
                    text={t(body)}
                  />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationModal;

import React, { useState, useEffect } from "react";
import TaskStatusSpinner from "../progress/TaskStatusSpinner";
import HrzSwitch from "./HrzSwitch";
function ProfileStatus({
  profile,
  scope,
  mode,
  reload,
  parentStatus,
  setParameters,
  status,
  setStatus,
  checkRevertable,
}) {
  const [profileResponse, setProfileResponse] = useState(false);
  useEffect(() => {
    setStatus("pending");
    setParameters(false);
    // getApi(
    //   `/policy/profiles/${profile.id}/status/${scope}/v2/`,
    //   {},
    //   controller.signal
    // )
    //   .then((response) => {
    //     setStatus(response.data.enabled);
    //     sessionStorage.setItem(profile.id, response.data.enabled);
    //     setProfileResponse(response.data);
    //     setParameters(response.data.parameters);
    //     checkRevertable(response.data.revertable);
    //   })
    //   .catch((err) => {
    //     if (!canceled(err)) {
    //       console.error(err);
    //     }
    //   });
    // return () => {
    //   controller.abort();
    // };
  }, [scope, reload]);

  function valueChange(e){
    const values = JSON.parse(e.target.value)
        setStatus(values.enabled);
        sessionStorage.setItem(profile.id, values.enabled);
        setProfileResponse(values);
        setParameters(values.parameters);
        checkRevertable(values.revertable)
  }

  return (<>
    <input type="hidden" id={`status_${profile.id}`} value="" onInput={valueChange} />
    {status === "pending" ? (
      <TaskStatusSpinner progress="5" />
    ) : (
      <HrzSwitch
        profile={profile}
        status={status}
        setStatus={setStatus}
        editable={profileResponse["editable"]}
        editMode={mode}
        parentStatus={parentStatus}
      />
    )}
  </>);
}

export default ProfileStatus;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import MultilineTranslation from "../layout/MultilineTranslation";
import { Providers } from "@microsoft/mgt-element";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";
import StandardButton from "../layout/buttons/StandardButton";
function OffboardModal({ toggle }) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [enableOffboard, setEnableOffboard] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(
    Math.floor(Math.random() * 10000 + 1)
  );
  const wrapper = useRef(false);
  useToggleComponent(wrapper, toggle);
  const handleUserCode = (e) => {
    setUserCode(e.target.value);
    setEnableOffboard(e.target.value == confirmationCode);
  };
  const handleOffboard = () => {
    if (enableOffboard) {
      let reqBody = { kind: "unm", target: "set", type: "admin", detail: [] };
      postApi("/tasks/", reqBody).then(() => {
        sessionStorage.clear();
        Providers.globalProvider.logout();
        toggle();
        setLoading(false);
      });
    }
  };
  return (
    <>
      <ModalCard wide>
        <ModalCardTitle>
          {t("pages.settings.modal.offboard.title")}
        </ModalCardTitle>
        <ModalCardBody>
          <div className=" text-fgray-400 text-left">
            <MultilineTranslation
              text={t(
                "pages.settings.modal.offboard.advisory",
                confirmationCode
              )}
            />
          </div>
          <div className=" text-fgray-400 text-left">
            {t("pages.settings.modal.offboard.code_request", {
              code: confirmationCode,
            })}
          </div>
          <input
            type="input"
            name={confirmationCode}
            // placeholder={t("pages.settings.modal.offboard.confirmation_code")}
            className="w-24 p-2 border rounded-lg focus:outline-none"
            value={userCode}
            onChange={handleUserCode}
          />
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t(`pages.settings.modal.add.cancel`)}
          </StandardButton>
          <StandardButton onClick={handleOffboard} disabled={!enableOffboard}>
            {t(`pages.settings.offboard.remove_all`)}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default OffboardModal;

import React, { useState, useRef } from "react";
import RoleDropdown from "./RoleDropdown";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import MultilineTranslation from "../layout/MultilineTranslation";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard ,{
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";
import StandardButton from "../layout/buttons/StandardButton";
function EditAdminModal({ refresh, toggle, selected, allAdmins }) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("smp.administrator");
  const wrapper = useRef(false);
  useToggleComponent(wrapper, toggle);
  function handleEditAdmin() {
    setLoading(true);
    let reqBody = { kind: "rnm", target: "set", type: "admin", detail: [] };
    for (let index = 0; index < selected.length; index++) {
      console.log(selected);
      const user = selected[index].original;
      reqBody.detail.push({
        userPrincipalName: user.userPrincipalName,
        displayName: user.displayName,
        role: role,
      });
    }
    postApi("/tasks/", reqBody).then(() => {
      toggle();
      refresh();
      setLoading(false);
    });
  }
  const isSaveAllowed = () => {
    if (role === "smp.administrator") {
      return true;
    } else if (role === "smp.device") {
      const deviceAdmins = allAdmins.filter(
        (item) => item.role == "smp.device"
      );
      if (
        selected.length + deviceAdmins.length >= allAdmins.length || // If already device + selected = all
        allAdmins.length === selected.length // If all are selected
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      <ModalCard wrapper={wrapper} wide>
        <ModalCardTitle>{t("pages.settings.modal.edit.title")}</ModalCardTitle>
        <ModalCardBody>
          <MultilineTranslation
            text={t("pages.settings.modal.edit.user", {
              count: selected.length,
              user: selected[0].original.principalDisplayName,
            })}
          />
          <div className="mt-2 text-justify">
            {t("pages.settings.modal.add.role")}
            <RoleDropdown setRole={setRole} role={role} />
          </div>
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t("pages.device.modal.cancel")}
          </StandardButton>
          <StandardButton
            onClick={handleEditAdmin}
            disabled={!isSaveAllowed()}
            loading={loading}
          >
            {t("words.save")}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default EditAdminModal;

import React, { useState } from "react";

function HorizontalSwitch({ enabled,id,setEditMode, ...properties }) {
  const [checked, setChecked] = useState(enabled||false)
  const toggleCheck = ()=>{setChecked(!checked) 
    setEditMode && setEditMode()}
  return (
    <>
      <label
        htmlFor={id}
        className={`inline-flex relative items-center cursor-pointer`}
      >
        <input
        id={id}
          data-tooltip-target="tooltip-default"
          type="checkbox"
          value=""
          className="sr-only peer"
          checked={checked}
          onChange={toggleCheck}
          {...properties}
        />
        <div className="w-11 h-6 bg-fgray-300 peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-forange-400"></div>
      </label>{" "}
    </>
  );
}

export default HorizontalSwitch;

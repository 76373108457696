import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "../components/layout/Sidebar";
import ApplicationTable from "../components/application/ApplicationTable";
import LoadingTable from "../components/layout/loading/LoadingTable";
import RefreshButton from "../components/layout/buttons/RefreshButton";
import DeviceModeBar from "../components/layout/DeviceModeBar";
import ApplicationActionModalButton from "../components/application/ApplicationActionModalButton";
import { ReactComponent as UnmanageLogo } from "../components/icons/unmanage.svg";
import { ReactComponent as UninstallLogo } from "../components/icons/uninstall.svg";
import { ReactComponent as InstallLogo } from "../components/icons/install.svg";
import { ReactComponent as RemoveLogo } from "../components/icons/remove.svg";
import { useCheckLicenseStatus } from "../tools/utilties";
import { useApplication } from "../hooks/useApplication";
import PageTitle from "../components/layout/PageTitle";
import PageContent, { PageContentBody } from "../components/layout/PageContent";
import { useOnboarded } from "../hooks/useOnboarded";
import Onboarding from "../components/device/Onboarding";
function Application() {
  const { t } = useTranslation("global");
  const globalId = "99999999-9999-9999-9999-999999999999";
  const [filter, setFilter] = useState(globalId);
  const [selected, setSelected] = useState([]);
  const [clear, setClear] = useState(0);
  useCheckLicenseStatus();
  const { onboarded } = useOnboarded();
  const { applications, isLoading } = useApplication(filter);
  const clearSelected = () => {
    setClear((p) => p + 1);
  };
  return (
    <>
      {/*Title Bar*/}
      <PageTitle title={t("pages.application.title")}>
        <RefreshButton loading={isLoading} />
        <ApplicationActionModalButton
          action="unmanage"
          Icon={UnmanageLogo}
          selected={selected}
          clear={clearSelected}
          filter={filter}
        />
        <ApplicationActionModalButton
          action="delete"
          Icon={RemoveLogo}
          selected={selected}
          clear={clearSelected}
          filter={filter}
        />
        <ApplicationActionModalButton
          action="uninstall"
          Icon={UninstallLogo}
          selected={selected}
          clear={clearSelected}
          filter={filter}
        />
        <ApplicationActionModalButton
          action="install"
          Icon={InstallLogo}
          selected={selected}
          clear={clearSelected}
          filter={filter}
        />
      </PageTitle>
      <PageContent sidebar>
        <Sidebar filter={filter} setFilter={setFilter} globalId={globalId} />
        <PageContentBody>
          <DeviceModeBar target="application" />
          {onboarded() === true ? (
            isLoading ? (
              <LoadingTable />
            ) : applications ? (
              <ApplicationTable
                appData={applications}
                globalId={globalId}
                filter={filter}
                setSelected={setSelected}
                clear={clear}
              />
            ) : (
              <LoadingTable />
            )
          ) : (
            <Onboarding target="application" />
          )}
        </PageContentBody>
      </PageContent>
    </>
  );
}

export default Application;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import GroupProgress from "./GroupProgress";
import IconButton from "../layout/buttons/IconButton";
import { ReactComponent as Plus } from "../icons/plus.svg";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardTitle,
  ModalCardButtons,
} from "../layout/ModalCard";
import Field from "../layout/design/Field";
import FieldArea from "../layout/design/FieldArea";
import StandardButton from "../layout/buttons/StandardButton";
import Checkbox from "../layout/Checkbox";
function NewGroupButton() {
  const { t } = useTranslation("global");
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(!showModal);

  return (
    <>
      <IconButton onClick={toggle} Icon={Plus} subtle>
        {t("pages.group.new")}
      </IconButton>
      {showModal && (
        <NewGroupButtonModal toggle={toggle} />
      )}
    </>
  );
}

function NewGroupButtonModal({ toggle }) {
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [groupType, setGroupType] = useState("category");
  const { t } = useTranslation("global");
  const [taskId, setTaskId] = useState(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);
  const handleSaveChanges = (event) => {
    setLoading(true);
    // if name is empty, cancel
    if (document.getElementById("groupName").value === "") {
      //show alert to the user
    } else {
      let reqBody = {
        detail: [
          {
            name: document.getElementById("groupName").value,
            description: document.getElementById("groupDescription").value,
            shared: document.getElementById("category_shared")?.checked || false,
          },
        ],
        kind: "cre",
      };
      // console.log(event.target.type.value)
      if (groupType === "room") {
        reqBody.target = "rom";
      } else if (groupType === "category") {
        reqBody.target = "cat";
      }

      postApi("/tasks/", reqBody).then((response) => {
        toggle();
        setLoading(false);
        document.getElementById("groupName").value = "";
        document.getElementById("groupDescription").value = "";
        setTaskId(response.data.identifier);
        // console.log(response.data);
      });
    }
  };

  return (
    <>
      {taskId && (
        <GroupProgress
          taskId={taskId}
          setTaskId={setTaskId}
        />
      )}
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>{t("pages.group.modal.new")}</ModalCardTitle>
        <ModalCardBody>
          <div className="text-left">
            {t("pages.group.modal.type")}
            <div>
              <select
                className="w-full p-2 rounded-lg border text-fblack"
                id="type"
                name="type"
                onChange={(e) => setGroupType(e.target.value)}
              >
                <option value="category" id="category">
                  {t("pages.group.category")}
                </option>
                <option value="room" id="room">
                  {t("pages.group.room")}
                </option>
              </select>
            </div>
            
          </div>
          <div className="text-left">
            <GroupNameField
              title={`${t("pages.group.modal.name")}*`}
              name="name"
              id="groupName"
              invalidTip={t("pages.group.modal.name_invalid")}
              valid={valid}
              setValid={setValid}
            />
            <FieldArea
              title={t("pages.group.modal.description")}
              id="groupDescription"
              name="description"
            />
            {groupType == "category" && (
              <Checkbox
                id="category_shared"
                title={t("pages.group.modal.category.shared")}
              />
            )}
          </div>
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t("pages.device.modal.cancel")}
          </StandardButton>
          <StandardButton
            loading={loading}
            onClick={handleSaveChanges}
            disabled={!valid}
          >
            {t("pages.group.modal.create")}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

function GroupNameField({ value, valid, setValid, ...attributes }) {
  const [text, setText] = useState(value);
  const handleOnChange = (e) => {
    const txt = e.target.value;
    if (e.target.value.match('^[a-zA-Z0-9\-.&äöüÄÖÜ," ]*$') != null) {
      setText(txt);
      setValid(true);
    } else {
      setText(txt);
      setValid(false);
    }
  };
  return (
    <>
      <Field
        value={text}
        onChange={handleOnChange}
        {...attributes}
        invalid={!valid}
      />
    </>
  );
}
export default NewGroupButton;

import React from 'react'
import TenantChannel from '../../topbar/TenantChannel'

function TitleProgressBar(props) {
  const handleOnChange = (e)=>{
    props.setProgress(e.target.value)}
  return (<>
    <div id={`bar_${props.task}`} className="h-1 bg-forange-400" style={{"width":`${props.progress}%`}} />
    <input hidden id={props.task} value="" onInput={handleOnChange}/>
     {props.task && <TenantChannel task={props.task} setProgress={props.setProgress} />}
    </>
  )
}

export default TitleProgressBar
import React, { useContext } from "react";
import { PortalContext } from "../../PortalContext";
import Objekt from "./Objekt";
import IpadOnboarding from "../device/IpadOnboarding";
function ObjectList({
  policies,
  scope,
  mode,
  listSelected,
  reload,
  setReload,
}) {
  const { deviceMode, ipadOnboarded } = useContext(PortalContext);

  return (
    <>
      {ipadOnboarded || deviceMode == "windows" ? (
        Object.keys(policies[deviceMode]).map((objectId, index) => {
          return (
            <Objekt
              key={`Objk${index}`}
              id={objectId}
              policies={policies[deviceMode][objectId]}
              scope={scope}
              mode={mode}
              reload={reload}
              setReload={setReload}
            />
          );
        })
      ) : (
        <IpadOnboarding target="policy" />
      )}
    </>
  );
}

export default ObjectList;

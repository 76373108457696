import React, { useContext } from "react";
import { PortalContext } from "../../PortalContext";
import { useTranslation } from "react-i18next";
import { ReactComponent as ComputerLogo } from "../icons/computer.svg";
import { ReactComponent as TabletLogo } from "../icons/tablet.svg";
import { Link } from "react-router-dom";
import Tooltip from "../layout/tooltip/Tooltip";
function ModuleMultipleDeviceModeButton({
  link,
  Logo,
  title,
  tooltip,
  disabled,
  windows,
  macos,
  ipad,
  ...attributes
}) {
  const { setDeviceMode } = useContext(PortalContext);
  const { t } = useTranslation("global");
  return (
    <Link to={disabled ? "#" : link} className="hover:border-forange-400">
      <Tooltip title={tooltip} {...attributes}>
        <div className="group">
          <div
            className={`rounded-2xl align-middle text-center border  border-gray-400 items-center h-[132px] w-[255px] font-normal  transition-transform ${
              disabled
                ? "cursor-not-allowed text-fgray-300 "
                : "group-hover:hidden hover:font-medium hover:bg-forange-100 hover:text-forange-400 hover:border-forange-400 "
            }`}
          >
            <Logo className="h-14 w-14 mx-auto my-4    " />
            <span className=" align-text-center ">{t(title)}</span>
          </div>
          <div
            className={`rounded-2xl m-auto border  hidden border-gray-400 h-[132px] w-[255px] transition-transform ${
              disabled ? "" : "group-hover:block"
            }`}
          >
            <div className="mx-5 mt-5  text-center flex border-b-gray-400 border-0 border-b-[1px] m-auto font-normal   rounded-t-xl">
              <p
                className="p-[10px] m-auto w-full h-full align-text-center truncate hover:font-medium hover:bg-forange-100 hover:text-forange-400 rounded-tl-xl"
                onClick={() => setDeviceMode("windows")}
              >
                {t("pages.home.windows")}
              </p>
              <p
                className="p-[10px] m-auto w-full h-full align-text-center truncate border-l-2 hover:font-medium hover:bg-forange-100 hover:text-forange-400 rounded-tr-xl"
                onClick={() => setDeviceMode("macos")}
              >
                {t("words.macos")}
              </p>
            </div>
            <div
              className="mx-5 mb-5 p-[10px]  flex  m-auto hover:font-medium font-normal hover:bg-forange-100 hover:text-forange-400  rounded-b-xl"
              onClick={() => setDeviceMode("ipad")}
            >
              <p className="px-4 m-auto text-center truncate">
                {t("pages.home.ipad")}
              </p>
            </div>
          </div>
        </div>
      </Tooltip>
    </Link>
  );
}

export default ModuleMultipleDeviceModeButton;

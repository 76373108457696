import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import global_en from '../translations/EN.json'
import global_de from '../translations/DE.json'

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
   interpolation: { escapeValue: false },
   fallbackLng: "de",
  //  debug: true,
   resources:{
     en: {
       global: global_en
     },
     de: {
       global: global_de
     }
    //  },
    //  es:{
    //      global: global_es,
    //      legal: legal_es,
    //     },
   },
});

export default i18n;
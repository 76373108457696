import React, { useState, useEffect, useContext } from "react";
import ProgressSpinner from "./ProgressSpinner";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { PortalContext } from "../../../PortalContext";
import NotificationCenter from "../../progress/NotificationCenter";
function ProgressIndicator({ makeRefresh }) {
  const { taskOnProgress, setTaskOnProgress, apiToken } =
    useContext(PortalContext);
  const [display, setDisplay] = useState(false);
  const [reopen, setReopen] = useState(0);
  let socket = null;
  const startSocket=()=>{
    if (apiToken) {
      socket = new W3CWebSocket(
        `${process.env.REACT_APP_WS_ENDPOINT}/ws/progress/${apiToken}/`
      );
      socket.onopen = () => {};
      socket.onmessage = (msg) => {
        let message = "";
        try {
          message = JSON.parse(msg.data);
        } catch {}
        // console.log(message)
        if (message.task) {
          setTaskOnProgress(message.task);
        }
      };
      socket.onclose = ()=>{
        socket = null
        setTimeout(startSocket,5000)
      }
    }
  }
  useEffect(() => {
    startSocket();
  }, [apiToken]);

  useEffect(() => {
    if (taskOnProgress.length === 0) {
      makeRefresh();
    }
    setDisplay(taskOnProgress.length > 0);
  }, [taskOnProgress]);

  useEffect(() => {
    if (display) {
      const timeout = setTimeout(() => setDisplay(false), 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [display]);

  return (
    <>
      {taskOnProgress.length > 0 && <ProgressSpinner progress="5" />}
      {/* {display && <Toast display={taskOnProgress[0]} />} */}
      <NotificationCenter />
    </>
  );
}

export default ProgressIndicator;

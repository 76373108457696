import React from "react";
import { useTranslation } from "react-i18next";
import StandardButton from "../layout/buttons/StandardButton";

function MetricStatusCard({ info, fun }) {
  const { t } = useTranslation("global");
  const StatusCard = () => {
    switch (info[0].status) {
      case 300:
        return (
          <>
            <div className="mx-auto max-w-6xl p-2 overflow-hidden">
              <div className="flex">
                <div className="bg-fstatus-300 rounded-full w-2 h-2 align-middle my-auto mr-2" />
                <div className="text-xl font-medium">
                  {t("metrics.status.300.card_title")}
                </div>
              </div>
              <div className="text-fgray-300 truncate my-4">
                {t(`metrics.${info[0].id}.warning`)}
              </div>
              <div className="flex my-2">
                <CallToActionButton />
              </div>
            </div>
          </>
        );
        break;
      case 400:
        return (
          <>
            <div className="mx-auto max-w-6xl p-2 overflow-hidden">
              <div className="flex">
                <div className="bg-fstatus-400 rounded-full w-2 h-2 align-middle my-auto mr-2" />
                <div className="text-xl font-medium">
                  {t("metrics.status.400.card_title")}
                </div>
              </div>
              <div className="text-fgray-300 truncate my-4">
                {t(`metrics.${info[0].id}.danger`)}
              </div>
              <div className="flex my-2">
                <CallToActionButton />
              </div>
            </div>
          </>
        );
    }
  };

  const CallToActionButton = () => {
    switch (info[0].action.action) {
      case "link":
        return (
          <StandardButton
            onClick={() => window.open(info[0].action.trigger, "_blank")}
            secondary
          >
            {t("metrics.status.buttons.call_to_action")}
          </StandardButton>
        );
        break;
      case "function":
        const click = Function(info[0].action.trigger);
        return (
          <StandardButton onClick={click} secondary>
            {t("metrics.status.buttons.call_to_action")}
          </StandardButton>
        );

      default:
        <></>;
        break;
    }
  };

  return (
    info[0].status !== 200 && (
      <>
        <div className="m-auto  items-center p-2 overflow-hidden ">
          <div className="max-w-6xl mx-auto p-2 my-2 rounded-xl shadow-lg">
            <StatusCard />
          </div>
        </div>
      </>
    )
  );
}

export default MetricStatusCard;

import React from "react";

function NotificationCard({ children }) {
  return (
    <div className="m-auto items-center p-2 overflow-hidden w-full ">
      <div className="max-w-6xl mx-auto p-2 my-2 rounded-xl shadow-lg">
        <div className="mx-auto max-w-6xl p-2 overflow-hidden">{children}</div>
      </div>
    </div>
  );
}

export default NotificationCard;

export function NotificationCardTitle({ children }) {
  return <div className="text-xl font-normal">{children}</div>;
}

export function NotificationCardBody({ children, className, ...attributes }) {
  return (
    <div className={`truncate my-4 ${className}`} {...attributes}>
      {children}
    </div>
  );
}
export function NotificationCardButtons({ children }) {
  return <div className="flex">{children}</div>;
}

import React, { useState } from "react";

function Textbox({ parameter }) {
  const [valid, setValid] = useState(true);
  const [textValue, setTextValue] = useState(parameter.prefilled || "");
  function handleChange(e) {
    let texto = e.target.value;
    setTextValue(texto);

    if (e.target.value.match(parameter.validator) != null) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  return (
    <div>
      {" "}
      <input
        type="text"
        name={parameter.name}
        id={parameter.id}
        placeholder={parameter.hint}
        className={`p-2  w-full border-0 rounded-lg text-sm focus:outline-transparent ${
          valid ? "" : " bg-red-200 text-red-800 "
        } `}
        onChange={handleChange}
        value={textValue}
      />
    </div>
  );
}

export default Textbox;

import React from "react";
import { useTranslation } from "react-i18next";
import BitlockerFields from "./BitlockerFields";
import { ReactComponent as LockIcon } from "../icons/lock-closed.svg";
import { ReactComponent as LockOpenIcon } from "../icons/lock-open.svg";
import { SystemBoxField } from "./SystemInfoBox";
import Field from "../layout/design/Field";
function DeviceSecurityBox({ deviceInfo, bitlocker }) {
  const { t } = useTranslation("global");
  const isEncryptedValue = deviceInfo.isEncrypted ? (
    <div className="flex">
      <LockIcon className="mr-2 w-6 h-6" />
      {t("pages.device_detail.encrypted")}
    </div>
  ) : (
    <div className="flex">
      <LockOpenIcon className="mr-2 w-6 h-6" />
      {t("pages.device_detail.unencrypted")}
    </div>
  )
  return (
    <>
      <div className="w-full space-y-2.5 rounded-lg border-fgray-200 border py-4 px-4">
        <SystemBoxField title={t("pages.device_detail.device_status")} value={isEncryptedValue}/>
      
        {bitlocker != [] && (deviceInfo.type == "windows" ? (
          <BitlockerFields bitlockerKeys={bitlocker} />
        ) : deviceInfo.type == "macos" ? (
          <Field
            title={t("pages.device_detail.filevault")}
            value={bitlocker}
            icon
            copy
          />
        ) : (
          <></>
        ))}
      </div>
    </>
  );
}

export default DeviceSecurityBox;

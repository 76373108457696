import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGetLicenseStatus } from "../tools/utilties";
import { getApi } from "../tools/axiosInstances";
import LoadingHome from "../components/layout/loading/LoadingHome";
import { ReactComponent as appstoreLogo } from "../components/icons/appstoreLogo.svg";
import { ReactComponent as appManagementLogo } from "../components/icons/appManagementLogo.svg";
import { ReactComponent as deviceManagementLogo } from "../components/icons/deviceManagementLogo.svg";
import { ReactComponent as groupManagementLogo } from "../components/icons/groupManagementLogo.svg";
import { ReactComponent as policyManagementLogo } from "../components/icons/policyManagementLogo.svg";
import { ReactComponent as progressLogo } from "../components/icons/progressLogo.svg";
import { ReactComponent as settingsLogo } from "../components/icons/settingsLogo.svg";
import { ReactComponent as documentationLogo } from "../components/icons/documentationLogo.svg";
import { ReactComponent as printerLogo } from "../components/icons/printerLogo.svg";
import ModuleButton from "../components/home/ModuleButton";
import ModuleMultipleDeviceModeButton from "../components/home/ModuleMultipleDeviceModeButton";
import { PortalContext } from "../PortalContext";
import PaymentNotificationCard from "../components/home/PaymentNotificationCard";
import PageTitle from "../components/layout/PageTitle";
function Home() {
  const { t } = useTranslation("global");
  const { deviceMode } = useContext(PortalContext);
  const [userRole, setUserRole] = useState(false);
  const AccessAllowed = useGetLicenseStatus();
  useEffect(() => {
    getApi("/settings/onboardhome/", {});
    getApi("/category/onboard/", {})
  }, []);
  useEffect(() => {
    if (!userRole) {
      const interval = setInterval(() => {
        if (sessionStorage.getItem("role")) {
          setUserRole(sessionStorage.getItem("role"));
          clearInterval(interval);
        }
      }, 10);
    }

    return () => {};
  }, []);

  return (
    <>
      {/*Title Bar*/}
      <PageTitle title={t("pages.home.welcome")} />
      {/*Main Container*/}
      <div className="mx-auto max-w-6xl ">
        {/* {<TermsAndConditions showAccept={acceptedTaC} modalId="Accept" refresh={getTaCStatus()} /> } */}
        {/* <TermsAndConditions modalId="modalTaC"/> */}
        {userRole == "smp.administrator" ? (
          <>
            <PaymentNotificationCard />
            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 mx-5 my-8 gap-10">
              {deviceMode == "windows" && (
                <ModuleButton
                  link="/appstore"
                  Logo={appstoreLogo}
                  title="pages.appstore.title"
                  tooltip="tooltip.home.appstore"
                  disabled={!AccessAllowed}
                />
              )}
              <ModuleMultipleDeviceModeButton
                link="/application"
                Logo={appManagementLogo}
                title="pages.application.title"
                tooltip="tooltip.home.app_management"
                disabled={!AccessAllowed}
              />
              <ModuleButton
                link="/group"
                Logo={groupManagementLogo}
                title="pages.group.title"
                disabled={!AccessAllowed}
              />
              <ModuleMultipleDeviceModeButton
                link="/device"
                Logo={deviceManagementLogo}
                title="pages.device.title"
                disabled={!AccessAllowed}
              />
              {deviceMode == "windows" && (
                <ModuleButton
                  link="/printer"
                  Logo={printerLogo}
                  title="pages.printer.title"
                  disabled={!AccessAllowed}
                />
              )}
              <ModuleMultipleDeviceModeButton
                link="/policy"
                // link="#"
                Logo={policyManagementLogo}
                title="pages.policy.title"
                disabled={!AccessAllowed}
              />

              <ModuleButton
                link="/progress"
                Logo={progressLogo}
                title="pages.progress.title"
                disabled={!AccessAllowed}
              />
              <ModuleButton
                link="https://docs.numiato.de"
                Logo={documentationLogo}
                title="pages.documentation.title"
                target="_blank"
              />
              <ModuleButton
                link="/settings"
                Logo={settingsLogo}
                title="pages.settings.title"
              />
            </div>
          </>
        ) : userRole == "smp.device" ? (
          <>
            <PaymentNotificationCard />
            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 mx-5 my-8 gap-10">
              <ModuleMultipleDeviceModeButton
                link="/application"
                Logo={appManagementLogo}
                title="pages.application.title"
                disabled={!AccessAllowed}
              />
              <ModuleButton
                link="/group"
                Logo={groupManagementLogo}
                title="pages.group.title"
                disabled={!AccessAllowed}
              />
              <ModuleMultipleDeviceModeButton
                link="/device"
                Logo={deviceManagementLogo}
                title="pages.device.title"
                disabled={!AccessAllowed}
              />
              <ModuleButton
                link="/progress"
                Logo={progressLogo}
                title="pages.progress.title"
                disabled={!AccessAllowed}
              />
              <ModuleButton
                link="https://docs.numiato.de"
                Logo={documentationLogo}
                title="pages.documentation.title"
                target="_blank"
              />
              <ModuleButton
                link="/settings"
                Logo={settingsLogo}
                title="pages.settings.title"
              />
            </div>
          </>
        ) : (
          <>
            <LoadingHome />
          </>
        )}
      </div>
    </>
  );
}

export default Home;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TabBar from "../components/layout/TabBar";
import GroupGrid from "../components/group/GroupGrid";
import NewGroupButton from "../components/group/NewGroupButton";
import LoadingGroupCard from "../components/layout/loading/LoadingGroupCard";
import { useCheckLicenseStatus } from "../tools/utilties";
import { useCategory } from "../hooks/useCategory";
import { useRoom } from "../hooks/useRoom";
import PageTitle from "../components/layout/PageTitle";
import PageContent from "../components/layout/PageContent";
function Group() {
  const { t } = useTranslation("global");
  const [tab, setTab] = useState(0);
  const categories = useCategory();
  const rooms = useRoom();
  useCheckLicenseStatus();
  return (
    <>
      <PageTitle title={t("pages.group.title")}>
        <NewGroupButton />
      </PageTitle>
      <PageContent>
        <TabBar
          options={[
            { title: t("words.all"), id: 0 },
            { title: t("pages.group.category"), id: 1 },
            { title: t("pages.group.room"), id: 2 },
          ]}
          handler={setTab}
          activeTab={tab}
        />
        <div className="mx-auto m-5 flex overflow-auto ">
          {categories && rooms ? (
            <GroupGrid categories={categories} rooms={rooms} filter={tab} />
          ) : (
            <LoadingGroupCard />
          )}
        </div>
      </PageContent>
    </>
  );
}

export default Group;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import MultilineTranslation from "../layout/MultilineTranslation";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardTitle,
  ModalCardBody,
  ModalCardButtons,
} from "../layout/ModalCard";
function DeviceActionModal({
  selected,
  action,
  showModal,
  toggle,
  clear,
  device,
  setDeviceInfo,
}) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);
  function handleActionClick() {
    setLoading(true);
    let reqBody = "";
    switch (action) {
      case "sync":
        reqBody = {
          kind: "syc",
          target: "dev",
          detail: [],
        };
        break;
      case "reboot":
        reqBody = {
          kind: "rbt",
          target: "dev",
          detail: [],
        };
        setDeviceInfo &&
          setDeviceInfo((d) => ({
            ...d,
            pendingTasks: [
              ...d.pendingTasks,
              { actionName: "rebootNow", actionState: "pending" },
            ],
          }));
        break;
      case "reset":
        reqBody = {
          kind: "rst",
          target: "dev",
          detail: [],
        };
        setDeviceInfo &&
          setDeviceInfo((d) => ({
            ...d,
            pendingTasks: [
              ...d.pendingTasks,
              {
                actionName: "factoryResetKeepEnrollmentData",
                actionState: "pending",
              },
            ],
          }));
        break;
      case "remove":
        reqBody = {
          kind: "del",
          target: "dev",
          detail: [],
        };
        break;
      case "resetPasscode":
        reqBody = {
          kind: "ulk",
          target: "dev",
          detail: [],
        };
        break;
      default:
        break;
    }
    device
      ? reqBody.detail.push({ deviceId: device.id,targetType:device.targetType })
      : selected.map((device) => {
          reqBody.detail.push({ deviceId: device.original.id,targetType:device.original.targetType });
        });

    postApi("/tasks/", reqBody).then((response) => {
      toggle();
      setLoading(false);
      clear && clear();
    });
  }

  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>
          {device
            ? t(`pages.device.modal.${action}.title_1`, {
                deviceName: device.deviceName,
              })
            : selected.length === 1
            ? t(`pages.device.modal.${action}.title_1`, {
                deviceName: selected.map((row) => row.original.deviceName),
              })
            : t(`pages.device.modal.${action}.title_n`, {
                deviceCount: selected.length,
              })}
        </ModalCardTitle>
        <ModalCardBody>
          {device ? (
            <MultilineTranslation
              text={t(`pages.device.modal.${action}.body_1`, {
                deviceName: device.deviceName,
              })}
            />
          ) : selected.length === 1 ? (
            <MultilineTranslation
              text={t(`pages.device.modal.${action}.body_1`, {
                deviceName: selected.map((row) => row.original.deviceName),
              })}
            />
          ) : (
            <MultilineTranslation
              text={t(`pages.device.modal.${action}.body_n`, {
                deviceCount: selected.length,
              })}
            />
          )}
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t(`pages.device.modal.cancel`)}
          </StandardButton>
          <StandardButton onClick={handleActionClick} loading={loading}>
            {t(`pages.device.modal.${action}.confirm`)}
          </StandardButton>

        </ModalCardButtons>
      </ModalCard>

      {/* overlay */}
   
    </>
  );
}

export default DeviceActionModal;

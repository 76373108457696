import axios from "axios";
import { Providers } from "@microsoft/mgt-element";

// const graphMeEndpoint = "https://graph.microsoft.com/v1.0/me";
// const graphBetaEndpoint = "https://graph.microsoft.com/beta";
// const graphv1Endpoint = "https://graph.microsoft.com/v1.0";

// const { instance, accounts } = useMsal();

// instance.acquireTokenSilent(request)

// const headers = new Headers();
//     const bearer = `Bearer ${accessToken}`;

//     headers.append("Authorization", bearer);

//     const options = {
//         method: "GET",
//         headers: headers,
//     };
// const graphBeta = axios.create({
//     baseURL,
//     headers
// })

export async function postApi(uri, data) {
  const headers = {
    Authorization: `Token ${
      JSON.parse(sessionStorage.getItem("apiAuth"))["token"]
    }`,
  };
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, data, {
      headers: headers,
    })
    .then((response) => response)
    .catch((error) => console.log(error));
}

export async function uploadFile(uri, data) {
  const headers = {
    Authorization: `Token ${
      JSON.parse(sessionStorage.getItem("apiAuth"))["token"]
    }`,
    "Content-Type": "multipart/form-data",
  };
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, data, {
      headers: headers,
    })
    .then((response) => response)
    .catch((error) => console.log(error));
}

export async function getApiGraph(msToken, uri, data) {
  let headrs = {
    Authorization: `Token ${
      JSON.parse(sessionStorage.getItem("apiAuth"))["token"]
    }`,
    msToken: `Bearer ${msToken}`,
    // "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,mstoken"
  };
  return axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, { headers: headrs })
    .then((response) => response)
    .catch((error) => console.log(error));
}

export async function getApi(uri, data, signal = false) {
  let headrs = {
    Authorization: `Token ${
      JSON.parse(sessionStorage.getItem("apiAuth"))["token"]
    }`,
    // "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,mstoken"
  };
  if (signal) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, {
        headers: headrs,
        signal: signal,
      })
      .then((response) => response)
      .catch((error) => {
        if (error.code == "ERR_CANCELED") {
          throw error;
        } else {
          console.log(error);
        }
      });
  } else {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, { headers: headrs })
      .then((response) => response)
      .catch((error) => console.log(error));
  }
}

export async function getApiUnauthenticated(uri, signal = false) {
  if (signal) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, {
        signal: signal,
      })
      .then((response) => response)
      .catch((error) => {
        if (error.code == "ERR_CANCELED") {
          throw error;
        } else {
          console.log(error);
        }
      });
  } else {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`)
      .then((response) => response)
      .catch((error) => console.log(error));
  }
}
export async function postApiUnauthenticated(uri, data) {
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, data)
    .then((response) => response)
    .catch((error) => console.log(error));
}
export async function getApiDelegated(uri, signal = false) {
  return Providers.globalProvider.getAccessToken().then((token) => {
    const headrs = {
      Authorization: `Token ${
        JSON.parse(sessionStorage.getItem("apiAuth"))["token"]
      }`,
      mstoken: token,
    };
    if (signal) {
      return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, {
          headers: headrs,
          signal: signal,
        })
        .then((response) => response)
        .catch((error) => {
          if (error.code == "ERR_CANCELED") {
            throw error;
          } else {
            console.log(error);
          }
        });
    } else {
      return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, { headers: headrs })
        .then((response) => response)
        .catch((error) => console.log(error));
    }
  });
}

export async function postApiGraph(msToken, expiresOn, uri, data) {
  let headrs = {
    Authorization: `Token ${
      JSON.parse(sessionStorage.getItem("apiAuth"))["token"]
    }`,
    msToken: `Bearer ${msToken}`,
    expiresOn: expiresOn,
    // "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,mstoken"
  };
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}${uri}`, data, {
      headers: headrs,
    })
    .then((response) => response)
    .catch((error) => console.log(error));
}

export function canceled(req) {
  return axios.isCancel(req);
  // return req.code == "ERR_CANCELED"
}

import React from "react";

function Option({ definition, mode }) {
  
  return (
    <select
      name={definition.name}
      id={definition.id}
      className="p-2 mx-4 my-2 w-full rounded-lg text-sm border border-fgray-400"
      disabled={!mode}
      defaultValue={definition.selected || definition.default}
      >
      {definition.values.map((o, index) => (
        <option
          key={`opt${index}`}
          value={o.value}
        >
          {o.display}
        </option>
      ))}
    </select>
  );
}

export default Option;

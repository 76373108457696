import React from "react";
import classNames from "classnames";
import Tooltip from "../tooltip/Tooltip";
function BaseButton({
  children,
  secondary,
  icon,
  small,
  subtle,
  thin,
  full,
  className,
  tooltip,
  destructive,
  ...attributes
}) {
  const classes = classNames(
    "px-5 rounded-lg mx-1 justify-center items-center inline-flex text-base disabled:bg-white disabled:text-fgray-300 hover:bg-opacity-90  disabled:cursor-not-allowed",
    {
      "text-white bg-forange-400 focus:outline-white": !(
        secondary |
        icon |
        subtle |
        destructive
      ),
      "py-1.5  ": small,
      "py-2.5": !small,
      "border border-forange-400 text-forange-400 hover:bg-forange-100 focus:outline-forange-400 focus:bg-forange-200 disabled:border-fgray-300":
        secondary,
      "w-full": full,
      "min-w-[100px]": !thin,
      "hover:bg-forange-200 text-black focus:outline-forange-400 focus:bg-forange-200":
        subtle | icon,
      "text-fstatus-400 hover:bg-red-200 focus:outline-fstatus-400 ":
        destructive,
    }
  );
  return (
    <Tooltip title={tooltip} {...attributes}>
      <button
        type="button"
        className={`${classes} ${className}`}
        {...attributes}
      >
        {children}
      </button>
    </Tooltip>
  );
}

export default BaseButton;

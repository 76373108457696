import React, { useState, useEffect, useContext } from "react";
import Results from "./Results";
import { useTranslation } from "react-i18next";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { debounce } from "lodash";
import { PortalContext } from "../../PortalContext";
import { useGetLicenseStatus } from "../../tools/utilties";
function Searchbar() {
  const [sock, setSock] = useState(null);
  let skt = null;
  const { apiToken } = useContext(PortalContext);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation("global");
  const licenseStatus = useGetLicenseStatus()
  function handleOnFocus() {
    if (sock == null || sock.readyState != 1) {openSocket();}
  }
  function handleOnChangeQuery(e) {
    setSearchQuery(e.target.value);
  }
  const debouncedQuery = debounce(handleOnChangeQuery, 300);

  // useEffect(()=>{
  //   return () =>{
  //     debouncedQuery.cancel();
  //   }
  // })

  useEffect(() => {
    try {
      if (searchQuery != "") {
        // if (e.target.value.length >= 3) {
        // if (sock == null || sock.readyState != 1) {
        //   openSocket();
        // }
        sock && sock.send(JSON.stringify({ query: searchQuery }));
        setLoading(true);
      }
      // }
    } catch (error) {
      console.log(error);
    }
  }, [searchQuery]);

  function openSocket() {
    try {
      if (apiToken) {
        skt = new W3CWebSocket(
          `${process.env.REACT_APP_WS_ENDPOINT}/ws/search/${apiToken}/`
        );
        skt.onopen = () => {};
        skt.onmessage = (message) => {
          setResults(JSON.parse(message.data));
          setLoading(false);
        };
        skt.onclose=()=>{
          skt = null
          setTimeout(openSocket,5000)
        }
      }
    } catch {}
    setSock(skt);
    return () => {
      skt = null;
    };
  }

  useEffect(() => {
    openSocket();
    return () => setSock(null);
  }, [apiToken]);

  function handleLostFocus(e) {
    setTimeout(() => setSearchQuery(""), 500);
    e.target.value = "";
  }

  useEffect(() => {
    if (!visible) {
      const interval = setInterval(() => {
        if (apiToken) {
          setVisible(true);
          clearInterval(interval);
        }
      }, 100);
    }
    return () => {};
  }, [apiToken]);

  return (
    <>
      {visible && licenseStatus && (
        <>
          <div className="h-full flex items-center">
            <div className="border rounded-lg overflow-hidden w-full">
              <div className="flex py-1 px-3 ">
                <div className="relative w-full flex items-center align-middle">
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 15L21 21"
                      stroke="#CBC6C4"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="#CBC6C4"
                      strokeWidth="2"
                    />
                  </svg>

                  <input
                    type="input"
                    name=""
                    placeholder={t("Navbar.search")}
                    className="w-full px-2 ml-2 rounded-lg focus:outline-none"
                    onFocus={handleOnFocus}
                    onChange={debouncedQuery}
                    onPaste={debouncedQuery}
                    onBlur={handleLostFocus}
                  />
                </div>
              </div>
            </div>
          </div>
          {results && searchQuery.length > 0 && (
            <Results
              searchQuery={searchQuery}
              results={results}
              loading={loading}
            />
          )}
        </>
      )}
    </>
  );
}

export default Searchbar;

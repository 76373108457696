import React from "react";
import { useTranslation } from "react-i18next";
import FeedbackLink from "./FeedbackLink";
import Tooltip from "./layout/tooltip/Tooltip";
export default function Footer() {
  const { t } = useTranslation("global");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated") === "true";

  return (
    <>
      <footer className="bg-fgray-100 w-full fixed bottom-0 items-center ">
        <div className="flex justify-between max-w-6xl mx-auto">
          <div className="text-gray-700 py-4  px-4 flex">
            © {new Date().getFullYear()} Numiato GmbH&nbsp;&#183;&nbsp;
            <a
              href="/terms"
              className="text-gray-700 underline hover:text-forange-400"
            >
              {t("Footer.imprint")}
            </a>
            &nbsp;&#183;&nbsp;
            <a
              href="/privacy"
              className="text-gray-700 hover:text-forange-400 underline"
            >
              {t("Footer.privacy_policy")}
            </a>
            &nbsp;
          </div>
          <div className="p-4">
            {isAuthenticated && (
              <>
                <Tooltip title="tooltip.footer.feedback" position="top">
                  <FeedbackLink className="text-gray-700 hover:text-forange-400 underline" />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </footer>
    </>
  );
}

import { rankItem } from "@tanstack/match-sorter-utils";

export const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const muxFilter = (row, columnId, value) => {
  const cellValue = row.getValue(columnId);
  if (value.match(/^\*(.*?)\*/gm)) {
    // contains
    return cellValue.toLowerCase().includes(value.split("*")[1].toLowerCase());
  } else if (value.match(/^\*.*/gm)) {
    // ends with
    return cellValue.toLowerCase().endsWith(value.split("*")[1].toLowerCase());
  } else if (value.match(/^.*\*/gm)) {
    // starts with
    return cellValue
      .toLowerCase()
      .startsWith(value.split("*")[0].toLowerCase());
  }
};

import React, { useState, useEffect } from "react";
import { getApi } from "../../tools/axiosInstances";
import TenantChannel from "../topbar/TenantChannel";
function AppProgressBar({ taskId, setTaskId, reload }) {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (progress >= 100) {
      setTaskId("installed");
      reload((p) => p + 1);
    }
  }, [progress]);
  useEffect(() => {
    getApi(`/tasks/${taskId}/progress/`, {}).then((task) =>
      setProgress(task.data.progress)
    );
  }, []);
  const handleChangeProgress = (e) => {
    setProgress(e.target.value);
  };

  return (
    <div className="py-5">
      {progress == 0 ? (
        <div className="h-1 w-full bg-fgray-300 rounded animate-pulse "></div>
      ) : (
        <div className="h-1 w-full bg-fgray-300 rounded ">
          <input hidden id={taskId} onInput={handleChangeProgress} value="0" />
          <div
            id={`bar_${taskId}`}
            className="h-full bg-forange-400 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
    </div>
  );
}

export default AppProgressBar;

import React, { useState } from "react";
import UserButton from "./UserButton";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import ProgressIndicator from "./ProgressIndicator/ProgressIndicator";
import Searchbar from "../search/Searchbar";
import DeviceMode from "./DeviceMode";
import { ReactComponent as NumiatoIcon } from "../icons/numiatoIcon.svg";
import { Link } from "react-router-dom";
export default function Navbar({ makeRefresh }) {
  return (
    <>
      <nav className="bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between">
            {/* hamburger */}
            <div className="py-5 px-2 items-center relative flex ">
              <HamburgerMenu />
              <Link to="/">
                <div className="flex space-x-2 text-bold px-4">
                  <NumiatoIcon />
                  <span className="font-medium">Numiato</span>
                </div>
              </Link>
            </div>
            {/* userpart */}
            <div className="w-[500px] ">
                <Searchbar />
              </div>
            <div className="flex">
              
              <div className="w-[150px] items-center flex ">
                <DeviceMode />
              </div>
              <div className="w-[50px] flex align-middle items-center ">
                <ProgressIndicator makeRefresh={makeRefresh} />
              </div>
              <div className="w-[200px] flex align-middle items-center ">
                <UserButton />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

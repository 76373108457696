import React from "react";
import { useTranslation } from "react-i18next";

function ResultFooter({ results,loading }) {
  const { t } = useTranslation("global");
  return (
    <>
      <p className={`text-right mx-1 pt-1 px-1 bg-white text-sm text-fgray-300 ${loading ? "animate-pulse" : ""}`}>
      {isNaN(results.count) || loading  ?t("words.loading")+"...": results.count <= 10? t("Navbar.search_footer", {count: results.count}) :t("Navbar.search_footer", {count: results.count}) }

      </p>
    </>
  );
}

export default ResultFooter;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import { Login } from "@microsoft/mgt-react";
// import 'moment/locale/es'
import moment from "moment";
import { Providers } from "@microsoft/mgt-element";

export default function UserButton() {

  //Login Command template
  const LoginCommandsTemplate = (props) => {
    const { AADSignOut } = props.dataContext;
    const handleSignOut = ()=>{
      sessionStorage.clear()
      Providers.globalProvider.logout()
    }
    return (
      <div className=" mt-2">
        <span className="">
          <button
            className="px-4 py-2 bg-white rounded-md w-full hover:bg-fgray-100 hover:font-medium hover:text-forange-400 text-sm focus:outline-none"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </span>
        <span className="flex w-full">
          <button
            className="px-4 py-2 bg-white rounded-md w-full hover:bg-fgray-100 hover:font-medium hover:text-forange-400 text-sm focus:outline-none"
            onClick={() => changeLocale("en")}
          >
            EN
          </button>
          <button
            className="px-4 py-2 bg-white rounded-md w-full hover:bg-fgray-100 hover:font-medium hover:text-forange-400 text-sm focus:outline-none"
            onClick={() => changeLocale("de")}
          >
            DE
          </button>
        </span>
      </div>
    );
  };
  //Translations
  const {i18n} = useTranslation("global");

  //Photo
  useEffect(() => {
    moment.locale(i18n.language);
  }, []);

  function changeLocale(locale) {
    i18n.changeLanguage(locale);
    moment.locale(locale);
  }
  return (
    <>
      <div className="relative">
        <Login className="[--button-color--hover:text-forange-400] [--popup-padding:20px_20px_10px] hover:text-forange-400 [--font-weight--hover:500] [--font-weight:400] ">
          <LoginCommandsTemplate template="flyout-commands"></LoginCommandsTemplate>
        </Login>
      </div>
    </>
  );
}

import React, { useContext, useState, useRef } from "react";
import { ReactComponent as ResetIcon } from "../../icons/reset.svg";
import { useTranslation } from "react-i18next";
import { postApi } from "../../../tools/axiosInstances";
import ActionButton from "../../layout/buttons/ActionButton";
import { useToggleComponent } from "../../../tools/hooks";
import { PortalContext } from "../../../PortalContext";
import StandardButton from "../../layout/buttons/StandardButton";
import ModalCard, {
  ModalCardTitle,
  ModalCardButtons,
  ModalCardBody,
} from "../../layout/ModalCard";
function ResetPolicyManagementButtons() {
  const { t } = useTranslation("global");
  const [ipadsLoading, setIpadsLoading] = useState(false);
  const [windowsLoading, setWindowsLoading] = useState(false);
  const [macosLoading, setMacosLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [target, setTarget] = useState("windows");
  const hide = () => setDisplayModal(false);
  const wrapper = useRef(null);
  const { ipadPolicyManagement,macosPolicyManagement, setWindowsPolicyManagement, setIpadOnboarded } =
    useContext(PortalContext);
  const handleResetClick = () => {
    target == "windows" ? setWindowsLoading(true) : setIpadsLoading(true);
    const reqBody = {
      kind: "rst",
      target: "pol",
      detail: [{ target: target }],
    };
    postApi("/tasks/", reqBody).then((a) => {
      if (target === "windows") {
        setWindowsLoading(false);
        setWindowsPolicyManagement(false);
      } else {
        setIpadsLoading(false);
        setIpadOnboarded(false);
      }
      sessionStorage.setItem(`onboardTask_${target}`, a.data.identifier);
      hide();
    });
  };
  const toggle = (t) => {
    switch (t) {
      case "windows":
        setTarget("windows");
        break;
      case "ipad":
        setTarget("ipad");
        break;
      case "macos":
        setTarget("macos");
        break;
      default:
        setTarget("windows");
        break;
    }
    setDisplayModal((v) => !v);
  };
  useToggleComponent(wrapper, hide);
  const loading = () => {
    return target == "windows" ? windowsLoading : ipadsLoading;
  };
  function ResetPolicyModal() {
    return (
      <>
        {displayModal && (
          <ModalCard wide wrapper={wrapper}>
            <ModalCardTitle>
              {t(`pages.settings.modal.reset_pm_${target}.title`)}
            </ModalCardTitle>
            <ModalCardBody>
              {t(`pages.settings.modal.reset_pm_${target}.body`)}
            </ModalCardBody>
            <ModalCardButtons>
              <StandardButton onClick={toggle} subtle>
                {t("actions.cancel")}
              </StandardButton>
              <ActionButton
                Icon={ResetIcon}
                onClick={handleResetClick}
                loading={loading()}
                destructive
              >
                {t(`pages.settings.policy.reset.button.${target}`)}
              </ActionButton>
            </ModalCardButtons>
          </ModalCard>
        )}
      </>
    );
  }

  return (
    <>
      <div className="flex space-x-4">
        <ActionButton
          Icon={ResetIcon}
          secondary
          onClick={() => toggle("windows")}
          disabled={windowsLoading}
        >
          {windowsLoading
            ? t("words.submitting")
            : t("pages.settings.policy.reset.button.windows")}
        </ActionButton>
        {ipadPolicyManagement && (
          <ActionButton
            Icon={ResetIcon}
            secondary
            onClick={() => toggle("ipad")}
            disabled={ipadsLoading}
          >
            {ipadsLoading
              ? t("words.submitting")
              : t("pages.settings.policy.reset.button.ipad")}
          </ActionButton>
        )}
        {macosPolicyManagement && (
          <ActionButton
            Icon={ResetIcon}
            secondary
            onClick={() => toggle("macos")}
            disabled={macosLoading}
          >
            {macosLoading
              ? t("words.submitting")
              : t("pages.settings.policy.reset.button.macos")}
          </ActionButton>
        )}
        <ResetPolicyModal />
      </div>
    </>
  );
}

export default ResetPolicyManagementButtons;

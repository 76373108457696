import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import Parameters from "./Parameters";
import ModalCard, {
  ModalCardBody,
  ModalCardTitle,
  ModalCardButtons,
} from "../layout/ModalCard";
function AppDownloadModal({ app, toggle, setTaskId }) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);

  const handleDownload = () => {
    setLoading(true);
    const parameters = gatherParameters() || [];
    let reqBody = {
      detail: [{ applicationId: app.id, parameters: parameters }],
      kind: "cre",
      target: "app",
    };
    postApi("/tasks/", reqBody).then((response) => {
      setLoading(false);
      setTaskId(response.data.identifier);
      toggle();
    });
  };

  const gatherParameters = () => {
    let response = [];
    for (let i = 0; i < app.parameters.length; i++) {
      const parameter = app.parameters[i];
      switch (parameter.type) {
        case "boolean":
          response.push({
            id: parameter.id,
            value: document.getElementById(parameter.id).checked,
          });
          break;
        case "text":
          response.push({
            id: parameter.id,
            value: document.getElementById(parameter.id).value,
          });
          break;
        default:
          break;
      }
    }
    return response;
  };

  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>
          {t("pages.appstore.actions.download")} "{app.display_name}"
        </ModalCardTitle>
        <ModalCardBody>
          <div className="space-y-4">
          {Array.isArray(app.parameters) &&
            app.parameters.map((parameter) => (
              <Parameters parameter={parameter} />
            ))}</div>
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t("pages.appstore.actions.cancel")}
          </StandardButton>
          <StandardButton loading={loading} onClick={handleDownload}>
            {t("pages.appstore.actions.download")}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default AppDownloadModal;

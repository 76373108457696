import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import MultilineTranslation from "../layout/MultilineTranslation";
import StandardButton from "../layout/buttons/StandardButton";
import { postApi } from "../../tools/axiosInstances";
import { PortalContext } from "../../PortalContext";
import { ReactComponent as CheckCircle } from "../../components/icons/checkCircle.svg";
function MacosOnboarding({ target }) {
  const { t } = useTranslation("global");
  const { macosOnboarded, setMacosOnboarded, setDeviceMode } =
    useContext(PortalContext);

  const handleActivate = () => {
    const userInfo = JSON.parse(sessionStorage.getItem("me"));
    postApi("/settings/macos/requestonboard/", { userInfo: userInfo })
      // Call the API to send the notification
      .then(() => {
        setMacosOnboarded("requested");
      });
  };

  return (
    <div className="p-4 m-2 rounded-lg w-[850px] shadow bg-white space-y-4">
      {macosOnboarded === "requested" ? (
        <>
          <h3 className="text-lg text-fblack font-medium my-2 flex space-x-2">
            <CheckCircle />
            <span>{t(`pages.onboard.macos.title_requested`)}</span>
          </h3>
          <div className="text-fgray-400">
            <MultilineTranslation
              text={t("pages.onboard.macos.body_requested")}
            />
          </div>
        </>
      ) : (
        <>
          <h3 className="text-lg text-fblack font-medium my-2">
            {t(`pages.onboard.macos.${target || "device"}.title`)}
          </h3>
          <div className="text-fgray-400">
            <MultilineTranslation
              text={t(`pages.onboard.macos.${target || "device"}.body`)}
            />
          </div>

          <div className="flex space-x-2">
            <StandardButton onClick={handleActivate}>
              {t("words.activate")}
            </StandardButton>
            <StandardButton onClick={() => setDeviceMode("windows")}>
              {t("words.not_now")}
            </StandardButton>
          </div>
        </>
      )}
    </div>
  );
}

export default MacosOnboarding;

import React, { useContext, useState } from "react";
import { getApi } from "../../tools/axiosInstances";
import { useInterval } from "../../tools/utilties";
import { PortalContext } from "../../PortalContext";

function GroupProgress({ taskId, setTaskId }) {
  const [progress, setProgress] = useState(0);
  const { makeRefresh } = useContext(PortalContext);
  useInterval(() => {
    getApi(`/tasks/${taskId}/`, {}).then((response) => {
      setProgress(response.data.progress);
    });
    if (progress == 100) {
      setTaskId(false);
      makeRefresh && makeRefresh((prev) => prev + 1);
    }
  }, 5000);

  return <></>;
}

export default GroupProgress;

import React, { useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardTitle,
  ModalCardButtons,
} from "../layout/ModalCard";
import ActionButton from "../layout/buttons/ActionButton";
import { PortalContext } from "../../PortalContext";
function ApplicationActionModalButton({
  selected,
  action,
  Icon,
  clear,
  filter,
}) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { deviceMode } = useContext(PortalContext);
  const toggleModal = () => selected?.length > 0 && setShowModal((v) => !v);
  const hide = () => setShowModal(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, hide);
  function handleActionClick() {
    setLoading(true);
    let reqBody = "";
    switch (action) {
      case "unmanage":
        reqBody = {
          kind: "unm",
          target: "app",
          detail: [],
        };
        break;
      case "uninstall":
        reqBody = {
          kind: "usg",
          target: "app",
          detail: [],
        };
        break;
      case "install":
        reqBody = {
          kind: "asg",
          target: "app",
          detail: [],
        };
        break;
      case "delete":
        reqBody = {
          kind: "del",
          target: "app",
          detail: [],
        };
        break;
      default:
        break;
    }
    selected &&
      selected.map((app) => {
        reqBody.detail.push({ appId: app.original.id, groupId: filter });
      });

    postApi("/tasks/", reqBody).then((response) => {
      hide();
      setLoading(false);
      clear && clear();
    });
  }

  return (
    <>
      <ActionButton
        id={action}
        Icon={Icon}
        onClick={toggleModal}
        tooltip={`tooltip.application.${deviceMode}.${action}`}
        subtle
      >
        {t(`pages.application.${action}`)}
      </ActionButton>
      {showModal && selected.length > 0 && (
        <ModalCard wrapper={wrapper}>
          <ModalCardTitle>
            {t(`pages.application.modal.title_${action}`, {
              appName: selected[0].original.displayName,
              count: selected.length,
            })}
          </ModalCardTitle>
          <ModalCardBody>
            {t(`pages.application.modal.confirm_${action}`, {
              appName: selected[0].original.displayName,
              count: selected.length,
            })}
          </ModalCardBody>
          <ModalCardButtons>
            <StandardButton onClick={toggleModal} subtle>
              {t("pages.application.modal.cancel")}
            </StandardButton>
            <StandardButton onClick={handleActionClick} loading={loading}>
              {t(`pages.application.modal.${action}`)}
            </StandardButton>
          </ModalCardButtons>
        </ModalCard>
      )}
    </>
  );
}

export default ApplicationActionModalButton;

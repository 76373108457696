import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function RestoreButton(props) {
  const { t } = useTranslation("global");
  const [labelText, setLabelText] = useState("pages.policy.restore");
  const handleOnClick = (e) => {
    e.preventDefault();
    if (labelText !== "words.refresh") {
      setLabelText("words.submitting");
      props.triggerRestore();
      setTimeout(() => {
        setLabelText("words.refresh");
      }, 5000);
    } else if(labelText === "words.refresh") {
      props.setReload((p) => p + 1);
    }
  };

  return (
    <div
      className="bg-fgray-100 text-black mx-1 hover:bg-forange-400 hover:text-white rounded-lg text-base font-medium py-2 px-5 focus:outline-none"
      onClick={handleOnClick}
    >
      {t(labelText)}
    </div>
  );
}

export default RestoreButton;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Field from "../layout/design/Field";
import { getApiDelegated } from "../../tools/axiosInstances";
import Combobox from "../layout/design/Combobox";
function BitlockerFields({ bitlockerKeys }) {
  const { t } = useTranslation("global");
  const [recoveryKey, setRecoveryKey] = useState(null);
  const [selectedKey, setSelectedKey] = useState(bitlockerKeys[0].id);
  useEffect(() => {
    setRecoveryKey(null)
    getApiDelegated(`/device/bitlockerkey/${selectedKey}/`).then((rKey) => {
      setRecoveryKey(rKey.data.key);
    });
  }, [selectedKey]);

  return (
    <>
      <div className=" space-y-5">
        <div className="">
          <div className="font-normal text-sx text-fgray-300 my-1">
            {t("pages.device_detail.bitlocker_key")}
          </div>
          <select
            name="Keys"
            id="bitlockerkeys"
            onChange={(e) => setSelectedKey(e.target.value)}
            defaultValue={bitlockerKeys[0]}
            className="w-full rounded-lg border border-fgray-300 py-3 px-4"
          >
            {bitlockerKeys &&
              bitlockerKeys.map(({ id }) => (
                <option value={id} key={`bk_${id}`}>
                  {id}
                </option>
              ))}
          </select>
        </div>
        <Field
          title={t("pages.device_detail.recovery_key")}
          value={recoveryKey}
          copy
          loading={!recoveryKey}
        />
      </div>
    </>
  );
}

export default BitlockerFields;

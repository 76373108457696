import React, { useState, useEffect } from "react";
import { ReactComponent as Warning } from "../icons/warning.svg";
import { ReactComponent as Error } from "../icons/circleX.svg";
import { ReactComponent as OK } from "../icons/ok.svg";
import { ReactComponent as Spinner } from "../icons/spinner.svg";
import { ReactComponent as Exclamation } from "../icons/circleExclamation.svg";
import { ReactComponent as Chevron } from "../icons/arrow.svg";

import moment from "moment";
import { useTranslation } from "react-i18next";
import ActionButton from "../layout/buttons/ActionButton";
import TaskDescription from "./TaskDescription";
import TaskStatusSpinner from "./TaskStatusSpinner";
import { postApi } from "../../tools/axiosInstances";
import IconButton from "../layout/buttons/IconButton";
import ResultFilterSelect from "./ResultFilterSelect";
import TaskResults from "./TaskResults";
function TaskCollapsible({ task }) {
  const { t } = useTranslation("global");
  const newTask = Object.keys(task.results).length > 0; //|| ['printer','application'].includes(task.target);
  function handleTryAgain() {
    postApi(`/tasks/${task.identifier}/retry/`, "");
  }
  return (
    <>
      <details className="group open:transition open:duration-300 w-full">
        <summary className="px-4 border-t-2 outline-fgray-300 list-none flex items-center cursor-pointer rounded group open:rounded text-black justify-between hover:font-semibold font-medium">
          <div className="flex w-64 justify-between">
            <div className="w-8">
              <TaskStatusLogo task={task} />
            </div>
            <div className="w-56">{`${moment(task.start_time).format(
              "L"
            )}, ${moment(task.start_time).format("H:mm")} - ${
              task.end_time ? moment(task.end_time).format("H:mm") : ""
            }`}</div>
          </div>
          <div className="w-[730px] flex justify-between align-middle items-center">
            <div className="py-4 ">
              {newTask ? (
                <p className="line-clamp-3">
                  {t(
                    `tasks.${task.kind}.${task.target}`,
                    `tasks.${task.kind}.${task.target}`,
                    {
                      ...task.display,
                      target: t(
                        `categories.name.${task.display.target}`,
                        task.display.target
                      ),
                    }
                  )}
                </p>
              ) : (
                <TaskDescription task={task} />
              )}
            </div>
            {(["cer", "cwr"].includes(task.state) || task.progress == -10) && (
              <ActionButton secondary onClick={handleTryAgain}>
                {t("pages.progress.action.try_again")}
              </ActionButton>
            )}
          </div>
          {newTask ? (
            <div className=" group-open:rotate-180 transition duration-300 hover:bg-fgray-200 p-2 rounded-md">
              <Chevron />
            </div>
          ) : (
            <div className="px-5 rounded-md"></div> //Place holder to align the missing Chevron
          )}
        </summary>
        {newTask && (
          <div className="px-16">
            <TaskResults task={task} />
          </div>
        )}
      </details>
    </>
  );
}

export default TaskCollapsible;

export function TaskStatusLogo({ task, result }) {
  const newTask = task?.results && Object.keys(task?.results).length > 0;
  if (newTask || result) {
    switch (task.state) {
      case "new":
      case "wip":
        return <Spinner />;
        break;
      case "css":
        return <OK className="h-6 w-6 text-fstatus-200 stroke-2" />;
      case "cwe":
      case "cer":
        return <Error className="h-6 w-6 text-fstatus-400 stroke-2" />;
      case "cww":
      case "cwr":
        return <Warning className="h-6 w-6 text-fstatus-300 stroke-2" />;
      case "unk":
        return <Exclamation className="h-6 w-6 text-forange-400" />;
      default:
        return <></>;
        break;
    }
  } else {
    return <TaskStatusSpinner progress={task.progress} />;
  }
}

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

function Combobox({ options, setSelected, label, title,noBlank, ...attributes }) {
  const { t } = useTranslation("global");
  const handleChange = (e) => {
    setSelected && setSelected(e.target.value);
  };
  return (
    <div className="w-full">
      {title && (
        <div className="font-normal text-sx text-fgray-300 my-1">{title}</div>
      )}
      <select
        onChange={handleChange}
        className="w-full rounded-lg border border-fgray-300 py-3 px-4"
        {...attributes}
      >
       {!noBlank && <option value="">{label || t("words.select_value")}</option>}
        {options &&
          options.map(
            (opt, index) =>
              opt && (
                <option key={index} value={opt.value || opt.label || opt} selected={opt?.selected}>
                  {opt.label ? t(opt.label) : opt}
                </option>
              )
          )}
      </select>
    </div>
  );
}

export default Combobox;

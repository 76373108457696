import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditableField from "../layout/design/EditableField";
import IconButton from "../layout/buttons/IconButton";
import { ReactComponent as SaveIcon } from "../icons/save.svg";
import { postApi } from "../../tools/axiosInstances";
import Combobox from "../layout/design/Combobox";
import { useNavigate } from "react-router-dom";
import HrzSwitch from "../policy/HrzSwitch";
import HorizontalSwitch from "../layout/design/HorizontalSwitch";
function PrinterBasicInfo({ deviceInfo }) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [enableCreate, setEnableCreate] = useState(false);
  const [templateId, setTemplateId] = useState(deviceInfo.templateId);
  const [manufacturer, setManufacturer] = useState(deviceInfo.manufacturer);
  const [modelList, setModelList] = useState([]);
  const [colorAllowed, setColorAllowed] = useState(
    () =>
      deviceInfo.properties.find((e) => e["name"] === "colorAllowed") 
  );
  const creating = deviceInfo.id == "00000000-0000-0000-0000-000000000000";
  const navigate = useNavigate();
  const canCreate = () => {
    document.getElementById("printerIpAddress").value != "" &&
    document.getElementById("printerName").value != "" &&
    templateId != false
      ? setEnableCreate(true)
      : setEnableCreate(false);
  };
  const canSave = () => {
    (document.getElementById("printerIpAddress").value !=
      deviceInfo.ipAddress ||
      document.getElementById("printerName").value != deviceInfo.displayName ||
    (colorAllowed && document.getElementById("colorAllowed").checked != colorAllowed.enabled) ||
    templateId != deviceInfo.templateId) &&
    templateId != false
      ? setEnableSave(true)
      : setEnableSave(false);
  };
  const manufacturerList = [
    ...new Set([
      ...deviceInfo.templates.map((template) => template.manufacturer),
    ]),
  ].map((man) => ({
    label: man,
    selected: man == deviceInfo.manufacturer,
  }));
  useEffect(() => {
    let models = [];
    deviceInfo.templates.map((template) => {
      template.manufacturer == manufacturer &&
        models.push({
          value: template.templateId,
          label: template.model,
          selected: template.selected,
        });
    });
    deviceInfo.manufacturer == manufacturer
      ? setTemplateId(deviceInfo.templateId)
      : setTemplateId(false);
    setModelList(models);
    canSave();
  }, [manufacturer]);
  useEffect(() => {
    creating ? canCreate() : canSave();
    creating && setColorAllowed(() => {
      try {
        const info =
          deviceInfo.templates.find((e) => e["templateId"] === templateId) ||
          false;
        if (info) {
          return (
            info.properties.find((e) => e["name"] === "colorAllowed") || false
          );
        } else {
          return false;
        }
      } catch {
        return false;
      }
    });
  }, [templateId]);
  const handleOnSave = () => {
    setLoading(true);
    let payload = {
      kind: "upd",
      target: "pri",
      detail: [
        {
          printerId: deviceInfo.id,
          templateId: templateId,
          ipAddress: document.getElementById("printerIpAddress").value,
          displayName: document.getElementById("printerName").value,
          properties: [],
        },
      ],
    };
    if (colorAllowed) {
      payload.detail[0].properties.push({
        name: "colorAllowed",
        enabled: document.getElementById("colorAllowed").checked,
      });
    }
    postApi("/tasks/", payload).then((a) => {
      setLoading(false);
      setEnableSave(false);
    });
  };
  const handleCreate = () => {
    setLoading(true);
    let payload = {
      kind: "cre",
      target: "pri",
      detail: [
        {
          templateId: templateId,
          ipAddress: document.getElementById("printerIpAddress").value,
          displayName: document.getElementById("printerName").value,
          properties:[]
        },
      ],
    };
    if (colorAllowed) {
      payload.detail[0].properties.push({
        name: "colorAllowed",
        enabled: document.getElementById("colorAllowed").checked,
      });
    }
    postApi("/tasks/", payload).then((a) => {
      setLoading(false);
      navigate(-1);
    });
  };
  return (
    <>
      <div className="w-full pr-2 space-y-5">
        <EditableField
          id="printerName"
          title={t("properties.name")}
          value={deviceInfo.displayName}
          setEditMode={creating ? canCreate : canSave}
        />
        <Combobox
          options={manufacturerList}
          title={t("properties.manufacturer")}
          noBlank={templateId}
          setSelected={setManufacturer}
        />
        <Combobox
          options={modelList}
          title={t("properties.model")}
          noBlank={templateId}
          setSelected={setTemplateId}
        />
        <EditableField
          id="printerIpAddress"
          title={t("properties.ip_address")}
          setEditMode={creating ? canCreate : canSave}
          value={deviceInfo.ipAddress}
        />
        {colorAllowed && (
          <div className="py-3 flex justify-between rounded-md ">
            {t("properties.allow_color")}
            <HorizontalSwitch
              enabled={colorAllowed?.enabled || false}
              id="colorAllowed"
              setEditMode={creating ? canCreate : canSave}
            />
          </div>
        )}
        <div className="mt-10 w-full">
          {creating ? (
            <IconButton
              Icon={SaveIcon}
              onClick={handleCreate}
              disabled={!enableCreate}
            >
              {loading ? t("words.loading") : t("actions.add")}
            </IconButton>
          ) : (
            <IconButton
              Icon={SaveIcon}
              onClick={handleOnSave}
              disabled={!enableSave}
            >
              {loading ? t("words.loading") : t("words.save")}
            </IconButton>
          )}
        </div>
      </div>
    </>
  );
}

export default PrinterBasicInfo;

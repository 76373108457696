import { useTranslation } from "react-i18next";
import { PeoplePicker, LocalizationHelper, Person } from "@microsoft/mgt-react";

function PeopleSelector({ setSelected }) {
  const { t } = useTranslation("global");

  const SelectedPersonTemplate = (props) => {
    return (
      <div className="flex">
        <Person userId={props.dataContext.person.id} fetch-image></Person>
        <span className="font-normal ml-1">
          {props.dataContext.person.displayName}
        </span>
      </div>
    );
  };
  const PersonTemplate = (props) => {
    return (
      
      <div className="flex">
        <Person userId={props.dataContext.person.id} fetch-image></Person>
        <div className="pl-2">
          <p className="font-normal">{props.dataContext.person.displayName}</p>
          <p className="text-sm text-fgray-300">{props.dataContext.person.userPrincipalName}</p>
        </div>
      </div>
    );
  };

  LocalizationHelper._strings = {
    _components: {
      "people-picker": {
        inputPlaceholderText: t("components.people_picker.placeholder"),
        loadingMessage: t("components.people_picker.loading"),
        noResultsFound: t("components.people_picker.no_results"),
      },
    },
  };

  return (
    <div className="rounded-lg border px-2 py-1 border-fgray-300 bg-white">
      <PeoplePicker
        type="person"
        userType="user"
        style={"--input-border: 0px;"}
        selectionChanged={(e) => setSelected(e.detail)}
      >
        <SelectedPersonTemplate template="selected-person"></SelectedPersonTemplate>
        <PersonTemplate template="person" view="twoLines"></PersonTemplate>
      </PeoplePicker>
    </div>
  );
}

export default PeopleSelector;

import { useTranslation } from "react-i18next";
import React, { useState } from "react";

function HrzSwitch({
  status,
  profile,
  editMode,
  editable,
  parentStatus,
  setStatus,
}) {
  const [isChecked, setIsChecked] = useState(status || false);
  const { t } = useTranslation("global");

  function toggleCheck(e) {
    editMode && editable && parentStatus && setIsChecked(e.target.checked);
    editMode && editable && parentStatus && setStatus(e.target.checked);
  }

  return (
    <>
      <label
        htmlFor={profile.id}
        className={
          `inline-flex relative items-center cursor-pointer` +
          (editMode ? (editable ? "" : ` group/item`) : "") +
          (editMode
            ? editable && !profile.childs && !parentStatus
              ? ` group/item2`
              : ""
            : "")
        }
      >
        <input
          data-tooltip-target="tooltip-default"
          type="checkbox"
          value=""
          id={profile.id}
          className="sr-only peer"
          checked={status && parentStatus}
          onChange={toggleCheck}
        />
        <div className="w-11 h-6 bg-fgray-300 peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-forange-400"></div>
        <span className="absolute hidden right-72 translate-x-full w-60 px-2 py-1 bg-fgray-400 rounded-lg text-center text-white text-sm before:content-[''] before:absolute before:top-1/2  before:left-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-r-transparent before:border-l-fgray-400 group-hover/item:flex">
          {t("pages.policy.room_category_advisory")}
        </span>
        <span className="absolute hidden right-72 translate-x-full w-60 px-2 py-1 bg-fgray-400 rounded-lg text-center text-white text-sm before:content-[''] before:absolute before:top-1/2  before:left-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-r-transparent before:border-l-fgray-400 group-hover/item2:flex">
          {t("pages.policy.parent_advisory")}
        </span>
      </label>{" "}
    </>
  );
}

export default HrzSwitch;

import React from "react";
import { Link } from "react-router-dom";
function HamburgerMenuItem({ destination, title, Logo, target, disabled }) {
  return (
    <Link to={disabled ? "#" : destination} target={target}>
      <div
        className={`rounded px-2 py-3 font-normal flex border-gray-400 bg-white ${
          disabled
            ? "text-fgray-300"
            : "hover:text-forange-400 hover:font-medium "
        }`}
      >
        <Logo className="h-6 w-6" />
        <div className="px-3 text-left">{title}</div>
      </div>
    </Link>
  );
}

export default HamburgerMenuItem;

import React from 'react'

function LoadingSidebar() {
  return (
    <div className='animate-pulse'>
      <div className='px-4 pb-3  list-none content-center  active:rounded'>
        <div className='mt-4 h-6  rounded content-center bg-fgray-200' />
        <div className='mt-4 h-6  rounded content-center bg-fgray-200' />
        <div className='mt-4 h-6  rounded content-center bg-fgray-200' />

        </div>
    </div>
  )
}

export default LoadingSidebar
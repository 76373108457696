import React from "react";

function LoadingTable() {
  return (
    <div className="animate-pulse">
      <div className='h-16 flex bottom-1'>
        <div className='mt-5 text-middle px-5 '>
          <div className='h-5 w-5 rounded content-center bg-fgray-200' />
        </div>
        <div className='mt-5 text-middle px-5 '>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      <div className='mt-5 text-middle px-5'>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      <div className='mt-5 text-middle px-5'>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      
      </div>
      <div className='h-16 flex bottom-1'>
        <div className='mt-5 text-middle px-5 '>
          <div className='h-5 w-5 rounded content-center bg-fgray-200' />
        </div>
        <div className='mt-5 text-middle px-5 '>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      <div className='mt-5 text-middle px-5'>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      <div className='mt-5 text-middle px-5'>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      
      </div>
      <div className='h-16 flex bottom-1'>
        <div className='mt-5 text-middle px-5 '>
          <div className='h-5 w-5 rounded content-center bg-fgray-200' />
        </div>
        <div className='mt-5 text-middle px-5 '>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      <div className='mt-5 text-middle px-5'>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      <div className='mt-5 text-middle px-5'>
        <div className='h-5 w-48 rounded content-center bg-fgray-200' />
      </div>
      
      </div>
    </div>
  );
}

export default LoadingTable;

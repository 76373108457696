import React from 'react'
import { useTranslation } from 'react-i18next'
function DeviceAppsBox({deviceApps}) {
  const {t} = useTranslation("global")
  return (
    <>
    <div className="border-fgray-300 border rounded-xl w-full h-[calc(100vh-21.5rem)] overflow-y-scroll overflow-x-hidden ">
      <table className="overflow-scroll text-center rounded-t-xl w-full">
        <thead className=" sticky top-0 rounded-t-xl bg-white overflow-hidden">
          <tr className="py-4 text-fgray-300 rounded-t-xl border-b-1 border-b-fgray-300">
            <th className="truncate">{t("pages.application.table.application_name")}</th>
            <th className="truncate">{t("pages.application.table.version")}</th>
          </tr>
        </thead>
        <tbody>
          {deviceApps.map((app)=>{
            return (
              <tr key={app.id} className="border-y-2 hover:font-medium font-normal hover:bg-forange-200 bg-white">
                <td className="py-5 px-8 text-left">{app.displayName}</td>
                <td className="py-5 px-8 text-left">{app.version}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default DeviceAppsBox
import React from "react";
import { useTranslation } from "react-i18next";

function DataPrivacy() {
  const { t } = useTranslation("global");

  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 overscroll-y-auto justify-between ">
          <div className="text-2xl font-medium mx-auto">
            {t("pages.data_protection.title")}
          </div>
        </div>
      </div>
      <div className="p-6 mb-12 max-w-6xl mx-auto space-y-6 text-justify overflow-y-auto overflow-x-hidden">
        <p>
          Hier erläutern wir Ihnen, welche personenbezogenen Daten wir speichern
          oder verarbeiten, warum wir dies tun, wie lange diese Daten
          gespeichert werden, wie wir sicherstellen, dass Sie die Kontrolle über
          Ihre Daten behalten, und wie Sie darüber von uns Auskunft erhalten
          können.
        </p>
        <p>
          <strong>Zweck der Verarbeitung: </strong>
          Eine Microsoft Cloud Instanz (technisch genannt „Tenant“) stellt viele
          einzelne Dienste bereit, die teilweise lokal installiert werden und
          teilweise in zentralen Rechenzentren von Microsoft („Cloud“)
          ausgeführt werden. Speziell in der EU wird Office 365 und Azure von
          Microsoft Ireland Operations Limited, Carmanhall Road, Sandyford
          Industrial Estate, Dublin 18, Irland („Microsoft Irland“) angeboten.
          Die Verwaltung dieser Dienste erfolgt teilweise über ein webbasiertes
          „Microsoft Endpoint Manager“ Administrationsportal und teilweise über
          Programmier-Schnittstellen. Das vorliegende Portal portal.numiato.de ist
          eine Webapplikation, die Ihnen eine einheitliche vereinfachte
          Verwaltung von „Microsoft Endpoint Manager“ ermöglicht, die auf
          Bildungseinrichtungen zugeschnitten ist und keine speziellen
          technischen Kenntnisse erfordert. Im Hintergrund leitet die
          Webapplikation Ihre Eingaben an das „Microsoft Endpoint Manager“
          Administrationsportal und die Programmier-Schnittstellen weiter und
          fügt somit eine Verarbeitungs-Ebene zwischen Ihnen als Kunden und
          „Microsoft Endpoint Manager“ ein.
        </p>
        <p>
          <strong>Rechtsgrundlage: </strong>Damit wir diese Dienstleistung
          erbringen können, müssen wir gemäß Art. 6 Abs. 1 lit. b) DSGVO gewisse
          personenbezogene Daten speichern, sodass Sie als Kunde bei
          wiederholter Anmeldung auf Ihre hinterlegten Daten zugreifen können.
          Personenbezogene Daten, die im Zuge Ihrer Eingaben zur Verwaltung
          Ihrer „Microsoft Endpoint Manager“ Instanz dienen, wie z. B. die
          Einstellungen auf Ihren Geräten und deren Eigenschaften, leiten wir
          dagegen an die „Microsoft Endpoint Manager“ Instanz weiter, ohne diese
          zu speichern. Bitte beachten Sie, dass wir für die Nutzung der
          kostenlosen Dienste weder Ihren Klarnamen noch Ihre Adresse kennen
          oder erfragen.
        </p>
        <p>
          <strong>
            Gespeicherte personenbezogene Daten für die kostenlosen Angebote in
            diesem Portal:{" "}
          </strong>
          Dies sind folgende Daten: (1) Der Office 365/Azure AD Anmeldename
          derjenigen bestehenden Office 365/Azure AD Konten, denen Sie das Recht
          zur Verwaltung der „Microsoft Endpoint Manager“ Instanz über dieses
          Portal einräumen. (2) Hilfe und Support: Wenn Sie Fragen oder Probleme
          bei der Nutzung unseres Portals haben, wenden Sie sich per E-Mail an
          uns. Diese E-Mails speichern wir, um mit Ihnen zwecks Klärung der
          Fragen kommunizieren zu können, werten sie aber nicht automatisiert
          aus (sog. Profiling).
        </p>
        <p>
          <strong>
            Gespeicherte personenbezogene Daten für die kostenpflichtigen
            Angebote in diesem Portal:{" "}
          </strong>
          Für die Rechnungsstellung speichern wir zusätzlich den von der
          Bildungseinrichtung benannten Rechnungsempfänger und einen technischen
          Ansprechpartner inklusive Adresse, E-Mail-Adresse und Telefonnummer.
        </p>
        <p>
          <strong>
            Verarbeitete, aber nicht gespeicherte personenbezogene Daten:{" "}
          </strong>
          Alle Kontodaten, wie Kontonamen, Anzeigenamen, Passwörter, Funktionen,
          Berechtigungen, die Sie in unserem Portal direkt eingeben oder per
          csv-Datei hochladen, werden von der Webapplikation an die
          entsprechende „Microsoft Endpoint Manager“ Instanz weitergeleitet,
          aber nicht auf unseren Servern gespeichert.
        </p>
        <p>
          <strong>Ort und Art der Speicherung: </strong>Unsere Server, auf denen
          die Webapplikation ausgeführt wird, sind virtuelle Server und liegen
          in der Microsoft Azure Cloud in europäischen Rechenzentren, die von
          Microsoft Irland betrieben werden. Es ist vertraglich gesichert, dass
          die oben angegebenen personenbezogenen Daten, die wir für die
          Erbringung unserer Dienstleistung speichern, den EU-Raum nicht
          verlassen und alle Daten im Transfer und in den Microsoft
          Rechenzentren verschlüsselt sind. Unsere Clients, die zur Verwaltung
          der Dienste dienen, nutzen die höchste Kategorie der von Microsoft
          bereitgestellten Schutzfunktionen, um die Sicherheit Ihrer Daten nach
          Stand der Technik zu gewährleisten.
        </p>
        <p>
          <strong>Weitergabe von personenbezogenen Daten: </strong>Wir geben die
          oben angeführten gespeicherten personenbezogenen Daten nicht an Dritte
          weiter und werten diese Daten nicht automatisiert aus. Unser Angebot
          ist werbefrei und wir setzen keine Cookies ein. Die zum Zwecke der
          Erbringung der Dienstleistung von Ihnen eingegeben Daten leiten wir an
          Ihre „Microsoft Endpoint Manager“ Instanzen von Microsoft Irland
          weiter, um die von Ihnen veranlassten Bearbeitungen der „Microsoft
          Endpoint Manager“ Instanz zu ermöglichen. Die Daten, die Sie zur
          Verwaltung von Ihren „Microsoft Endpoint Manager“ Instanzen in unserem
          Portal eingeben, werden anschließend in denselben EU Rechenzentren von
          Microsoft Irland verarbeitet. Für diese von Microsoft Irland
          verarbeiteten personenbezogenen Daten ist alleine der Vertrag zur
          Auftragsverarbeitung, den Ihre Bildungseinrichtung mit Microsoft
          Irland abgeschlossen hat, und die Datenschutzerklärung{" "}
          <a
            href="https://privacy.microsoft.com/de-de/privacystatement"
            className="text-forange-400 underline"
          >
            https://privacy.microsoft.com/de-de/privacystatement
          </a>{" "}
          von Microsoft relevant.
        </p>
        <p>
          <strong>Dauer der Speicherung personenbezogener Daten: </strong>Wir
          speichern die oben beschriebenen administrativen Benutzerprofile
          (1)-(3) solange Sie Ihre „Microsoft Endpoint Manager“ Instanzen über
          portal.numiato.de verwalten wollen. Wir speichern Support-E-Mails
          grundsätzlich 5 Jahre, ältere werden automatisch gelöscht.
        </p>
        <p>
          <strong>
            Recht auf Löschung und Beendigung der Dienstleistung:{" "}
          </strong>{" "}
          Sie können unter Einstellungen jederzeit Ihre von uns gespeicherten
          personenbezogenen Daten selbst löschen und damit die Nutzung des
          Portals portal.numiato.de beenden. Beachten Sie bitte auch die auf den
          folgenden Seiten angegebene Anleitung zur Löschung unserer
          Webapplikation „School Management Portal“ in Ihren Microsoft Azure
          Active Directory Instanzen. Diese Löschungsvorgänge haben keinen
          Einfluss auf die Daten in Ihren Office 365 Instanzen, die davon
          unberührt bleiben.
        </p>
        <p>
          <strong>Datenschutzrechtlich Verantwortliche: </strong>Für die
          Verarbeitung jeglicher Informationen und personenbezogener Daten sind
          wir, die Numiato GmbH, Doktorbäuerinweg 1, 82041 Deisenhofen,
          Verantwortliche im Sinne der datenschutzrechtlichen Vorschriften. Ihre
          gesetzlichen Rechte auf Auskunft, Berichtigung, Löschung,
          Einschränkung der Verarbeitung, Widerspruch, Widerruf und
          Datenübertragbarkeit können Sie uns gegenüber durch eine E-Mail an{" "}
          <a
            href="mailto:privacy@numiato.de"
            className="text-forange-400 underline"
          >
            privacy@numiato.de
          </a>{" "}
          oder schriftlich geltend machen. Zudem weisen wir darauf hin, dass Sie
          das Recht auf Beschwerde bei einer Aufsichtsbehörde haben.
        </p>
        <p>
          <strong>Microsoft Clarity: </strong>Zur Verbesserung unseres Produkt
          nutzen wir Microsoft Clarity. Anbieter ist die Microsoft Ireland
          Operations Limited, One Microsoft Place, South County Business Park,
          Leopardstown, Dublin 18, Irland. &nbsp;
          <a
            className="text-forange-400 underline"
            href="https://docs.microsoft.com/en-us/clarity/ "
          >
            https://docs.microsoft.com/en-us/clarity/ 
          </a>
          &nbsp;(im Folgenden „Clarity“).
        </p>
        <p>
          Clarity ist ein Werkzeug zur Analyse des Nutzerverhaltens auf dieser
          Website. Hierbei erfasst Clarity insbesondere Mausbewegungen und
          erstellt eine grafische Darstellung darüber, auf welchen Teil der
          Website Nutzer besonders häufig scrollen (Heatmaps). Clarity kann
          ferner Sitzungen aufzeichnen, sodass wir die Seitennutzung in Form von
          Videos ansehen können. Ferner erhalten wir Angaben über das allgemeine
          Nutzerverhalten innerhalb unserer Website.{" "}
        </p>
        <p>
          Clarity verwendet Technologien, die die Wiedererkennung des Nutzers
          zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z. B. Cookies
          oder Einsatz von Device-Fingerprinting). Ihre personenbezogenen Daten
          werden auf den Servern von Microsoft (Microsoft Azure Cloud Service)
          in den USA gespeichert.{" "}
        </p>
        <p>
          Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
          Einsatz des o. g. Dienstes ausschließlich auf Grundlage von Art. 6
          Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit
          widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt die
          Verwendung dieses Dienstes auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO; der Websitebetreiber hat ein berechtigtes Interesse an einer
          effektiven Nutzeranalyse.{" "}
        </p>
        <p>
          Weitere Details zum Datenschutz von Clarity finden Sie hier: &nbsp;
          <a
            href="https://docs.microsoft.com/de-de/clarity/faq"
            className="text-forange-400 underline"
          >
            https://docs.microsoft.com/de-de/clarity/faq
          </a>
          .
        </p>

        <p>
          <strong>
            Die vorstehenden Datenschutzhinweise stehen auf dieser Seite auch
            separat zur Verfügung. Mit Ihrer Anmeldung an dieses Portal erklären
            Sie sich damit einverstanden, dass wir Ihre Daten - wie vorstehend
            beschrieben - für die oben genannten Zwecke erheben, verarbeiten,
            nutzen und an Microsoft Irland übermitteln dürfen. Andernfalls
            brechen Sie bitte den Anmeldeprozess ab.
          </strong>
        </p>
      </div>
    </>
  );
}

export default DataPrivacy;

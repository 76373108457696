import React from "react";
import Tooltip from "./tooltip/Tooltip";
import CountBadge from "./CountBadge";

function TabBar({ options, handler, activeTab, updateBadge, ...attributes }) {
  return (
    <>
      <div className="w-full flex border-b-[1px] text-fblack border-fgray-200 top-0 bg-white justify-left">
        {options.map((option, index) => {
          return (
            <Tooltip title={option.tooltip}>
              <div
                className={
                  `flex px-2 py-1 cursor-pointer  z-10 ` +
                  (activeTab === option.id
                    ? ` border-forange-400 border-b-4`
                    : `border-transparent`)
                }
                onClick={() => handler(option.id)}
                key={`tab_${index}`}
              >
                <p className="text-center min-w-[100px] text-sm font-medium px-2 truncate m-auto ">
                  {option.title}{" "}
                </p>
                {option.id == 2 && updateBadge > 0 && (
                  <CountBadge>{updateBadge}</CountBadge>
                )}
              </div>
              {/* <a
                  className={
                    `text-base min-w-25 px-5 py-3 cursor-pointer rounded-full block leading-normal ` +
                    (activeTab === index
                      ? "text-forange-400 bg-forange-100 font-medium"
                      : "text-black bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    handler(index);
                  }}
                  data-toggle="tab"
                  href="#Link1"
                  role="tablist"
                  key={`tab_${index}`}
                >
                  {option.title}
                </a> */}
            </Tooltip>
          );
        })}
      </div>
    </>
  );
}

export default TabBar;

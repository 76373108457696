import React from "react";
import RestoreButton from "../policy/RestoreButton";

function CollapsibleSummary(props) {
  return (
    <details
      open={props.open}
      className="group open:transition open:duration-300 -z-10"
    >
      <summary className="px-4 py-3 border-t-2 outline-fgray-300  list-none flex items-center cursor-pointer rounded group open:rounded text-black justify-between bg-white">
          <h3 className="font-medium text-left">{props.title}</h3>
        <div className="flex">
          <div>{props.reversable ? <RestoreButton {...props} /> : <div className="py-3"></div>}</div>
          <div className=" group-open:rotate-180 transition duration-300 hover:bg-fgray-200 p-2 rounded-md">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="flex-grow-0 flex-shrink-0 w-6 h-6"
              preserveAspectRatio="none"
            >
              <path
                d="M7 14.5L11.6464 9.85355C11.8417 9.65829 12.1583 9.65829 12.3536 9.85355L17 14.5"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
        </div>
      </summary>
      <div className="px-4">{props.children}</div>
    </details>
  );
}

export default CollapsibleSummary;

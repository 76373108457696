import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import CategoryCombo from "../layout/CategoryCombo";
import SubmitButton from "../layout/buttons/SubmitButton";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";

function DeviceAssignUnenrolledModal({
  selected,
  device,
  showModal,
  toggle,
  clear,
}) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [selCategory, setSelCategory] = useState(0);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);
  function handleAssignment(event) {
    event.preventDefault();
    setLoading(true);
    let reqBody = {
      kind: "asg",
      target: "dev",
      detail: [],
    };
    if (selCategory !== 0) {
      device
        ? reqBody.detail.push({
            deviceId: device.id,
            categoryId: selCategory,
            deviceType: "unenrolled",
          })
        : selected.map((dev) => {
            reqBody.detail.push({
              deviceId: dev.original.id,
              categoryId: selCategory,
              deviceType: "unenrolled",
            });
          });
    }
    selCategory &&
      postApi("/tasks/", reqBody).then((response) => {
        toggle();
        setLoading(false);
        clear && clear();
      });
  }
  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>{t("pages.device.modal.assign.title")}</ModalCardTitle>
        <ModalCardBody>
          <h2 className="text-fgray-400 font-small text-justify py-2">
            {device
              ? t("pages.device.modal.assign.bodyunenrolled_1")
              : selected.length === 1
              ? t("pages.device.modal.assign.bodyunenrolled_1")
              : t("pages.device.modal.assign.bodyunenrolled_n")}
          </h2>
          <div className="text-left">
            {t("pages.device.table.category")}
            <CategoryCombo
              className="text-red-700"
              setSelected={setSelCategory}
              includeUnassigned={true}
            />
          </div>
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t("pages.device.modal.cancel")}
          </StandardButton>
          <StandardButton onClick={handleAssignment} loading={loading}>
            {t("pages.device.assign")}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default DeviceAssignUnenrolledModal;

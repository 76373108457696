import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function Textbox({ definition, mode, profileId,prefilled }) {
  const {t} = useTranslation("global");
  const [valid, setValid] = useState(true);
  const [textValue, setTextValue] = useState(
    definition.selected ||  prefilled || ""
  );

  const onFocusHandler = (e) =>{
    if (textValue=="" && definition.suffix){
      setTextValue(definition.suffix)
      document.getElementById(definition.id).setSelectionRange(0,1)
    }
  }

//   function setCaretPosition(elemId, caretPos) {
//     var el = document.getElementById(elemId);

//     el.value = el.value;
//     // ^ this is used to not only get "focus", but
//     // to make sure we don't have it everything -selected-
//     // (it causes an issue in chrome, and having it doesn't hurt any other browser)

//     if (el !== null) {

//         if (el.createTextRange) {
//             var range = el.createTextRange();
//             range.move('character', caretPos);
//             range.select();
//             return true;
//         }

//         else {
//             // (el.selectionStart === 0 added for Firefox bug)
//             if (el.selectionStart || el.selectionStart === 0) {
//                 el.focus();
//                 el.setSelectionRange(caretPos, caretPos);
//                 return true;
//             }

//             else  { // fail city, fortunately this never happens (as far as I've tested) :)
//                 el.focus();
//                 return false;
//             }
//         }
//     }
// }
  function handleChange(e) {
    let texto = e.target.value;
    if (definition.suffix) {
      if(texto.length < definition.suffix.length){
        texto = definition.suffix
      }
      else if(texto.substring(texto.length - definition.suffix.length) === definition.suffix){
        setTextValue(texto)
      }
      else if(texto.substring(texto.length - definition.suffix.length + 1) === definition.suffix.substring(0,definition.suffix.length - 1)){
        const newText =
        texto.substring(0, texto.length - definition.suffix.length) +
        definition.suffix;
        setTextValue(newText)
      }
      else{
        const newText =
        texto.substring(0, texto.length - definition.suffix.length - 1) +
        texto.substring(texto.length - 1) +
        definition.suffix;
        setTextValue(newText)
      }
    }
    else{
      setTextValue(texto)
    }
    definition.suffix && e.target.setSelectionRange(
      e.target.value.length - definition.suffix.length,
      e.target.value.length - definition.suffix.length
    );
    
    if (e.target.value.match(definition.validator) != null) {
      setValid(true);
      const input = document.getElementById(profileId)
      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set.call(input, true);
      input.dispatchEvent(new Event("click", { bubbles: true }));
    } else {
      setValid(false);
      const input = document.getElementById(profileId)
      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set.call(input, false);
      input.dispatchEvent(new Event("click", { bubbles: true }));
    }
  }
  return (<div className={`${definition.preview &&  textValue && "flex"} w-full`} >
    <input
      type="text"
      name={definition.name}
      id={definition.id}
      required
      placeholder={definition.hint}
      className={`p-2 mx-4 my-2 rounded-lg text-sm border border-fgray-400  ${
        valid ? "" : " bg-red-200 text-red-800 "
      } ${definition.preview ? " w-1/2 ":" w-full"}`}
      onChange={handleChange}
      disabled={!mode}
      value={textValue}
      onFocus={onFocusHandler}
    />
    {definition.preview &&  textValue ?
    <div className="m-2 p-2 w-1/2">
      <span className="text-fgray-400">{t("words.preview")}: </span>
      <span className=" font-medium ">{`${definition.preview.prefix && definition.preview.prefix}${textValue.substring(0, textValue.length - definition.suffix.length)}${definition.preview.suffix && definition.preview.suffix}`}</span>
    </div> :""}
    </div>
  );
}

export default Textbox;

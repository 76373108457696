import React,{useState,Children} from 'react'

function DeviceDetailsBar({initial,setSelectedTab,children}) {
  const [selected, setSelected] = useState(initial)
  const arrayChildren = Children.toArray(children)
  const handleChangeTab = (target)=>{
    setSelected(target)
    setSelectedTab && setSelectedTab(target)
  }
  return (
    <>
      <div className="w-full flex border-b-[1px] text-fblack border-fgray-200 sticky top-0 bg-white justify-between cursor-pointer">
        <div className="flex gap-2">
          {arrayChildren.map((child) => {
            return (
            <div key={child.props.id} className={`flex px-3.5 py-1.5 cursor-pointer border-b-4 z-10 ${selected == child.props.id ?" border-forange-400":"border-transparent"}`} onClick={()=>handleChangeTab(child.props.id)}>
              {child.Icon && <child.Icon className="h-4 w-4 m-auto"/>}
              <p className="text-center text-sm font-medium px-2 truncate m-auto">{child.props.children}</p>
            </div>
          )})}
        </div>
      </div>
    </>
  );
}

export default DeviceDetailsBar
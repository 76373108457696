import React from "react";
import { useTranslation } from "react-i18next";
import Offboard from "./Offboard";
import Breakglass from "./Breakglass";
import CollapsibleSummary from "../layout/CollapsibleSummary";
import OrganizationInfo from "./OrganizationInfo";
import PaymentInfo from "./PaymentInfo";
function AccountSettings() {
  const { t } = useTranslation("global");
  return (
    <>
    <div className="max-w-[850px]">
      <CollapsibleSummary title={t(`pages.settings.account.org_info.title`)}>
        <OrganizationInfo />
      </CollapsibleSummary>
      {sessionStorage.getItem("role") === "smp.administrator" &&<> <CollapsibleSummary title={t(`pages.settings.account.break_glass.title`)}>
        <Breakglass />
      </CollapsibleSummary>
      <CollapsibleSummary title={t(`pages.settings.account.payment.title`)}>
        <PaymentInfo />
      </CollapsibleSummary>
      <CollapsibleSummary title={t(`pages.settings.account.offboard.title`)}>
        <Offboard />
      </CollapsibleSummary></>}
      </div></>
  );
}

export default AccountSettings;

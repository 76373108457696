import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import { UserLogout } from "../../tools/utilties";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";
import StandardButton from "../layout/buttons/StandardButton";
function RemoveAdminModal({ selectedAdmins, toggle, refresh }) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const wrapper = useRef(false);
  useToggleComponent(wrapper, toggle);
  function handleRemoveAdmin() {
    setLoading(true);
    let reqBody = { kind: "del", target: "set", type: "admin", detail: [] };
    for (let index = 0; index < selectedAdmins.length; index++) {
      const user = selectedAdmins[index];
      reqBody.detail.push(user.original);
    }
    const myself = JSON.parse(
      sessionStorage.getItem("apiAuth")
    ).username.toLowerCase();
    postApi("/tasks/", reqBody).then(() => {
      toggle();

      for (let index = 0; index < reqBody.detail.length; index++) {
        const admin = reqBody.detail[index];
        if (admin.userPrincipalName.toLowerCase() === myself) {
          UserLogout();
        }
      }
      refresh();
      setLoading(false);
    });
  }
  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>
          {t("pages.settings.modal.remove.title")}
        </ModalCardTitle>
        <ModalCardBody>
          {t("pages.settings.modal.remove.advisory")}
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t("pages.device.modal.cancel")}
          </StandardButton>
          <StandardButton onClick={handleRemoveAdmin} loading={loading}>
            {t("pages.settings.modal.remove.remove")}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default RemoveAdminModal;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function RoleDropdown({ role, setRole }) {
  const { t } = useTranslation("global");
  const [expanded, setExpanded] = useState(false);
  const expand = () => setExpanded(!expanded);
  const handleRole = (e) => {
    setRole(e.target.id);
    expand();
  };
  return (
    <>
      <div className="mx-1 my-1  border rounded-lg  bg-white z-20">
        <div
          className="px-6 py-1 my-1 bg-white flex justify-between"
          onClick={expand}
        >
          <div className=" text-black ">{t(`roles.${role}`)}</div>
          <div className="rotate-180">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
              preserveAspectRatio="none"
            >
              <path
                d="M7 14.5L11.6464 9.85355C11.8417 9.65829 12.1583 9.65829 12.3536 9.85355L17 14.5"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      {expanded && (
        <div className="mx-1 my-1 absolute border w-[333px] shadow-lg rounded-lg bg-white z-20 cursor-pointer">
          <div
            id="smp.administrator"
            className="px-6 py-2 my-2  hover:bg-forange-100 hover:font-medium font-normal bg-white"
            onClick={(e) => handleRole(e)}
          >
            <div id="smp.administrator" className=" text-black ">
              {t("roles.smp.administrator")}
            </div>
            <div
              id="smp.administrator"
              className=" text-fgray-300 font-normal text-sm"
            >
              {t("roles.description.smp.administrator")}
            </div>
          </div>
          <div
            id="smp.device"
            className="px-6 py-2 my-2  hover:bg-forange-100 hover:font-medium font-normal bg-white"
            onClick={(e) => handleRole(e)}
          >
            <div id="smp.device" className=" text-black ">
              {t("roles.smp.device")}
            </div>
            <div
              id="smp.device"
              className=" text-fgray-300 font-normal text-sm"
            >
              {t("roles.description.smp.device")}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RoleDropdown;

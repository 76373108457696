import React, { useState, useRef } from "react";
import { postApi } from "../../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import CategoryCombo from "../layout/CategoryCombo";
import RoomCombo from "../layout/RoomCombo";
import StandardButton from "../layout/buttons/StandardButton";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";
import { useToggleComponent } from "../../tools/hooks";
function AppAssignModal({ toggle, app }) {
  const { t } = useTranslation("global");
  const [selCategory, setSelCategory] = useState(0);
  const [selRoom, setSelRoom] = useState(0);
  const [loading, setLoading] = useState(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);

  function handleAssignment(event) {
    event.preventDefault();
    let reqBody = {
      kind: "asg",
      target: "app",
      detail: [],
    };
    if (selCategory !== 0) {
      reqBody.detail.push({ appId: app.graphId, groupId: selCategory });
    }
    if (selRoom !== 0) {
      reqBody.detail.push({ appId: app.graphId, groupId: selRoom });
    }
    if (selCategory !== 0 || selRoom !== 0) {
      setLoading(true);
      postApi("/tasks/", reqBody).then((response) => {
        toggle();
        setLoading(false);
        setSelCategory(0);
        setSelRoom(0);
      });
    }
  }
  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>{t("pages.appstore.modal.title",{title:app.display_name})}</ModalCardTitle>
        <ModalCardBody>
          <div className="text-justify">{t("pages.appstore.modal.body",{title:app.display_name})}</div>
          <div className="text-left">
            {t("categories.modal.choose")}
            <CategoryCombo
              className="text-red-700"
              setGroupId={setSelCategory}
              includeAllDevices="true"
            />
          </div>
          <div className="text-left">
            {t("rooms.modal.choose")}
            <RoomCombo setSelected={setSelRoom} />
          </div>
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t("pages.device.modal.cancel")}
          </StandardButton>
          <StandardButton loading={loading} onClick={handleAssignment}>
            {t("pages.device.assign")}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default AppAssignModal;

import React, { useContext } from "react";
import NotificationCard, {
  NotificationCardBody,
  NotificationCardButtons,
  NotificationCardTitle,
} from "../layout/NotificationCard";
import { PortalContext } from "../../PortalContext";
import { useTranslation } from "react-i18next";
import ActionButton from "../layout/buttons/ActionButton";
function PaymentNotificationCard() {
  const { t } = useTranslation("global");
  const { numiatoStatus } = useContext(PortalContext);
  const displayCardStatus = ["1002", "1003", "1012", "1101", "1110"];
  return (
    <>
      {displayCardStatus.includes(numiatoStatus) && (
        <NotificationCard>
          <NotificationCardTitle>
            {t(`payment.notification_card.${numiatoStatus}.title`)}
          </NotificationCardTitle>
          <NotificationCardBody>
            {t(`payment.notification_card.${numiatoStatus}.description`)}
          </NotificationCardBody>
          <NotificationCardButtons>
            <ActionButton
              onClick={() =>
                window.open("mailto:support@numiato.de?subject=Payment Support")
              }
              secondary
            >
              {t("pages.policy.onboard.button.contact")}
            </ActionButton>
          </NotificationCardButtons>
        </NotificationCard>
      )}
    </>
  );
}

export default PaymentNotificationCard;

import React, { useState, useMemo, useEffect, useContext } from "react";
import {
  createColumnHelper,
  getSortedRowModel,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import IndeterminateCheckbox from "../layout/IndeterminateCheckbox";
import SortingIcon from "../layout/SortingIcon";
import { useNavigate } from "react-router-dom";
import DeviceStatusIndicator from "./DeviceStatusIndicator";
import DeviceTableFilterBar from "./DeviceTableFilterBar";
import { fuzzyFilter, muxFilter } from "./DeviceTableFilterFn";
import { ReactComponent as GhostImage } from "../../images/ghost.svg";
function DeviceTable({
  deviceData,
  setSelected,
  filter,
  reload,
  showColumns,
  setShowColumns,
  showFilter,
  dataType,
}) {
  const [globalFilter, setGlobalFilter] = useState(""); // Room/Category filter
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({
    categoryId: false,
    roomId: false,
    type: false,
    userPrincipalName: false,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [showFilterBar, setShowFilterBar] = useState(false);
  const columnHelper = createColumnHelper();
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const data = useMemo(
    () => [
      ...deviceData.map((d) => ({
        state: t(`metrics.status.${d.statusInfo.status}.title`),
        ...d,
      })),
    ],
    [filter, deviceData, reload]
  );
  const clearColumnFilters = () => setColumnFilters([]);
  const handleTableHead = () => {
    return t("pages.device.table.headerInfo", {
      total: data.length,
      count: Object.keys(rowSelection).length,
    });
  };

  const handleSelection = (e, row) => {
    setLastSelected(row);
    if (e.nativeEvent.shiftKey) {
      const sortedRows = table.getSortedRowModel().rows;
      const lastSelectedIndex = sortedRows.findIndex(
        (r) => r.id == lastSelected.id
      );
      const clickedIndex = sortedRows.findIndex((r) => r.id == row.id);

      if (lastSelectedIndex < clickedIndex) {
        for (let i = lastSelectedIndex; i <= clickedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      } else {
        for (let i = clickedIndex; i <= lastSelectedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      }
    } else {
      row.toggleSelected();
    }
  };

  const columns = [
    columnHelper.accessor("statusInfo", {
      header: () => <></>,
      cell: (info) => <DeviceStatusIndicator status={info.getValue()} />,
      classNameHeader: "-px-4",
      className: " ",
      size: 10,
      maxSize:10,
      enableResizing: false,
      enableSorting: true,
      sortDescFirst: true,
      enableColumnFilter: false,
    }),

    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      className: "w-16",
      classNameHeader: "w-16",
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect}
          indeterminate={row.getIsSomeSelected()}
          onChange={(e) => {
            handleSelection(e, row);
          }}
        />
      ),
      enableResizing: false,
      enableSorting: false,
      enableColumnFilter: false,
    }),

    columnHelper.accessor("deviceName", {
      header: () => <span>{handleTableHead()}</span>,
      cell: (info) => info.getValue(),
      className: "text-left cursor-pointer hover:text-ellipsis",
      classNameHeader: "cursor-pointer",
      size: 175,
      minSize: 150,
      label: t("pages.device.table.device_name"),
      filterFn: muxFilter,
    }),
    columnHelper.accessor("deviceCategoryDisplayName", {
      header: () => <span>{t("pages.device.table.category")}</span>,
      className: "cursor-pointer hover:text-ellipsis",
      classNameHeader: "cursor-pointer",
      cell: (info) => (
        <span>{t(`categories.name.${info.getValue()}`, info.getValue())}</span>
      ),
      size: 150,
      minSize: 150,
      enableColumnFilter: false,
      label: t("pages.device.table.category"),
      canHide: true,
    }),
    columnHelper.accessor("roomDisplayName", {
      header: () => <span>{t("pages.device.table.room")}</span>,
      className: "cursor-pointer",
      classNameHeader: "cursor-pointer",
      cell: (info) => (
        <span>{t(`rooms.name.${info.getValue()}`, info.getValue())}</span>
      ),
      size: 130,
      minSize: 130,
      enableColumnFilter: false,
      label: t("pages.device.table.room"),
      canHide: true,
    }),
    columnHelper.accessor("lastSyncDateTime", {
      header: () => <span>{t("pages.device.table.last_sync")}</span>,
      classNameHeader: "cursor-pointer",
      className: "cursor-pointer",
      cell: (info) => (
        <span>
          {info.getValue()
            ? moment(info.getValue()).fromNow()
            : t("words.never")}
        </span>
      ),
      size: 140,
      minSize: 140,
      enableColumnFilter: false,
      label: t("pages.device.table.last_sync"),
      canHide: true,
    }),
    columnHelper.accessor("model", {
      header: () => <span>{t("pages.device_detail.model")}</span>,
      classNameHeader: "cursor-pointer",
      className: "cursor-pointer",
      size: 160,
      minSize: 150,
      label: t("pages.device_detail.model"),
      columnFilterType: "option",
      canHide: true,
    }),
    columnHelper.accessor("operatingSystem", {
      header: () => <span>{t("pages.device_detail.os")}</span>,
      classNameHeader: "cursor-pointer",
      className: "cursor-pointer",
      size: 160,
      minSize: 150,
      filterOptions: ["abc"],
      label: t("pages.device_detail.os"),
      columnFilterType: "option",
      canHide: true,
    }),
    columnHelper.accessor("serialNumber", {
      header: () => <span>{t("pages.device_detail.serialnumber")}</span>,
      classNameHeader: "cursor-pointer",
      className: "cursor-pointer",
      filterFn: muxFilter,
      size: 160,
      minSize: 150,
      label: t("pages.device_detail.serialnumber"),
      canHide: true,
    }),
    columnHelper.accessor("manufacturer", {
      header: () => <span>{t("pages.device_detail.manufacturer")}</span>,
      classNameHeader: "cursor-pointer",
      className: "cursor-pointer",
      size: 160,
      minSize: 150,
      label: t("pages.device_detail.manufacturer"),
      columnFilterType: "option",
      canHide: true,
    }),
    columnHelper.accessor("userPrincipalName", {
      header: () => <span>{t("pages.device_detail.username")}</span>,
      classNameHeader: "cursor-pointer",
      className: "cursor-pointer",
      size: 160,
      minSize: 150,
      label: t("pages.device_detail.username"),
      canHide: true,
      filterFn: muxFilter,
    }),
    columnHelper.accessor("categoryId", {
      enableColumnFilter: false,
    }),
    columnHelper.accessor("roomId", {
      enableColumnFilter: false,
    }),
    columnHelper.accessor("type", {
      enableColumnFilter: false,
    }),
    columnHelper.accessor("state", {
      header: () => <></>,
      cell: (info) => <></>,
      classNameHeader: "-px-4",
      className: " ",
      size: 1,
      enableResizing: false,
      enableSorting: false,
      label: t("properties.status"),
      // enableColumnFilter: false,
      columnFilterType: "option",
      filterOptions: ["abc"],
    }),
  ];

  useEffect(() => {
    const tableColumn = {
      categoryId: false,
      roomId: false,
      type: false,
      state: false,
    };
    showColumns.map((col) => {
      tableColumn[col.id] = col.visible;
    });
    setColumnVisibility(tableColumn);
  }, [showColumns]);

  useEffect(() => {
    setShowFilterBar(showFilter);
    if (!showFilter) {
      clearColumnFilters();
    }
  }, [showFilter]);

  useEffect(() => {
    // Category/Room/Global filtering
    if (filter == "99999999-9999-9999-9999-999999999999") {
      setGlobalFilter("");
    } else {
      setGlobalFilter(filter);
    }
  }, [filter]);

  useEffect(() => {
    //Clearing column filters and column visibility
    clearColumnFilters();
    table.setRowSelection({});
    switch (dataType) {
      case "unenrolled":
        setColumnVisibility({
          categoryId: false,
          roomId: false,
          type: false,
          lastSyncDateTime: false,
          operatingSystem: false,
          roomDisplayName: false,
          serialNumber: false,
          manufacturer: false,
          userPrincipalName: false,
        });
        setTimeout(() => {
          setShowColumns(table.getAllColumns());
        }, 500);
        break;
      case "windows":
      case "macos":
      case "ipads":
      default:
        setColumnVisibility({
          categoryId: false,
          roomId: false,
          type: false,
          operatingSystem: false,
          serialNumber: false,
          manufacturer: false,
          model: false,
          userPrincipalName: false,
        });
        setTimeout(() => {
          setShowColumns(table.getAllColumns());
        }, 500);
        break;
    }
  }, [dataType]);

  useEffect(() => {
    setSelected(table.getSelectedRowModel().flatRows);
  }, [rowSelection]);

  const handleColumnFilterChange = (e) => {
    setColumnFilters(e);
    table.setRowSelection({});
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
      rowSelection,
      sorting,
      columnVisibility,
      columnFilters,
    },
    enableColumnResizing: true,
    enableColumnFilters: true,
    columnResizeMode: "onChange",
    enableRowSelection: true,
    enableGlobalFilter: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: handleColumnFilterChange,
    onSortingChange: setSorting,
    onglobalFilterFn: fuzzyFilter,
    GlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="h-full">
      <div className={``}>
        <DeviceTableFilterBar
          table={table}
          show={showFilterBar}
          type={dataType}
        />
      </div>
      <div className="overflow-auto h-[calc(100%-4rem)] ">
        <table
          className="text-center  mt-2"
          {...{ style: { width: table.getCenterTotalSize() } }}
        >
          <thead className="m-2 top-0 sticky bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={` truncate text-fgray-300 text-left  ${
                      header.column.columnDef.classNameHeader &&
                      flexRender(
                        header.column.columnDef.classNameHeader,
                        header.getContext()
                      )
                    } `}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex justify-end">
                      <div className={`flex px-4  w-full align-middle `}>
                        <p
                          className={`transition hover:text-black text-xs font-normal my-auto  ${
                            header.column.getIsSorted() && " text-black "
                          } `}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </p>
                        {header.column.getCanSort() &&
                          (header.column.getIsSorted() ? (
                            header.column.getIsSorted() == "asc" ? (
                              <SortingIcon status={"asc"} />
                            ) : (
                              <SortingIcon status={"desc"} />
                            )
                          ) : (
                            <SortingIcon status={"plain"} />
                          ))}
                      </div>
                      {/* Resizing */}
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`h-6 border-r-2 select-none  touch-none align-middle   cursor-col-resize hover:border-r-4 hover:border-fgray-300 ${
                            header.column.getIsResizing()
                              ? " border-fgray-400 "
                              : " "
                          }`}
                        ></div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {table.getRowModel().rows.length > 0 && (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={
                    `border-b-2  hover:font-medium font-normal focus:bg-forange-400 ` +
                    (row.getIsSelected()
                      ? `bg-forange-200 font-medium`
                      : `hover:bg-forange-100`)
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={
                        cell.column.getCanResize()
                          ? { width: cell.column.getSize() }
                          : {}
                      }
                      className={`py-5 text-base font-normal px-4 ${
                        cell.column.columnDef.className &&
                        flexRender(
                          cell.column.columnDef.className,
                          cell.getContext()
                        )
                      }`}
                      onClick={
                        cell.column.id == "select"
                          ? null
                          : () => navigate(`/device/${row.original.id}`)
                      }
                    >
                      <p
                        className="truncate  text-left"
                        style={
                          cell.column.getCanResize()
                            ? { width: cell.column.getSize() }
                            : {}
                        }
                      >
                        {cell.getValue() || cell.column.id === "select"
                          ? flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          : "-"}
                      </p>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {table.getRowModel().rows.length <= 0 && <NoDevicesMessage />}
        {showFilterBar && <div className="h-28"></div>}
      </div>
    </div>
  );
}

export default DeviceTable;
const NoDevicesMessage = () => {
  const { t } = useTranslation("global");
  return (
    <div className=" py-8">
      <div className="text-fgray-400 text-xl p-5 space-y-4 text-center place-items-center w-[835px] ">
        <GhostImage />
        <p>{t("pages.device.table.no_devices")}</p>
      </div>
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../layout/design/Dropdown";
import DropdownItem from "../layout/design/DropdownItem";

function ResultFilterSelect({ options, filter, setFilter }) {
  const { t } = useTranslation("global");
  return (
    <>
      <div className="m-auto">
        <Dropdown title={t(`filter.header`)}>
          {options.map((foption) => (
            <DropdownItem checkbox onClick={() => setFilter(foption)} checked={filter.includes(foption)} >
              {t(`state.${foption}`)}
            </DropdownItem>
          ))}
        </Dropdown>
      </div>
    </>
  );
}

export default ResultFilterSelect;

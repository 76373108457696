import React from "react";

function MultilineTranslation({ text }) {
  const items = text.split("\n");
  return (
    <span className="my-2">
      {items.map((item, index) => (
        <>
          <p key={`ml${index}`}>
            {item.split("**").map((bold, index) =>
              index % 2 ? (
                <span key={`bl${index}`}>
                  <strong key={`st${index}`}>{bold}</strong>
                </span>
              ) : (
                <span key={`bl${index}`}>{bold}</span>
              )
            )}
          </p>
          {index < items.length - 1 ? <span key={`spn${index}`}><br/></span> : <span key={`spn${index}`}></span> }
        </>
      ))}
    </span>
  );
}

export default MultilineTranslation;

import React, { useState, useEffect } from "react";
import FilterChip from "../layout/FilterChip";
import ActionButton from "../layout/buttons/ActionButton";
import { useTranslation } from "react-i18next";
import { ReactComponent as ClearFilterIcon } from "../icons/clearFilter.svg";
function DeviceTableFilterBar({ table, type, show }) {
  const [showDiv, setShowDiv] = useState(false);
  const [workedColumns, setWorkedColumns] = useState(table.getAllColumns());
  const [clean, setClean] = useState(false);
  const { t } = useTranslation("global");
  const clearFilter = () => {
    setClean(!clean);
  };
  const getItemOptions = (rows, column) => {
    return [...new Set(rows.map((itm) => itm.original[column]))];
  };
  useEffect(() => {
    setShowDiv(show);
  }, [show]);

  useEffect(() => {
    setWorkedColumns(table.getAllColumns());
  }, [table, clean]);

  

  return (
    <div
      className={`gap-x-2 gap-y-2 w-[72rem] transition-all delay-1500 flex-wrap flex ${
        showDiv ? "my-4 " : "h-0 my-0"
      }`}
    >
      {showDiv &&
        workedColumns.map(
          (column) =>
            column.getCanFilter() &&
           ( column.id == "state" ? true : column.getIsVisible() )&& (
              <FilterChip
                key={`${type}${column.id}${show}`}
                label={column.columnDef.label}
                columnName={column.id}
                filter={column.getFilterValue()}
                setFilter={column.setFilterValue}
                options={
                  column.columnDef.columnFilterType === "option"
                    ? getItemOptions(
                        column.getFacetedRowModel().rows,
                        column.id
                      )
                    : null
                }
                clear={clean}
              />
            )
        )}
      {showDiv && (
        <ActionButton small subtle className="border border-fgray-300" Icon={ClearFilterIcon} onClick={clearFilter}>
          {t("words.clear_filter")}
        </ActionButton>
      )}
      {/* {cols &&
        cols.map((column) => {
          return <FilterChip key={`${column.label}${type}`} {...column} />;
        })} */}
    </div>
  );
}

export default DeviceTableFilterBar;

import React from "react";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t } = useTranslation("global");

  return (
    <>
      {/*Title Bar*/}
      <div className="w-full  shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 overscroll-y-auto justify-between ">
          <div className="text-2xl font-medium">
            <h3>{t("pages.terms.title")}</h3>
          </div>
        </div>
      </div>
      <div className="p-6 mb-12 max-w-6xl mx-auto space-y-6 overflow-y-auto overflow-x-hidden">
        <h2 className="text-lg font-medium">Verantwortlich für den Inhalt</h2>
        <p>
          Numiato GmbH
          <br />
          Geschäftsführer: Anton Wabra
        </p>
        <p>
          Doktorbäuerinweg 1<br />
          D-82041 Deisenhofen
          <br />
          <span className="text-forange-400">www.numiato.de</span> <br />
          E-Mail: info@numiato.de
          <br />
        </p>
        <p className="text-fgray-400">
          Registergericht: Amtsgericht München
          <br />
          Registernummer: Handelsregister München HRB 243294
          <br />
          Steuernummer: 143 | 167 | 31458
          <br />
          USt-ID gemäß §27a UStG: DE318064197
          <br />
        </p>
        <h2 className="text-lg font-medium">
          Nutzungsbedingungen und allgemeine Hinweise
        </h2>
        <p>
          Die Nutzung dieses Webportals ist nur Bildungseinrichtungen gestattet.
          Versuche einer nicht autorisierten Nutzung sind verboten. Inhalte
          dieses Portals sind Eigentum der Firma Numiato GmbH und unterliegen
          sowohl dem Urheberrecht als auch geltenden
          Geheimhaltungsvereinbarungen.
        </p>
        <h1 className="text-2xl font-medium">
          Allgemeinen Geschäftsbedingungen und Widerrufsbelehrung
        </h1>
        <p>
          Um die Lesbarkeit der AGB zu vereinfachen, wird auf die zusätzliche
          Formulierung der weiblichen oder der diversen Form verzichtet. Die
          Verwendung der männlichen Form ist aber geschlechtsunabhängig zu
          verstehen.
        </p>
        <h2 className="text-lg font-medium">§ 1 Anwendungsbereich</h2>
        <p>
          (1) Diese Geschäftsbedingungen gelten für den Bezug von
          Dienstleistungen zwischen der Numiato GmbH und der
          Bildungseinrichtung, die diese Dienstleistungen in Anspruch nimmt.
        </p>
        <h2 className="text-lg font-medium">
          § 2 Urheberrechtlicher Schutz der AGB
        </h2>
        <p>
          Die AGB der Numiato GmbH unterliegen dem Schutz des Urheberrechts. Der
          Kunde darf sie jedoch abrufen, in wiedergabefähiger Form abspeichern
          und/oder ausdrucken.
        </p>
        <h2 className="text-lg font-medium">
          § 3 Informationen zur Identität von Numiato
        </h2>
        <p>
          (1) Vertragspartner der Kunden ist die Numiato GmbH, Doktorbäuerinweg
          1, 82041 Deisenhofen, Tel. +49 89 208046679, E-Mail: info@numiato.de,
          Handelsregister München HRB 243294
        </p>
        <p>(2) Geschäftsführer ist Anton Wabra.</p>
        <h2 className="text-lg font-medium">
          § 4 Informationen zu dem Angebot
        </h2>
        <p>
          (1) Numiato bietet Dienstleistungen an, die Bildungseinrichtungen bei
          der Nutzung von Microsoft Clouddiensten und Cloud-Angeboten
          unterstützen. Die wesentlichen Merkmale unserer Dienstleistungen sind:
          Bereitstellung und Pflege von Web-Portalen zur Verwaltung von
          Microsoft Intune Instanzen für Bildungseinrichtungen, technische
          Unterstützung bei der Einrichtung von Microsoft Intune, sowie
          technische Unterstützung bei der Nutzung von Microsoft Clouddiensten
          und Cloud-Angeboten.
        </p>{" "}
        <p>
          (2) Die Lieferung besteht ausschließlich in der Bereitsstellung und
          regelmäßiger Pflege des Online-Portals portal.numiato.de, das es
          Mitgliedern von Bildungseinrichtungen ermöglicht, ihre vorher
          erworbenen Microsoft Cloud Lizenzen zu nutzen und zu verwalten. Es
          werden keine körperlichen Datenträger oder Dokumentationen oder
          Herstellerhinweise in Papierform geliefert und sind auch nicht
          geschuldet.
        </p>{" "}
        <p>
          (3) Der erfolgreiche Zugriff auf das Online-Portal portal.numiato.de
          setzt voraus, dass die Bildungseinrichtung zum begünstigten Bezug von
          Microsoft Software für solche Einrichtungen berechtigt ist, über eine
          Azure AD Instanz ("tenant") verfügt und für diese Instanz kostenfreie
          oder kostenpflichtige Microsoft Lizenzen vorab erworben hat. Diese
          Lizenzen sind nicht Bestandteil dieses Angebots.
        </p>
        <h2 className="text-lg font-medium">
          § 5 Beschwerdeverfahren und Verhaltenskodizes
        </h2>
        <p>
          (1) Im Falle einer Beschwerde senden Sie bitte eine E-Mail an
          info@numiato.de.
        </p>{" "}
        <p>
          (2) Für eine außergerichtliche Schlichtung können Verbraucher aus
          EU-Mitgliedsstaaten folgendes EU-Portal nutzen:
          http://ec.europa.eu/consumers/odr/ Die Numiato hat sich darüber hinaus
          keinen außergerichtlichen Beschwerdeverfahren unterworfen.
        </p>{" "}
        <p>(3) Die Numiato hat sich keinerlei Verhaltenskodizes unterworfen.</p>
        <h2 className="text-lg font-medium">
          § 6 Gewährleistungsrechte, Garantien und Haftung
        </h2>
        <p></p>
        <h2 className="text-lg font-medium"></h2>
        <p>
          (1) Unsere Dienstleistungen bestehen in der Bereitsstellung des
          Portals portal.numiato.de. Dieses ermöglicht eine vereinfachte und
          effiziente Verwaltung, Konfiguration und Pflege einer Microsoft Intune
          Instanz, insbesondere der Geräte und ihrer Eigenschaften. Wir haften
          nicht für die Zuverlässigkeit und Verfügbarkeit der Microsoft
          Clouddienste und nicht für clientseitige oder verbindungstechnische
          Probleme bei der Nutzung der Clouddienste. Die technische Verwaltung
          sowie die korrekte Lizenzierung von privaten Clients (d. s.
          elektronische Geräte mit Internetzugang wie PCs, Macs, Tablets,
          Smartphones usw.) liegt außerhalb unseres Einfluss-, Wirkungs- und
          Zuständigkeitsbereiches. Der genaue Leistungsumfang der nutzbaren
          Komponenten der Microsoft Clouddienste liegt in der Verantwortung von
          Microsoft Ireland Operations Ltd. und kann sich auch während der
          Laufzeit unseres Dienstleistungsangebots ändern. Unterlagen dazu
          finden Sie am Internet unter
          https://docs.microsoft.com/de-de/office365/servicedescriptions/office-365-platform-service-description/microsoft-365-education
          .
        </p>
        <p>
          (2) Wir haften nicht für eine vorzeitige Beendigung des Nutzungsrechts
          der über portal.numiato.de zugänglichen Microsoft Software, die durch
          eine Kündigung oder Nicht-Verlängerung von Microsoft Lizenzen zustande
          kommt. Eine solche vorzeitige Beendigung liegt außerhalb unseres
          Einfluss- und Wirkungsbereiches und berechtigt den Kunden nicht zu
          einer Preisreduktion oder teilweisen Rückerstattung.
        </p>
        <p>
          (1) Bei allen Dienstleistungen der Numiato bestehen die gesetzlichen
          Gewährleistungsrechte.
        </p>
        <p>
          (2) Hinsichtlich einer Begrenzung der Haftung gilt Folgendes:
          <br />
          a) Bei Vorsatz oder grober Fahrlässigkeit haftet Numiato für alle
          darauf zurückzuführenden Schäden unbeschränkt.
          <br />
          b) Bei einfacher Fahrlässigkeit haftet Numiato im Falle der Verletzung
          des Lebens, des Körpers oder der Gesundheit unbeschränkt. Gerät
          Numiato durch einfache Fahrlässigkeit mit seiner Leistung in Verzug,
          wird seine Leistung unmöglich oder verletzt Numiato eine wesentliche
          Pflicht, ist die Haftung für darauf zurückzuführende Sach- und
          Vermögensschäden auf den vertragstypischen vorhersehbaren Schaden
          begrenzt. Eine wesentliche Pflicht ist eine solche, deren Erfüllung
          die ordnungsgemäße Durchführung des Vertrages erst ermöglicht, deren
          Verletzung den Vertragszweck gefährdet und auf deren Einhaltung der
          Vertragspartner regelmäßig vertrauen darf. <br />
          c) Die Haftung für alle übrigen Schäden ist ausgeschlossen. <br />
          d) Der Numiato bleibt der Einwand des Mitverschuldens offen. Der Kunde
          ist jedoch für ein regelmäßiges Sichern seiner Daten verantwortlich.
          Bei einem von der Numiato verschuldeten Datenverlust haftet die
          Numiato daher ausschließlich für die Kosten der Vervielfältigung der
          Daten für die von dem Kunden zu erstellenden Sicherungskopien und die
          Wiederherstellung der Daten, die auch bei einer ordnungsgemäß
          erfolgten Sicherung der Daten verloren gegangen wären, da dies der bei
          ordnungsgemäßer Datensicherung regelmäßig vorhersehbare und typische
          Schaden ist.
        </p>
        <h2 className="text-lg font-medium">
          § 7 Kosten der Benutzung des Fernkommunikationsmittels
        </h2>
        <p>
          Für den Einsatz des für den Vertragsschluss genutzten
          Fernkommunikationsmittels fallen keine Kosten an, die über die bloße
          Nutzung des Fernkommunikationsmittels hinausgehen.
        </p>
        <h2 className="text-lg font-medium">
          § 8 Angebot, Vertragsschluss und Bestellschritte
        </h2>
        <p>
          (1) Das Angebot zur Nutzung von portal.numiato.de ist ein Abonnement,
          das standardmäßig für ein Jahr abgeschlossen wird und anschließend
          jeweils um ein weiteres Jahr verlängert werden kann. Der Kunde meldet
          sich auf dem Portal https://portal.numiato.de an. Die Erstanmeldung
          ist nur als globaler Administrator der Azure AD Instanz der
          Bildungseinrichtung möglich. Anschließend kann portal.numiato.de
          erstmalig für einen Zeitraum von 3 Monaten mit allen Funktionen
          kostenlos genutzt werden (Testperiode). Wählt der Kunde innerhalb
          dieser Frist die Möglichkeit des Abonnement-Kaufs, verlängert sich das
          Abonnement auf ein volles Jahr ab der ersten Anmeldung, andernfalls
          wird der Zugang nach der Testperiode gesperrt. Lässt der Kunde das
          Abonnement auslaufen, kann es jederzeit neu für ein Jahr abgeschlossen
          werden, jedoch entfällt die Testperiode.
        </p>
        <p>
          (2) Mit dem Erwerb des Abonnements bietet Numiato dem Kunden ohne
          Aufpreis technische Unterstützung per E-Mail bei der Nutzung von
          portal.numiato.de. Die E-Mailadresse lautet support@numiato.de.
          Technische Anfragen, die sich auf Dienste und Eigenschaften von
          Microsoft Intune beziehen, sind nicht kostenfrei und werden dem Kunden
          nach unserem aktuellen Stundensatz in Rechnung gestellt, sofern der
          Aufwand mehr als 15 Minuten beträgt.
        </p>
        <p>
          (3) Um diese Dienstleistungen im Zusammenhang mit Microsoft Intune
          nutzen zu können, muss der Kunde folgende Bestellschritte durchführen.
          Erst wählt der Kunde die gewünschten Produkte. Nach dieser Wahl stehen
          3 Zahlungsverfahren zur Verfügung.
        </p>
        <p>
          (a) Einlösen eines Codes, den die Bildungseinrichtung oder ein
          Sachaufwandsträger vorab bei DrVis Software gekauft hat. Solche Codes
          können per E-Mail von sales@drvis.de bestellt werden.
        </p>
        <p>
          (b) Zahlung per Rechnung. Der Rechnungsbetrag wird angezeigt und die
          Rechnung kann heruntergeladen werden. Nach Eingang der Zahlung wird
          der Zugang zu portal.numiato.de entsprechend dem Abonnementzeitraum
          automatisch aktiviert bzw. verlängert.
        </p>
        <p>
          (c) Zahlung per Einziehungsauftrag. Der Rechnungsbetrag wird angezeigt
          und die Rechnung kann heruntergeladen werden. Der Zugang zu
          portal.numiato.de wird sofort aktiviert bzw. verlängert. Rechtzeitig
          vor Ablauf des Abonnements wird der Kunde veständigt und kann der
          Einziehungsauftrag verlängern oder beenden.
        </p>
        <h2 className="text-lg font-medium">
          § 9 Speicherung der Bestellung, Sprachen und Abrufbarkeit der AGB
        </h2>
        <p>
          (1) Die anonymen Daten bei der Bestellung werden von DrVis Software
          elektronisch gespeichert. Nach der Bestellung sind die Daten der
          Bestellung für den Kunden nach Anmeldung an das Portal
          https://bildung365.de einsehbar.
        </p>
        <p>
          (2) Die AGB können vom Kunden auch jederzeit unter dem Link
          https://www.bildung365.de/agb eingesehen und ausgedruckt werden.
        </p>
        <p>
          (3) Für den Vertragsschluss stehen dem Kunden die Sprachen zur
          Verfügung, in denen die AGB abgerufen werden können.
        </p>
        <h2 className="text-lg font-medium">
          § 10 Preise, Überzahlungen, Unterzahlungen
        </h2>
        <p>
          (1) Der Preis für die Dienstleistung von DrVis Software ist auf der
          Bestellseite inklusive der Mehrwertsteuer ausgewiesen und in Euro
          angegeben.
        </p>
        <p>
          (2) Bei Überzahlung kann DrVis Software eine automatische
          Rücküberweisung des Differenzbetrags nicht garantieren. Jedoch kann
          der Kunde beim Support support@drvis.de per E-Mail die Überzahlung
          unter Angabe des von ihm benutzen Verwendungszwecks melden und DrVis
          Software wird nach Verifikation der Angaben eine Rücküberweisung
          veranlassen.
        </p>
        <p>
          (3) Wenn der Kunde zu wenig bezahlt hat, erfolgt keine Verlängerung
          des Abonnements. Jedoch kann der Kunde beim Support support@drvis.de
          per E-Mail die Unterzahlung melden und DrVis Software wird nach
          Verifikation der Angaben entweder eine Rücküberweisung veranlassen
          oder dem Kunden eine Nachfrist zur Bezahlung des Differenzbetrags
          einräumen.
        </p>
        <h2 className="text-lg font-medium">
          § 11 Nutzungsrechte bei dem Erwerb von Software
        </h2>
        <p>
          (1) Die über die Web-Portale von Numiato verfügbare Software
          unterliegt den Nutzungsbedingungen des Softwareherstellers.
        </p>
        <p>
          (2) Der Kunde ist berechtigt, die Vertragssoftware entsprechend der
          Nutzungsbedingungen des Herstellers einzusetzen.
        </p>
        <p>
          (3) Der Kunde darf die Software nur in den Schranken der §§ 69d und
          69e UrhG ohne Zustimmung des Rechteinhabers in urheberrechtlich
          relevanter Hinsicht nutzen. Hinsichtlich der Weiterveräußerung und
          Weitervermietung sind die Lizenzbestimmungen des Herstellers sowie die
          gesetzlichen Regelungen einzuhalten.
        </p>
        <h2 className="text-lg font-medium">§ 12 Datenschutz</h2>
        <p>
          (1) Die Numiato übernimmt die Datenschutzverantwortung für alle in den
          Web-Portalen von Numiato verarbeiteten personenbezogenen Daten.
          Insbesondere übernimmt Numiato die Datenschutzverantwortung für das
          Verwalten der Endpoint Manager Geräte.
        </p>
        <p>
          (2) Der Kunde muss den Datenschutzerläuterungen für die von ihm
          beanspruchten Dienste in den Webportalen zustimmen. Dazu gehören
          https://www.officefuerbildung.de/Home/OfbPrivacyPolicyDE?reseller=5
          und
          https://www.officefuerbildung.de/Home/OptionsPrivacyPolicyDE?reseller=5
        </p>
        <p>(3) Numiato befolgt die einschlägigen Datenschutzgesetze.</p>
        <h2 className="text-lg font-medium">
          § 13 Salvatorische Klausel, Rechtswahl
        </h2>
        <p>
          (1) Die Parteien vereinbaren die Geltung deutschen Rechts unter
          Ausschluß des UN-Kaufrechts.
        </p>
        <p>
          (2) Sollten einzelne Bestimmungen dieser Geschäftsbedingungen oder des
          zwischen den Parteien abgeschlossenen Vertrags ganz oder teilweise
          unwirksam sein, so bleibt die Wirksamkeit des Vertrages im Übrigen
          hiervon unberührt.
        </p>
        <h2 className="text-lg font-medium">
          § 14 Bestehen des Widerrufsrechts
        </h2>
        <p>
          (1) Verbrauchern gem. § 13 BGB steht ein Widerrufsrecht in den
          nachfolgend beschriebenen Fällen zu.
        </p>
        <h2 className="text-lg font-medium">
          § 15 Widerrufsbelehrung für Dienstleistungeverträge und vorzeitiges
          Erlöschen des Widerrufsrechtes
        </h2>
        <p>(1) WIDERRUFSBELEHRUNG FÜR DIENSTLEISTUNGSVERTRÄGE</p>
        <p>
          Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
          diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage
          ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben,
          müssen Sie uns, der
        </p>
        <p className="indent-8">Numiato GmbH</p>
        <p className="indent-8"> Doktorbäuerinweg 1</p>
        <p className="indent-8"> 82041 Deisenhofen</p>
        <p className="indent-8"> Telefon: +49 89 208046679</p>
        <p className="indent-8"> E-Mail-Adresse: info@numiato.de</p>
        <p>
          mittels einer eindeutigen Erklärung (z.B. Brief oder E-Mail) über
          Ihren Entschluss diesen Vertrag zu widerrufen, informieren. Ihre
          Mitteilung muss zwingend ein Bankkonto und einen Verwendungszweck für
          die Rücküberweisung enthalten. Zur Wahrung der Widerrufsfrist reicht
          es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts
          vor Ablauf der Widerrufsfrist absenden.
        </p>
        <p> FOLGEN DES WIDERRUFS</p>
        <p>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen,
          die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen
          vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung mit
          Ihren Angaben des Verwendungszwecks und Konto über Ihren Widerruf
          dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung werden
          Ihnen keine Entgelte berechnet.
        </p>
        <h2 className="text-lg font-medium">
          § 16 Geltung der AGB, Erfüllungsort, Gerichtsstand
        </h2>
        <p>
          (1) Geschäftsbedingungen des Kunden gelten nur bei ausdrücklicher und
          schriftlicher Zustimmung der Numiato.
        </p>
        <p>
          (2) Als Erfüllungsort und Gerichtsstand wird München vereinbart. Die
          Numiato kann jedoch auch den allgemeinen Gerichtsstand des Kunden
          wählen.
        </p>
        <h2 className="text-lg font-medium">Marken</h2>
        <p>
          Alle genannten Marken- oder Warenzeichen sind eingetragene Marken oder
          eingetragene Warenzeichen ihrer jeweiligen Eigentümer. Marken- oder
          Warenzeichen wurden in der Regel nicht als solche kenntlich gemacht.
          Das Fehlen einer solchen Kennzeichnung bedeutet nicht, dass es sich um
          freie Namen im Sinne des Waren- und Markenzeichenrechts handelt.
          Insbesondere sind Microsoft und deren Produkte Windows, Office, Teams,
          Sharepoint, OneDrive, Exchange, Active Directory, Intune, Microsoft
          Intune eingetragene Markenzeichen der Microsoft Corporation.
        </p>
      </div>
    </>
  );
}

export default TermsAndConditions;

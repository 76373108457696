import  { useEffect, useRef,useContext } from "react";
import { PortalContext } from "../PortalContext";
import { Providers } from "@microsoft/mgt-element";
import { useNavigate } from "react-router-dom";

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function UserLogout(){
  sessionStorage.clear()
  Providers.globalProvider.logout()
}

export function ValidateRole(){
  if(sessionStorage.getItem("role") !== "smp.administrator"){
    window.location.replace("/")
  }
}

export function isUUID4(uuid) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

export function getUnique(list){
  return list.filter((value, index, array) => array.indexOf(value) === index);
}

export function useGetLicenseStatus(){ 
  // Returns True if access is granted, false is access is restricted
  const {numiatoStatus} = useContext(PortalContext)
  const allowedStatusList=["1000","1001","1002","1003","1010","1012"]
  return allowedStatusList.includes(numiatoStatus)
}

export function useCheckLicenseStatus(numiatoStatus){
  const navigate = useNavigate()
  if(!useGetLicenseStatus(numiatoStatus)){
    navigate("/")
  }
}
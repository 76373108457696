import React, { useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { getApi } from "../../tools/axiosInstances";

function TenantChannel({ task, setProgress }) {
  const [tenantId, setTenantId] = useState(
    sessionStorage.getItem("tenantId") || queryTenantId()
  );
  let socket = null;

  const queryTenantId = () => {
    let tenant = "";
    getApi("/authenticate/tenant/").then((response) => {
      sessionStorage.setItem("tenantId", response.data.tenantId);
      return tenant;
    });
  };

  useEffect(() => {
    socket = new W3CWebSocket(
      `${process.env.REACT_APP_WS_ENDPOINT}/ws/${tenantId}/`
    );
    socket.onopen = () => {};
    socket.onmessage = (msg) => {
      const message = JSON.parse(msg.data);
      switch (message.action) {
        case "update":
          let element = document.getElementById(message.id);
          if (element) {
            element.value = message.value;
            element.dispatchEvent(new Event("input", { bubbles: true }));
          }
          break;
        default:
          break;
      }
    };

    return () => {
      socket = null;
    };
  }, []);

  return <></>;
}

export default TenantChannel;

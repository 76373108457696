import React, { useState, useEffect } from "react";
import { getApi } from "../../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import LoadingSidebar from "../layout/loading/LoadingSidebar";
import { useCategory } from "../../hooks/useCategory";
import { useRoom } from "../../hooks/useRoom";
import { SidebarGroup } from "../layout/Sidebar";

function SidebarDevice({ unassigned, filter, setFilter, globalId, refresh }) {
  const { t } = useTranslation("global");
  const categoryList = useCategory();
  const roomList = useRoom();
  function handleToggleFilter(name) {
    setFilter(name);
  }
  const SidebarItem = ({ filterCompare, children }) => {
    return (
      <li
        className={
          `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer hover:font-medium font-normal active:rounded ` +
          (filter === filterCompare
            ? "bg-forange-400 text-white font-medium"
            : " hover:text-forange-400 ")
        }
        aria-current="page"
        key={filterCompare}
        onClick={(e) => {
          e.preventDefault();
          handleToggleFilter(filterCompare);
        }}
      >
        {children}
      </li>
    );
  };

  return (
    <>
      <nav className=" mr-5 w-64 mb-12 ">
        <ul className="nav flex flex-col w-full">
          {/* allDevices */}
          <SidebarItem filterCompare={globalId}>
            {t("sidebar.all_devices")}
          </SidebarItem>
          {/* Categories */}
          <SidebarGroup
            title={t("sidebar.category.title")}
            count={categoryList?.length || 0}
          >
            {categoryList ? (
              categoryList.map(({ id, displayName, shared }) => (
                <SidebarItem filterCompare={id}>
                  {shared
                    ? t(
                        `categories.name.${displayName}`,
                        `${displayName.substring(7)} ${t(
                          "categories.name.shared"
                        )}`
                      )
                    : t(`categories.name.${displayName}`, displayName)}
                </SidebarItem>
              ))
            ) : (
              <LoadingSidebar />
            )}
            {unassigned && categoryList && (
              <SidebarItem filterCompare="00000000-0000-0000-0000-000000000099">
                {t("sidebar.no_category")}
              </SidebarItem>
            )}
          </SidebarGroup>

          {/* Rooms */}
          <SidebarGroup
            title={t("sidebar.room.title")}
            count={roomList?.length || 0}
          >
            {roomList ? (
              roomList.map(({ id, displayName }) => (
                <SidebarItem filterCompare={id}>{t(displayName)}</SidebarItem>
              ))
            ) : (
              <LoadingSidebar />
            )}
            {unassigned && roomList && (
              <SidebarItem filterCompare="00000000-0000-0000-0000-000000000088">
                {t("sidebar.no_room")}
              </SidebarItem>
            )}
          </SidebarGroup>
        </ul>
      </nav>
    </>
  );
}

export default SidebarDevice;

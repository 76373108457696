import React, { useState, useEffect } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { getApi } from "../../../tools/axiosInstances";
import "./ImportDeviceTable.scss";
import { StatusCellTemplate } from "./StatusCellTemplate";
import LoadingTable from "../../layout/loading/LoadingTable";
import { useTranslation } from "react-i18next";
import { useCategory } from "../../../hooks/useCategory";
require("@silevis/reactgrid/styles.css");
function ImportDeviceTable({ loadedData, setValidData, setValid }) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const categoryList = useCategory()
  const [options, setOptions] = useState(() => {
    const OEMOptions = JSON.parse(sessionStorage.getItem("OEMOptions"));
    if (OEMOptions) return OEMOptions;
    getApi("/device/import/models/").then((list) => {
      sessionStorage.setItem("OEMOptions", JSON.stringify(list.data));
      setOptions(list.data);
    });
  });
  useEffect(() => {
    categoryList && options && setLoading(true);
  }, [categoryList, options]);

  const getModels = (manufacturer) => {
    manufacturer = options && options.find((o) => o.id === manufacturer);
    if (manufacturer) {
      return manufacturer.models.map((model) => ({
        label: model.displayName,
        value: model.id,
      }));
    } else return [];
  };
  const getCategories = () => {
    return [
      { label: "", value: null },
      ...categoryList.map((category) => ({
        label: t(
          `categories.name.${category.displayName}`,
          category.displayName
        ),
        value: category.displayName,
      })),
    ];
  };
  const generateRows = (loadedData) => {
    let rows = [];
    loadedData &&
      loadedData.map((row, index) => {
        let newRow = {
          index: (index + 1).toString(),
          valid: "",
          manufacturer: { isOpen: false, selectedValue: "" },
          model: { isOpen: false, selectedValue: "" },
          category: { isOpen: false, selectedValue: row?.category || "" },
          msid: row?.msid || "",
          hhs: row?.hhs || "",
          serialnumber: row?.serialnumber || "",
        };
        validateRowData(newRow);
        rows.push(newRow);
      });
    for (let i = rows.length + 1; i <= 100; i++) {
      rows.push({
        index: i.toString(),
        valid: "",
        manufacturer: { isOpen: false, selectedValue: "" },
        model: { isOpen: false, selectedValue: "" },
        category: { isOpen: false, selectedValue: "" },
        msid: "",
        hhs: "",
        serialnumber: "",
      });
    }
    validateRowData(rows);
    return rows;
  };
  const validateRowData = (row) => {
    if (
      row.hhs == "" &&
      row.msid == "" &&
      row.manufacturer.selectedValue == "" &&
      row.model.selectedValue == "" &&
      row.serialnumber == ""
    ) {
      row.valid = "";
    } else if (row.hhs != "" && row.serialnumber != "") {
      row.valid = "hhs";
    } else if (row.msid != "" && /^\d{13}$/.test(row.msid)) {
      row.valid = "mpi";
    } else if (
      row.serialnumber != "" &&
      row.manufacturer != "" &&
      row.model != ""
    ) {
      // Validate that model is instance of Manufacturer
      if (
        row.manufacturer.selectedValue &&
        getModels(row.manufacturer.selectedValue).find(
          (o) => o.value == row.model.selectedValue
        )
      ) {
        row.valid = "smm";
      } else row.valid = "unk";
    } else row.valid = "unk";
  };
  const [tableData, setTableData] = useState(generateRows(loadedData));
  const getManufacturers = () => {
    return [
      { label: "", value: "0000" },
      ...options.map((manufacturer) => ({
        label: manufacturer.displayName,
        value: manufacturer.id,
      })),
    ];
  };
  useEffect(() => {
    let atLeastOne = false;
    let available = true;
    let validData = [];
    tableData.map((row) => {
      if (row.valid == "unk") {
        available = false;
      } else if (row.valid != "") {
        atLeastOne = true;
        validData.push(row);
      }
    });
    setValid(atLeastOne && available);
    setValidData(validData);
  }, [tableData, options, categoryList]);

  const columns = [
    { columnId: "index", width: 20 },
    { columnId: "valid", width: 20 },
    { columnId: "hhs", width: 200 },
    { columnId: "msid", width: 200 },
    { columnId: "serialnumber", width: 150 },
    { columnId: "manufacturer", width: 150 },
    { columnId: "model", width: 150 },
    { columnId: "category", width: 200 },
  ];
  const headers = {
    rowId: "header",
    cells: [
      { type: "header", text: "" },
      { type: "header", text: "" },
      { type: "header", text: "Hardware Hash" },
      { type: "header", text: "Microsoft Product Id" },
      { type: "header", text: t("pages.device_detail.serialnumber") },
      { type: "header", text: t("pages.device_detail.manufacturer") },
      { type: "header", text: t("pages.device_detail.model") },
      { type: "header", text: t("pages.group.category") },
    ],
  };

  useEffect(() => {
    setTableData(generateRows(loadedData));
  }, [loadedData]);
  const getRows = (rowData) => [
    headers,
    ...rowData.map((r, index) => deviceRowParse(r, index)),
  ];
  const deviceRowParse = (rowData, index) => {
    const models = getModels(rowData.manufacturer?.selectedValue);
    return {
      rowId: index,
      height: 40,
      cells: [
        {
          type: "text",
          text: rowData?.index || "",
          style: {
            background: "#F6F5F4",
            textAlign: "right",
            padding: "8px",
            height: "40px",
          },
        },
        {
          type: "status",
          text: rowData?.valid || "",
          style: { padding: "8px", height: "40px" },
        },
        {
          type: "text",
          text: rowData?.hhs || "",
          style: { padding: "8px", height: "40px" },
        },
        {
          type: "text",
          text: rowData?.msid || "",
          style: { padding: "8px", height: 40 },
        },
        {
          type: "text",
          text: rowData?.serialnumber || "",
          style: { padding: "8px", height: "40px" },
        },

        {
          type: "dropdown",
          values: getManufacturers(),
          isOpen: rowData.manufacturer?.isOpen,
          selectedValue: rowData.manufacturer?.selectedValue,
        },
        {
          type: "dropdown",
          values: models,
          isOpen: rowData.model?.isOpen,
          selectedValue: rowData.model?.selectedValue,
        },
        {
          type: "dropdown",
          values: getCategories(),
          isOpen: rowData.category?.isOpen,
          selectedValue: rowData.category?.selectedValue,
        },
      ],
    };
  };

  const applyChangesToData = (changes, previous) => {
    changes.map((change) => {
      const index = change.rowId;
      const fieldName = change.columnId;
      switch (change.type) {
        case "checkbox":
          previous[index][fieldName] = change.newCell.checked;
          break;
        case "dropdown":
          if (change.previousCell.selectedValue != change.newCell.text) {
            previous[index][fieldName].selectedValue =
              change.newCell.selectedValue;
            previous[index][fieldName].isOpen = false;
            if (fieldName === "manufacturer") {
              previous[index].model.selectedValue = "";
            }
          }
          previous[index][fieldName].isOpen = change.newCell.isOpen;
          break;
        default:
          previous[index][fieldName] = change.newCell.text;
          break;
      }
      validateRowData(previous[index]);
    });
    return [...previous];
  };
  const handleChanges = (changes) => {
    setTableData((prev) => applyChangesToData(changes, prev));
  };
  return (
    <>
      {loading ? (
        <>
          <div className="reactgrid-nmt">
            <ReactGrid
              columns={columns}
              rows={getRows(tableData)}
              onCellsChanged={handleChanges}
              enableRangeSelection
              enableFillHandle
              customCellTemplates={{ status: new StatusCellTemplate() }}
            />
          </div>
        </>
      ) : (
        <>
          <LoadingTable />
        </>
      )}
    </>
  );
}

export default ImportDeviceTable;

import React, { useState, useEffect } from "react";
import { getApi } from "../../tools/axiosInstances";
import PlaceholderIcon from "../icons/placeholder.png";
function ApplicationLogo({ logoUrl }) {
  // const [logo, setLogo] = useState(() => {
  //   const image = sessionStorage.getItem(appId);
  //   return image || false;
  // });
  // useEffect(() => {
  //   !logo &&
  //     getApi(`/application/${appId}/logo/`, {}).then((response) => {
  //       setLogo(response.data);
  //       if (!response.data.includes("placeholder")) {
  //         sessionStorage.setItem(appId, response.data);
  //       }
  //     });
  // }, []);
  return logoUrl ? (
    <img className="h-10 mx-auto " alt={logoUrl} src={logoUrl} />
  ) : (
    <img
      className="h-10 mx-auto border rounded bg-fgray-100"
      alt={"logo"}
      src={PlaceholderIcon}
    />
  );
  // <img className="h-10 mx-auto " alt={appId} src={`data:image/jpeg;base64,${logo['logo']}`} />
}

export default ApplicationLogo;

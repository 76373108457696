import React from "react";
import Checkbox from "../layout/Checkbox";
import Textbox from "./parameters/Textbox";
function Parameters({ parameter }) {
  switch (parameter.type) {
    case "boolean":
      return (
        <>
        <div>
          <h3 className="text-xs text-fgray-300 ">{parameter.title}</h3>
          <div className="border rounded-xl align-middle p-2">
            <div className=" flex my-2">
              {/* <Checkbox className="align-top mt-2" id={parameter.id} /> */}
              <input
                id={parameter.id}
                type="checkbox"
                className="checked:bg-forange-400 indeterminate:bg-forange-400 rounded focus:outline-none my-1"
                checked={parameter.selected}
                onClick={(e) => e.stopPropagation()}
              />

              <p className="px-2 ">{parameter.display}</p>
            </div>
          </div></div>
        </>
      );
      break;
    case "text":
      return (
        <><div>
          <h3 className="text-xs text-fgray-300">{parameter.title}</h3>
          <div className="border rounded-xl align-middle p-2">
            <Textbox parameter={parameter} />
          </div></div>
        </>
      );
      break;
    default:
      console.log("default");
      return <></>;
  }
}

export default Parameters;

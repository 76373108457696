import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Tooltip from "../layout/tooltip/Tooltip";

function ModuleButton({
  link,
  Logo,
  title,
  target,
  disabled,
  tooltip,
  ...attributes
}) {
  const { t } = useTranslation("global");
  return (
    <Link
      to={disabled ? "#" : link}
      target={target}
      className="hover:border-forange-400"
    >
      <Tooltip title={tooltip} {...attributes}>
        <div className={`rounded-2xl align-middle text-center border border-gray-400 items-center h-[132px] w-[255px] font-normal transition ${ disabled ? "cursor-not-allowed text-fgray-300 " : "hover:font-medium  hover:bg-forange-100 hover:text-forange-400 hover:border-forange-400"}`}>
          <Logo className="h-14 w-14 mx-auto my-4 hover:stroke-forange-400" />
          <span className=" align-text-center ">{t(title)}</span>
        </div>
      </Tooltip>
    </Link>
  );
}

export default ModuleButton;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ProfileStatus from "./ProfileStatus";
import Parameter from "./Parameter";
function ProfileChild({
  profile,
  scope,
  mode,
  reload,
  parentStatus,
  checkRevertable,
}) {
  const { t } = useTranslation("global");
  const [profileStatus, setProfileStatus] = useState("pending");
  const [parameterStatus, setParametertatus] = useState(false);

  useEffect(() => {
    if (parameterStatus) {
      parameterStatus.map((p) => {
        sessionStorage.setItem(p.id, p.selected || p.default || "");
      });
    }
  }, [parameterStatus]);
  return (
    <>
      <li
        className={
          `px-4 py-3 mx-4 flex justify-between ` +
          (parentStatus ? ` hover:bg-forange-100 ` : ` text-fgray-300 `) 
        }
        key={`li.${profile.id}`}
      >
        <span className="w-3/4 items-baseline">
          <span className="pl-6">{t(`policy.${profile.id}.displayName`)}</span>
          <span className="flex-1 my-2">
            {parameterStatus &&
              parameterStatus.map((definition, index) => (
                <Parameter
                  key={`prch${index}`}
                  definition={definition}
                  mode={mode}
                  profileId={profile.id}
                />
              ))}
          </span>
        </span>

        <span>
          {parentStatus ? (
            parentStatus === "pending" ? (
              <ProfileStatus
                profile={profile}
                scope={scope}
                mode={mode}
                reload={reload}
                parentStatus={false}
                setParameters={setParametertatus}
                status={profileStatus}
                checkRevertable={checkRevertable}
                setStatus={setProfileStatus}
              />
            ) : (
              <ProfileStatus
                profile={profile}
                scope={scope}
                mode={mode}
                reload={reload}
                parentStatus={true}
                setParameters={setParametertatus}
                status={profileStatus}
                setStatus={setProfileStatus}
                checkRevertable={checkRevertable}
              />
            )
          ) : (
            <ProfileStatus
              profile={profile}
              scope={scope}
              mode={mode}
              reload={reload}
              parentStatus={false}
              setParameters={setParametertatus}
              status={profileStatus}
              setStatus={setProfileStatus}
              checkRevertable={checkRevertable}
            />
          )}
        </span>
      </li>
    </>
  );
}

export default ProfileChild;

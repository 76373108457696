import React, { useState, useRef, useContext,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as appstoreLogo} from "../../icons/appstoreLogo.svg";
import { ReactComponent as appManagementLogo} from "../../icons/appManagementLogo.svg";
import { ReactComponent as deviceManagementLogo} from "../../icons/deviceManagementLogo.svg";
import { ReactComponent as groupManagementLogo} from "../../icons/groupManagementLogo.svg";
import { ReactComponent as policyManagementLogo} from "../../icons/policyManagementLogo.svg";
import { ReactComponent as progressLogo} from "../../icons/progressLogo.svg";
import { ReactComponent as settingsLogo} from "../../icons/settingsLogo.svg";
import { ReactComponent as documentationLogo} from "../../icons/documentationLogo.svg";
import { ReactComponent as printerManagementLogo} from "../../icons/printerLogo.svg";
import HamburgerMenuItem from "./HamburgerMenuItem";
import { ReactComponent as HamburgerMenuIcon} from "../../icons/hamburgerMenu.svg";
import { useToggleComponent } from "../../../tools/hooks";
import { PortalContext } from "../../../PortalContext";
import { useGetLicenseStatus } from "../../../tools/utilties";
function HamburgerMenu() {
  const [showMenu, setShowMenu] = useState(false);
  const { deviceMode } = useContext(PortalContext);
  const { t } = useTranslation("global");
  const [adminRole, setAdminRole] = useState(false)
  const licenseStatus = useGetLicenseStatus()
  useEffect(() => {
    setAdminRole(sessionStorage.getItem("role") == "smp.administrator")

  }, [showMenu])
  
  const wrapperRef = useRef(null);
  const expand = () => setShowMenu(!showMenu);
  useToggleComponent(wrapperRef, setShowMenu);
  return (
    <>
      <div className="relative" ref={wrapperRef}>
        <button onClick={expand}>
          <HamburgerMenuIcon className="h-6 w-6"  />
        </button>
        {showMenu && (
          <div
            className="absolute px-2 w-[250px] bg-white rounded-lg z-20 shadow-lg border space-y-2"
            onClick={expand}
          >
            {adminRole && deviceMode == "windows" && (
              <HamburgerMenuItem
                destination="/appstore"
                title={t("pages.appstore.title")}
                Logo={appstoreLogo}
                disabled={!licenseStatus}
              />
            )}
            <HamburgerMenuItem
              destination="/application"
              title={t("pages.application.title")}
              Logo={appManagementLogo}
                disabled={!licenseStatus}
            />
            <HamburgerMenuItem
              destination="/group"
              title={t("pages.group.title")}
              Logo={groupManagementLogo}
              disabled={!licenseStatus}
              />
            <HamburgerMenuItem
              destination="/device"
              title={t("pages.device.title")}
              Logo={deviceManagementLogo}
              disabled={!licenseStatus}
              />
              {adminRole && (
              <HamburgerMenuItem
                destination="/policy"
                title={t("pages.policy.title")}
                Logo={policyManagementLogo}
                disabled={!licenseStatus}
              />
            )}
            {adminRole && deviceMode == "windows" && (
              <HamburgerMenuItem
                destination="/printer"
                title={t("pages.printer.title")}
                Logo={printerManagementLogo}
                disabled={!licenseStatus}
              />
            )}
            <HamburgerMenuItem
              destination="/progress"
              title={t("pages.progress.title")}
              Logo={progressLogo}
              disabled={!licenseStatus}
              />
            <HamburgerMenuItem
              destination="https://docs.numiato.de"
              title={t("pages.documentation.title")}
              Logo={documentationLogo}
              target="_blank"
            />
            <HamburgerMenuItem
              destination="/settings"
              title={t("pages.settings.title")}
              Logo={settingsLogo}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default HamburgerMenu;

import React, { useContext, useState, useRef } from "react";
import { ReactComponent as BroomIcon } from "../../icons/broom.svg";
import { useTranslation } from "react-i18next";
import { postApi } from "../../../tools/axiosInstances";
import ActionButton from "../../layout/buttons/ActionButton";
import { useToggleComponent } from "../../../tools/hooks";
import ModalCard, {
  ModalCardTitle,
  ModalCardButtons,
  ModalCardBody,
} from "../../layout/ModalCard";
import StandardButton from "../../layout/buttons/StandardButton";
function CleanPolicyManagementButton() {
  const { t } = useTranslation("global");
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setDisplayModal(!displayModal);
  const hide = () => setDisplayModal(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, hide);
  const handleCleanClick = () => {
    setLoading(true);
    const reqBody = { kind: "del", target: "pol", detail: [] };
    postApi("/tasks/", reqBody).then((a) => setLoading(false));
    hide();
  };
  return (
    <div className="flex space-x-4">
      <ActionButton
        Icon={BroomIcon}
        secondary
        onClick={toggle}
        disabled={loading}
      >
        {loading
          ? t("words.submitting")
          : t("pages.settings.policy.clean.button")}
      </ActionButton>
      {displayModal && (
        <ModalCard wide wrapper={wrapper}>
          <ModalCardTitle>
            {t(`pages.settings.modal.clean_pm.title`)}
          </ModalCardTitle>
          <ModalCardBody>
            {t(`pages.settings.modal.clean_pm.body`)}
          </ModalCardBody>
          <ModalCardButtons>
            <StandardButton onClick={toggle} subtle>
              {t("actions.cancel")}
            </StandardButton>
            <ActionButton
              destructive
              Icon={BroomIcon}
              onClick={handleCleanClick}
              loading={loading}
            >
              {t(`pages.settings.policy.clean.button`)}
            </ActionButton>
          </ModalCardButtons>
        </ModalCard>
      )}
    </div>
  );
}

export default CleanPolicyManagementButton;

import {
  createColumnHelper,
  getSortedRowModel,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IndeterminateCheckbox from "../layout/IndeterminateCheckbox";
import {
  fuzzyFilter,
  muxFilter,
} from "../device/DeviceTableFilterFn";
import SortingIcon from "../layout/SortingIcon";
import {ReactComponent as GhostImage} from "../../images/ghost.svg"
import TableLayout, { TableBody, TableDataCell, TableDataRow, TableHeader, TableHeaderRow } from '../layout/design/Table';
function PrinterTable({printerData,setSelected,filter,reload,clear,showColumns,showFilter}) {
  const [globalFilter, setGlobalFilter] = useState("") //Room/Category filter
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({

  });
  const [rowSelection, setRowSelection] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [sorting, setSorting] = useState([]);
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const data = useMemo(() => [...printerData], [filter, printerData, reload]);
  const clearColumnFilters = () => setColumnFilters([]);
  const columnHelper = createColumnHelper();

  const handleTableHead = () => {
    return t("table.header.printer", {
      total: data.length,
      count: Object.keys(rowSelection).length,
    });
  };
  const handleSelection = (e, row) => {
    setLastSelected(row);
    if (e.nativeEvent.shiftKey) {
      const sortedRows = table.getSortedRowModel().rows;
      const lastSelectedIndex = sortedRows.findIndex(
        (r) => r.id == lastSelected.id
      );
      const clickedIndex = sortedRows.findIndex((r) => r.id == row.id);

      if (lastSelectedIndex < clickedIndex) {
        for (let i = lastSelectedIndex; i <= clickedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      } else {
        for (let i = clickedIndex; i <= lastSelectedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      }
    } else {
      row.toggleSelected();
    }
  };

  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      className: "w-16",
      classNameHeader: "w-16",
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect}
          indeterminate={row.getIsSomeSelected()}
          onChange={(e) => {
            handleSelection(e, row);
          }}
        />
      ),
      enableResizing: false,
      enableSorting: false,
      enableColumnFilter: false,
    }),

    columnHelper.accessor("displayName", {
      header: () => <span>{handleTableHead()}</span>,
      cell: (info) => info.getValue(),
      className: "text-left cursor-pointer hover:text-ellipsis",
      classNameHeader: "cursor-pointer",
      // size: 210,
      minSize: 150,
      label: t("pages.printer.table.printer_name"),
      filterFn: muxFilter,
    }),
    columnHelper.accessor("manufacturer", {
      header: () => <span>{t("properties.manufacturer")}</span>,
      className: "cursor-pointer hover:text-ellipsis",
      classNameHeader: "cursor-pointer",
      cell: (info) => info.getValue(),
      // size: 160,
      minSize: 150,
      label: t("pages.device_detail.manufacturer"),
      canHide: true,
    }),
    columnHelper.accessor("ipAddress", {
      header: () => <span>{t("properties.ip_address")}</span>,
      className: "cursor-pointer",
      classNameHeader: "cursor-pointer",
      // size: 150,
      minSize: 150,
      enableColumnFilter: true,
      label: t("properties.ip_address"),
      canHide: true,
    }),
    columnHelper.accessor("status", {
      header: () => <span>{t("properties.status")}</span>,
      cell: (info) => (
        <span>
          {t(`status.${info.getValue()}`, info.getValue())}
        </span>
      ),
    }),
  ];

  useEffect(() => {
    let tableColumn = {
    };
    showColumns.map((col) => {
      tableColumn[col.id] = col.visible;
    });
    setColumnVisibility(tableColumn);
  }, [showColumns]);

  useEffect(() => {
    setShowFilterBar(showFilter);
    if (!showFilter) {
      clearColumnFilters();
    }
  }, [showFilter]);
  useEffect(() => {
    table.resetRowSelection(true);
  }, [clear]);
  // useEffect(() => {
  //   // Category/Room/Global filtering
  //   if (filter == "99999999-9999-9999-9999-999999999999") {
  //     setGlobalFilter("");
  //   } else {
  //     setGlobalFilter(filter);
  //   }
  // }, [filter]);
  useEffect(() => {
    setSelected(table.getSelectedRowModel().flatRows);
  }, [rowSelection]);
  const handleColumnFilterChange = (e) => {
    setColumnFilters(e);
    table.setRowSelection({});
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
      rowSelection,
      sorting,
      columnVisibility,
      columnFilters,
    },
    enableColumnResizing: true,
    enableColumnFilters: true,
    columnResizeMode: "onChange",
    enableRowSelection: true,
    enableGlobalFilter: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: handleColumnFilterChange,
    onSortingChange: setSorting,
    onglobalFilterFn: fuzzyFilter,
    GlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });


  return (
    <>
    <TableLayout>
      <TableHeaderRow top>
        {table
          .getHeaderGroups()
          .map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <TableHeader header={header} />
            ))
          )}
      </TableHeaderRow>
      <TableBody>
        {table.getRowModel().rows.length > 0 ? (
          table.getRowModel().rows.map((row) => (
            <TableDataRow isSelected={row.getIsSelected()}>
              {row.getVisibleCells().map((cell) => (
                <TableDataCell cell={cell} />
              ))}
            </TableDataRow>
          ))
        ) : (
          <NoPrintersMessage />
        )}
      </TableBody>
    </TableLayout>
  </>
  )
}
const NoPrintersMessage = () => {
  const { t } = useTranslation("global");
  return (
    <div className=" py-8">
      <div className="text-fgray-400 text-xl p-5 space-y-4 text-center place-items-center w-[835px] ">
        <GhostImage />
        <p>{t("pages.printer.table.no_printers")}</p>
      </div>
    </div>
  );
};

export default PrinterTable
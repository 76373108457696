import { isEqual } from "lodash";
import { useState, useEffect, useCallback, useContext } from "react";
import { getApi } from "../tools/axiosInstances";
import { PortalContext } from "../PortalContext";

const initialize = () => {
  const allRooms = JSON.parse(sessionStorage.getItem("allRooms"));
  return allRooms || false;
};

export const useRoom = () => {
  const [rooms, setRooms] = useState(initialize);
  const { refresh } = useContext(PortalContext);
  const handleFetchRooms = useCallback(async () => {
    getApi("/room/", {}).then((list) => {
      if (!isEqual(list.data, sessionStorage.getItem("allRooms"))) {
        setRooms(list.data);
        sessionStorage.setItem("allRooms", JSON.stringify(list.data));
      }
    });
  }, []);
  useEffect(() => {
    handleFetchRooms();
  }, [handleFetchRooms, refresh]);

  return rooms ;
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getApi } from "../../tools/axiosInstances";
import moment from "moment";

function PaymentInfo() {
  const { t } = useTranslation("global");
  const [paymentInfo, setPaymentInfo] = useState(false);
  useEffect(() => {
    getApi("/settings/payment/", {}).then((data) => setPaymentInfo(data.data));
  }, []);

  return (
    <>
      <div className="w-full p-4 align-items-center">
        {paymentInfo && paymentInfo.associationName && (
          <div className="py-4 flex">
            <p className="w-1/3 px-2 py-2 mx-2">
              {t("pages.settings.payment_info.payer")}
            </p>
            {paymentInfo ? (
              <div className="w-2/3 px-2 py-2 mx-2 ">
                {" "}
                {paymentInfo.associationName}
              </div>
            ) : (
              <div className="w-2/3 px-2 py-2 mx-2 h-8 bg-fgray-200 border rounded border-fgray-200 animate-pulse" />
            )}
          </div>
        )}
        <div className="py-4 flex">
          <p className="w-1/3 px-2 py-2 mx-2">
            {t("pages.settings.payment_info.expiration_date")}
          </p>
          {paymentInfo ? (
            <div className="w-2/3 px-2 py-2 mx-2 flex">
              {moment(paymentInfo.expirationDate).format("MMM Do YYYY")}
              <p className="text-fgray-400 ml-2">
                ({moment(paymentInfo.expirationDate).fromNow()})
              </p>
            </div>
          ) : (
            <div className="w-2/3 px-2 py-2 mx-2 h-8 bg-fgray-200 border rounded border-fgray-200 animate-pulse" />
          )}
        </div>
        <div className="py-4 flex">
          <p className="w-1/3 px-2 py-2 mx-2">{t("words.details")}</p>
          {paymentInfo ? (
            <div className="w-2/3 px-2 py-2 mx-2 ">
              {" "}
              {t(
                `payment.numiato_status.${paymentInfo.numiatoStatus}.description`
              )}
            </div>
          ) : (
            <div className="w-2/3 px-2 py-2 mx-2 h-8 bg-fgray-200 border rounded border-fgray-200 animate-pulse" />
          )}
        </div>
      </div>
    </>
  );
}

export default PaymentInfo;

import React from "react";

function LoadingText() {
  return (
    <div className="animate-pulse align-middle">
      <div className="h-5 w-48 rounded content-center bg-fgray-200" />
    </div>
  );
}

export default LoadingText;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import PeopleSelector from "../layout/PeopleSelector";
import { postApi } from "../../tools/axiosInstances";
import RoleDropdown from "./RoleDropdown";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardTitle,
  ModalCardButtons,
} from "../layout/ModalCard";
import StandardButton from "../layout/buttons/StandardButton";
function AddAdminModal({ refresh, toggle }) {
  const { t } = useTranslation("global");
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("smp.administrator");
  const wrapper = useRef(false);
  useToggleComponent(wrapper, toggle);
  function handleAddAdmin() {
    setLoading(true);
    if (selectedAdmins.length === 0) {
      toggle();
    } else {
      let reqBody = { kind: "asg", target: "set", type: "admin", detail: [] };
      for (let index = 0; index < selectedAdmins.length; index++) {
        const user = selectedAdmins[index];
        reqBody.detail.push({
          principalId: user.id,
          userPrincipalName: user.userPrincipalName,
          displayName: user.displayName,
          role: role,
        });
      }
      postApi("/tasks/", reqBody).then(() => {
        toggle();
        refresh();
        setLoading(false);
      });
    }
  }

  return (
    <>
      {
        <ModalCard wrapper={wrapper}>
          <ModalCardTitle>{t("pages.settings.modal.add.title")}</ModalCardTitle>
          <ModalCardBody>
            <div className="text-justify">
              {t("pages.settings.modal.add.user")}
              <PeopleSelector setSelected={setSelectedAdmins} />
            </div>
            <div className="text-justify">
              {t("pages.settings.modal.add.role")}
              <RoleDropdown role={role} setRole={setRole} />
            </div>
          </ModalCardBody>
          <ModalCardButtons>
            <StandardButton onClick={toggle} subtle>
              {t(`pages.settings.modal.add.cancel`)}
            </StandardButton>
            <StandardButton loading={loading} onClick={handleAddAdmin}>
              {t(`pages.settings.modal.add.add`)}
            </StandardButton>
          </ModalCardButtons>
        </ModalCard>
      }
    </>
  );
}

export default AddAdminModal;

import React from "react";
import SortingIcon from "../SortingIcon";
import { flexRender } from "@tanstack/react-table";
function TableLayout({ children, ...props }) {
  return (
    <div className="h-[calc(100%-4rem)] py-2">
      <table className="text-center w-full" {...props}>
        {children}
      </table>
    </div>
  );
}

export function TableHeaderRow({ top, children, ...props }) {
  return (
    <thead
      className={`m-2 ${top ? `top-0` : `top-6`} sticky bg-white `}
      {...props}
    >
      <tr>{children}</tr>
    </thead>
  );
}

export function TableHeader({ header, className, ...props }) {
  return (
    <th
      className={`py-4 truncate text-fgray-300 font-medium text-left ${
        header.column.columnDef.classNameHeader &&
        flexRender(header.column.columnDef.classNameHeader, header.getContext())
      }`}
      onClick={header.column.getToggleSortingHandler()}
      {...props}
    >
      <div className="flex justify-end">
        <div className={`flex px-4  w-full `}>
          <p
            className={`transition hover:text-black ${
              header.column.getIsSorted() && " text-black "
            } `}
          >
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </p>
          {header.column.getCanSort() && (
            <SortingIcon status={header.column.getIsSorted()} />
          )}
        </div>
        {header.column.getCanResize() && (
          <TableColumnResizing
            handler={header.getResizeHandler()}
            isResizing={header.column.getIsResizing()}
          />
        )}
      </div>
    </th>
  );
}

export function TableColumnResizing({ handler, isResizing }) {
  return (
    <div
      onMouseDown={handler}
      onTouchStart={handler}
      className={`h-10 border-r-2 select-none  touch-none align-middle absolute  cursor-col-resize hover:border-r-4 hover:border-fgray-300 ${
        isResizing ? " border-fgray-400 " : " "
      }`}
    />
  );
}

export function TableBody({ children, ...props }) {
  return <tbody {...props}>{children}</tbody>;
}

export function TableDataRow({ isSelected, children, ...props }) {
  return (
    <tr
      className={
        `border-y-2 font-normal  hover:font-medium focus:bg-forange-400 ` +
        (isSelected ? `bg-forange-200 font-medium` : `hover:bg-forange-100`)
      }
      {...props}
    >
      {children}
    </tr>
  );
}

export function TableDataCell({ cell, children, ...props }) {
  return (
    <td
      style={cell.column.getCanResize() ? { width: cell.column.getSize() } : {}}
      className={`py-5 font-xs px-4 ${
        cell.column.columnDef.className &&
        flexRender(cell.column.columnDef.className, cell.getContext())
      }`}
      {...props}
    >
      <p
        className="truncate text-left"
        style={
          cell.column.getCanResize() ? { width: cell.column.getSize() } : {}
        }
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </p>
    </td>
  );
}

export default TableLayout;

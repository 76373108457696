import React,{useState} from 'react'
import Field from './Field';
function EditableField({ setEditMode, value, validator, ...attributes }) {
  const [innerValue, setInnerValue] = useState(value);
  const [valid, setValid] = useState(true);
  const handleChangetext = (e) => {
    const text = e.target.value;
    if (e.target.value.match(validator) != null) {
      setValid(true);
      setInnerValue(text);
      setEditMode && setEditMode(text !== value);
    } else {
      setValid(false);
      setInnerValue(text);
      setEditMode && setEditMode(false);
    }
  };

  return (
    <Field
      value={innerValue}
      {...attributes}
      onChange={handleChangetext}
      invalid={!valid}
    />
  );
}

export default EditableField
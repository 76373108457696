import React,{useEffect, useState} from 'react'
import * as jose from 'jose'
import { useTranslation } from "react-i18next";

function FeedbackLink(props) {
  const [feedbackURL, setFeedbackURL] = useState("#")
  const { t } = useTranslation("global");
  useEffect(() => {
    const authSecret = "ea77740b3dc65ff834b1000bbd5fdd06869eb8cf8d5d8a0fe72c17667578e6f3deced64eef3d381a524f34a7f7a1a6d5a92de732392e45dee0956bcf0a7fc64b"
    // const authSecret = process.env.REACT_APP_FEEDBACK_SECRET
    const userInfo = JSON.parse(sessionStorage.getItem("me"))
    const data = {
      'email': userInfo.mail,
      'name': userInfo.displayName,
      'userpic':""
    }
    new jose.SignJWT(data)
    .setProtectedHeader({alg:"HS256"})
    .sign(new TextEncoder().encode(authSecret))
    .then((token)=>setFeedbackURL(token)
    )
    // setFeedbackURL(jwt.sign(data, authSecret, {algorithm: 'HS256'}))
    
  }, [])
  
  return (
    <a {...props} target='_blank' rel='noopener' href={`https://feedback.numiato.de/company/auth/?token=${feedbackURL}`}>{t("footer.feedback")}</a>
  )
}

export default FeedbackLink
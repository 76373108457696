import React from "react";
import ResultLine from "./ResultLine";
import ResultFooter from "./ResultFooter";
import { useTranslation } from "react-i18next";

function Results({ searchQuery, results, loading }) {
  const { t } = useTranslation("global");

  return (
    <div className="w-[535px] mx-2 absolute rounded-lg border z-50">
      {searchQuery && results.count > 0
        ? results.results
            .slice(0, 10)
            .map((result, index) => (
              <ResultLine
                result={result}
                query={searchQuery}
                key={`rl${index}`}
                num={index}
              />
            ))
        : results.count === 0 && (
            <div className="w-full p-2 justify-between bg-white flex hover:bg-forange-200">
              {t("pages.device.table.no_devices")}
            </div>
          )}
      <ResultFooter results={results} loading={loading} />
    </div>
  );
}

export default Results;

import React, { useCallback, useState } from "react";
import { ReactComponent as DownloadLogo } from "../../icons/download.svg";
import { ReactComponent as DropLogo } from "../../icons/install.svg";
import { ReactComponent as ClearLogo } from "../../icons/circleMinus.svg";
import { ReactComponent as ExclamationCircle } from "../../icons/circleExclamation.svg";
import { ReactComponent as WarningLogo } from "../../icons/warning.svg";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import Tooltip from "../../layout/tooltip/Tooltip";
function DropzoneUpload({ setLoadedData }) {
  const [parsedFile, setParsedFile] = useState(null);
  const [fileError, setFileError] = useState({ code: 200, message: null });
  const { t } = useTranslation("global");
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    clearData();
    if (acceptedFiles.length) {
      Papa.parse(acceptedFiles[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          let headers = {
            hhs: null,
            serialnumber: null,
            msid: null,
            category: null,
          };
          const valuesArray = [];
          Object.keys(results.data[0]).map((head, index) => {
            switch (head.toLowerCase().trim()) {
              case "serial":
              case "serial number":
              case "serial nummer":
              case "serialnumber":
              case "serialnummer":
              case "deviceserialnumber":
              case "deviceserialnummer":
              case "device serial number":
              case "device serial nummer":
              case "device serialnumber":
              case "device serialnummer":
                headers.serialnumber = index;
                break;
              case "hardware hash":
              case "hardwarehash":
                headers.hhs = index;
                break;
              case "windows product id":
              case "windows productid":
              case "windowsproductid":
              case "windows produkt id":
              case "windows produktid":
              case "windowsproduktid":
              case "produkt id":
              case "produktid":
              case "productid":
              case "product id":
                headers.msid = index;
                break;
            }
          });
          if (
            headers.hhs == null ||
            headers.serialnumber == null ||
            headers.msid == null
          ) {
            if (
              headers.hhs == null &&
              headers.serialnumber == null &&
              headers.msid == null
            ) {
              // No column was found
              setFileError({ code: 401, message: acceptedFiles[0].name });
              return;
            }
            setFileError({ code: 300, message: acceptedFiles[0].name }); // Some column is missing
          }
          results.data.map((line) => {
            const d = Object.values(line);
            valuesArray.push({
              hhs: d[headers?.hhs] || "",
              serialnumber: d[headers?.serialnumber] || "",
              msid: d[headers?.category] || "",
              category: d[headers?.category] || "",
            });
          });
          setLoadedData(valuesArray);
          setParsedFile(acceptedFiles[0].name);
        },
      });
    }
    if (fileRejections.length) {
      setFileError({ code: 400, message: fileRejections[0].file.name });
    }
  });
  const clearData = () => {
    setLoadedData([]);
    setParsedFile(null);
    setFileError({code:200,message:null})
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "text/csv": [".csv"] },
  });
  
  return (
    <>
      {fileError.code && <ErrorCard status={fileError} />}
        <div {...getRootProps({id:'dropzone'})}>
          <input {...getInputProps({id:'dropzone-input'})} />
          <div className="m-auto items-center p-2 overflow-hidden w-full ">
            <div className=" mx-auto p-2 my-2 rounded-xl shadow-md">
              <div className="mx-auto p-2 overflow-hidden flex justify-between">
                {isDragActive ? (
                  <>
                    <div className="flex">
                      <div className="bg-forange-200 rounded-2xl w-10 h-10 items-center align-middle flex">
                        <DropLogo className="w-5 h-5 text-forange-400 m-auto" />
                      </div>
                      <div className="px-4 py-2 text-fgray-400 ">
                        {t("pages.adddevice.dropzone.drag")}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex">
                      <div className="bg-forange-200 rounded-2xl w-10 h-10 items-center align-middle flex">
                        <DownloadLogo className="w-5 h-5 text-forange-400 m-auto rotate-180" />
                      </div>
                      <div className="px-4 py-2 text-fgray-400 ">
                        {parsedFile
                          ? t("pages.adddevice.dropzone.replace_file")
                          : t("pages.adddevice.dropzone.upload")}
                      </div>
                    </div>
                    {parsedFile && (
                      <div
                        className="flex"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="px-4 py-2 text-fblack flex ">
                          {parsedFile}
                          <Tooltip
                            position="bottom"
                            title={"tooltip.addevice.dropzone.clear_data"}
                          >
                            <ClearLogo
                              className="h-6 w-6 ml-2"
                              onClick={clearData}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

function ErrorCard({ status }) {
  const { t } = useTranslation("global");
  /*
  200 - OK -> No card
  30X - Warning
  40X - Error
   */
  switch (status.code) {
    case 300:
      return (
        <>
          <DropzoneStatusCard className="bg-yellow-50">
            <div className="flex items-center ">
              <WarningLogo className="h-6 w-6 text-yellow-500" />
              <div className="px-4 py-2 text-fblack ">
                <div className="font-bold">
                  {t("pages.adddevice.dropzone.warning.title")}
                </div>
                <div className="text-fgray-400 text-sm">
                  {t(`pages.adddevice.dropzone.warning.${status.code}`, {
                    message: status.message,
                  })}
                </div>
              </div>
            </div>
          </DropzoneStatusCard>
        </>
      );
      break;
    case 400:
    case 401:
    case 402:
      return (
        <>
          <DropzoneStatusCard className="bg-red-50">
            <div className="flex items-center ">
              <ExclamationCircle className="h-6 w-6 text-fstatus-400" />
              <div className="px-4 py-2 text-fblack ">
                <div className="font-bold">
                  {t("pages.adddevice.dropzone.error.title")}
                </div>
                <div className="text-fgray-400 text-sm">
                  {t(`pages.adddevice.dropzone.error.${status.code}`, {
                    message: status.message,
                  })}
                </div>
              </div>
            </div>
          </DropzoneStatusCard>
        </>
      );
      break;
    case 200:
    default:
      return <></>;
      break;
  }
}

export default DropzoneUpload;

function DropzoneStatusCard({ className, children }) {
  return (
    <>
      <div className={`py-2 px-4 mx-2 rounded-xl shadow-md ${className} `}>
        <div className="mx-auto overflow-hidden flex justify-between">
          {children}
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getApi } from "../tools/axiosInstances";
import TabBar from "../components/layout/TabBar";
import AppCard from "../components/appstore/AppCard";
import LoadingAppCard from "../components/layout/loading/LoadingAppCard";
import { ValidateRole, useCheckLicenseStatus } from "../tools/utilties";
import { PortalContext } from "../PortalContext";
import { useNavigate } from "react-router-dom";
import TenantChannel from "../components/topbar/TenantChannel";
function Appstore() {
  const { t } = useTranslation("global");
  const { deviceMode } = useContext(PortalContext);
  const [apps, setApps] = useState([]);
  const [tab, setTab] = useState(0);
  const [filtered, setFiltered] = useState(false);
  const [reloadList, setreloadList] = useState(0);
  const [installModal, setInstallModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const [updateBadge, setUpdateBadge] = useState(999);
  const navigate = useNavigate();
  const toggleInstall = () => {
    setInstallModal(!installModal);
  };
  useCheckLicenseStatus();
  function tabHandler(name) {
    setTab(name);
    name === 0 ? setFiltered(false) : setFiltered(true);
  }
  useEffect(() => {
    if (deviceMode != "windows") {
      navigate("/");
    }
  }, [deviceMode]);
  useEffect(() => {
    getApi("/appstore/", {}).then((list) => {
      setApps(list.data);
    });
  }, [reloadList]);
  const reload = () => setreloadList((p) => p + 1);
  useEffect(() => {
    ValidateRole();
  }, []);
  useEffect(() => {
    let totalUpdates = 0;
    apps.map((app) => {
      if (app.updatable) {
        totalUpdates++;
      }
    });
    setUpdateBadge(totalUpdates);
  }, [apps]);

  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between ">
          <div className="text-2xl font-medium">
            {t("pages.appstore.title")}
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="mx-auto max-w-6xl m-5 overflow-auto">
        <TabBar
          options={[
            {
              title: t("pages.appstore.tabs.all"),
              tooltip: "tooltip.appstore.tabs.all",
              id: 0,
            },
            {
              title: t("pages.appstore.tabs.downloaded"),
              tooltip: "tooltip.appstore.tabs.downloaded",
              id: 1,
            },
            {
              title: t("pages.appstore.tabs.updatable"),
              tooltip: "tooltip.appstore.tabs.updatable",
              id: 2,
            },
          ]}
          handler={tabHandler}
          activeTab={tab}
          updateBadge={updateBadge}
        />
      </div>
      <div className="mx-auto max-w-6xl m-5 flex overflow-auto">
        <div
          key="appstoreGrid"
          className="grid grid-cols-4 gap-4 mb-12 items-start"
        >
          <TenantChannel/>
          {apps.length > 0 ? (
            apps.map((app, index) => {
              return tab == 0 ? (
                <AppCard
                  info={app}
                  key={`app${index}`}
                  toggleInstall={toggleInstall}
                  select={setSelected}
                  reload={reload}
                />
              ) : tab == 1 ? (
                app.isInstalled && (
                  <AppCard
                    info={app}
                    key={`app${index}`}
                    toggleInstall={toggleInstall}
                    select={setSelected}
                    reload={reload}
                  />
                )
              ) : tab == 2 ? (
                app.updatable && (
                  <AppCard
                    info={app}
                    key={`app${index}`}
                    toggleInstall={toggleInstall}
                    select={setSelected}
                    reload={reload}
                  />
                )
              ) : (
                <AppCard
                  info={app}
                  key={`app${index}`}
                  toggleInstall={toggleInstall}
                  select={setSelected}
                  reload={reload}
                />
              );
            })
          ) : (
            <LoadingAppCard />
          )}
        </div>
      </div>
    </>
  );
}

export default Appstore;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as RefreshIcon } from "../../icons/refresh.svg";
import IconButton from "./IconButton";
import { PortalContext } from "../../../PortalContext";

function RefreshButton({ loading, handleRefresh }) {
  const { t } = useTranslation("global");
  const {makeRefresh} = useContext(PortalContext)
  return (
    <IconButton
    subtle
      Icon={RefreshIcon}
      onClick={() => !loading && makeRefresh()}
      tooltip="tooltip.buttons.refresh"
    >
      {t("words.refresh")}
    </IconButton>
  );
}

export default RefreshButton;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import CategoryCombo from "../layout/CategoryCombo";
import RoomCombo from "../layout/RoomCombo";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardTitle,
  ModalCardBody,
  ModalCardButtons,
} from "../layout/ModalCard";
function DeviceAssignModal({ selected, device, showModal, toggle, clear }) {
  const { t } = useTranslation("global");
  const [selCategory, setSelCategory] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selRoom, setSelRoom] = useState(0);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);
  function handleAssignment(event) {
    event.preventDefault();
    setLoading(true);
    let reqBody = {
      kind: "asg",
      target: "dev",
      detail: [],
    };
    if (selCategory !== 0) {
      device
        ? reqBody.detail.push({
            deviceId: device.id,
            categoryId: selCategory,
            objectId: device.objectId,
            deviceType: "enrolled",
          })
        : selected.map((dev) => {
            reqBody.detail.push({
              deviceId: dev.original.id,
              categoryId: selCategory,
              objectId: dev.original.objectId,
              deviceType: "enrolled",
            });
          });
    }
    if (selRoom !== 0) {
      device
        ? reqBody.detail.push({
            deviceId: device.id,
            roomId: selRoom,
            objectId: device.objectId,
            deviceType: "enrolled",
          })
        : selected.map((dev) => {
            reqBody.detail.push({
              deviceId: dev.original.id,
              roomId: selRoom,
              objectId: dev.original.objectId,
              deviceType: "enrolled",
            });
          });
    }
    (selCategory || selRoom) &&
      postApi("/tasks/", reqBody).then((response) => {
        toggle();
        setLoading(false);
        clear && clear();
      });
  }

  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>{t("pages.device.modal.assign.title")}</ModalCardTitle>
        <ModalCardBody>
          <h2 className=" text-justify ">
            {device
              ? t("pages.device.modal.assign.body_1")
              : selected.length === 1
              ? t("pages.device.modal.assign.body_1")
              : t("pages.device.modal.assign.body_n")}
          </h2>
          <div className="text-left">
            {t("pages.device.table.category")}
          
          <CategoryCombo
            className="text-red-700"
            setSelected={setSelCategory}
            includeUnassigned="True"
          />
</div>
          <div className="text-left">
            {t("pages.device.table.room")}
          
          <RoomCombo setSelected={setSelRoom} includeUnassigned="True" /></div>
        </ModalCardBody>
        <ModalCardButtons>
        <StandardButton onClick={toggle} subtle>
            {t("pages.device.modal.cancel")}
          </StandardButton>
          <StandardButton loading={loading} onClick={handleAssignment}>
            {t("pages.device.assign")}
          </StandardButton>
          
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default DeviceAssignModal;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import MultilineTranslation from "../layout/MultilineTranslation";
import ActionButton from "../layout/buttons/StandardButton";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import Parameters from "./Parameters";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";
function AppUpdateModal({ app, toggle, setTaskId }) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);

  const handleUpdate = () => {
    setLoading(true);
    const parameters = gatherParameters() || []
    let reqBody = {
      detail: [{ applicationId: app.id, parameters: parameters }],
      kind: "upd",
      target: "app",
    };
    postApi("/tasks/", reqBody).then((response) => {
      setLoading(false);
      setTaskId(response.data.identifier);
      toggle();
    });
  };
  const gatherParameters = () => {
    let response = [];
    for (let i = 0; i < app.parameters.length; i++) {
      const parameter = app.parameters[i];
      switch (parameter.type) {
        case "boolean":
          response.push({
            id: parameter.id,
            value: document.getElementById(parameter.id).checked,
          });
          break;
        case "text":
          response.push({
            id: parameter.id,
            value: document.getElementById(parameter.id).value,
          });
          break;
        default:
          break;
      }
    }
    return response;
  };
  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>
          {t("pages.appstore.actions.update_modal", {
            appName: app.display_name,
            appVersion: app.version,
          })}
        </ModalCardTitle>
        <ModalCardBody>
          <div className="space-y-4">
            {Array.isArray(app.parameters) &&
              app.parameters.map((parameter) => (
                <Parameters parameter={parameter} />
              ))}
          </div>
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t("pages.appstore.actions.cancel")}
          </StandardButton>
          <StandardButton loading={loading} onClick={handleUpdate}>
            {t("pages.appstore.actions.update")}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

export default AppUpdateModal;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileStatus from "./ProfileStatus";
import ProfileChild from "./ProfileChild";
import Parameter from "./Parameter";
import { useEffect } from "react";
function Profile({ profile, scope, mode, reload, checkRevertable }) {
  const { t } = useTranslation("global");
  const [profileStatus, setProfileStatus] = useState("pending");
  const [parameterStatus, setParameterStatus] = useState(profile.parameters);

  // useEffect(() => {
  //   !mode && setParameterStatus(false);
  // }, [mode]);

  useEffect(() => {
    if (parameterStatus) {
      parameterStatus.map((p) => {
        switch (p.type) {
          case "localAdmin":
            sessionStorage.setItem(
              p.id,
              JSON.stringify(p.selected) || p.default || ""
            );
            break;
          case "file":
            try {
              sessionStorage.setItem(
                p.id,
                JSON.stringify(p.selected.name) || p.default || ""
              );
            } catch {
              sessionStorage.setItem(p.id, p.default || "");
            }
            break;
          case "linkedMenu":
            break;
          default:
            sessionStorage.setItem(p.id, p.selected || p.default || "");
            break;
        }
      });
    }
  }, [parameterStatus]);

  return (
    <>
      <li
        className="px-8 py-3 flex justify-between rounded-md hover:bg-forange-100"
        key={`li.${profile.id}`}
      >
        <span className="w-3/4 items-baseline">
          <span className="">{t(`policy.${profile.id}.displayName`)}</span>
          <span className="flex-1 my-2">
            {parameterStatus &&
              [...parameterStatus]
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((definition, index) => (
                  <Parameter
                    key={`param${index}`}
                    profileId={profile.id}
                    definition={definition}
                    mode={mode}
                  />
                ))}
          </span>
        </span>
        <span className="items-baseline flex">
          <ProfileStatus
            profile={profile}
            scope={scope}
            mode={mode}
            reload={reload}
            setParameters={setParameterStatus}
            status={profileStatus}
            setStatus={setProfileStatus}
            parentStatus={true}
            checkRevertable={checkRevertable}
          />
        </span>
      </li>
      {profile.childs.map((profil, indx) => (
        <ProfileChild
          key={`prfch.${indx}`}
          profile={profil}
          scope={scope}
          mode={mode}
          reload={reload}
          parentStatus={profileStatus}
          checkRevertable={checkRevertable}
        />
      ))}
    </>
  );
}

export default Profile;

import React, { useState, useEffect, useContext } from "react";
import { getApi, getApiDelegated } from "../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import GroupProgress from "../components/group/GroupProgress";
import DeviceActionModal from "../components/device/DeviceActionModal";
import DeviceAssignModal from "../components/device/DeviceAssignModal";
import { PortalContext } from "../PortalContext";
import { ReactComponent as PasscodeLogo } from "../components/icons/lock-open.svg";
import { ReactComponent as SyncLogo } from "../components/icons/sync.svg";
import { ReactComponent as RebootLogo } from "../components/icons/reboot.svg";
import { ReactComponent as ResetLogo } from "../components/icons/reset.svg";
import { ReactComponent as RemoveLogo } from "../components/icons/remove.svg";
import { ReactComponent as AssignLogo } from "../components/icons/assign.svg";
import { ReactComponent as ArrobBackIcon } from "../components/icons/arrowBack.svg";
import ActionButton from "../components/layout/buttons/ActionButton";
import MoreButtons from "../components/layout/buttons/MoreButtons";
import MetricStatusCard from "../components/metrics/MetricStatusCard";
import { isUUID4, useCheckLicenseStatus } from "../tools/utilties";
import DeviceAssignUnenrolledModal from "../components/device/DeviceAssignUnenrolledModal";
import PageTitle from "../components/layout/PageTitle";
import DeviceDetailsBar from "../components/deviceDetails/DeviceDetailsBar";
import BasicInformation from "../components/deviceDetails/BasicInformation";
import StatusBox from "../components/deviceDetails/StatusBox";
import SystemInfoBox from "../components/deviceDetails/SystemInfoBox";
import DeviceAppsBox from "../components/deviceDetails/DeviceAppsBox";
import DeviceSecurityBox from "../components/deviceDetails/DeviceSecurityBox";
import LoadingTable from "../components/layout/loading/LoadingTable";
import PendingTasks from "../components/deviceDetails/PendingTasks";
function DeviceDetail() {
  const { deviceMode, setDeviceMode } = useContext(PortalContext);
  const { t } = useTranslation("global");
  const [selectedTab, setSelectedTab] = useState("basic");
  const [deviceEnrolled, setDeviceEnrolled] = useState(true);
  const [sync, setSync] = useState(false);
  const [reboot, setReboot] = useState(false);
  const [reset, setReset] = useState(false);
  const [remove, setRemove] = useState(false);
  const [assign, setAssign] = useState(false);
  const [assignUnenrolled, setAssignUnenrolled] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [reload, setReload] = useState(0);
  const [resetPasscode, setResetPasscode] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState(null);
  let { id } = useParams();
  const [deviceInfo, setDeviceInfo] = useState(false);
  const [deviceApps, setDeviceApps] = useState(false);
  const [deviceBitlocker, setDeviceBitlocker] = useState("loading");
  const userRole = sessionStorage.getItem("role");
  const navigate = useNavigate();
  useCheckLicenseStatus();
  useEffect(() => {
    if (isUUID4(id)) {
      setDeviceEnrolled(true);
      getApi(`/device/${id}/`, {}).then((info) => {
        setDeviceInfo(info.data);
        setDeviceMode(info.data.type.toLowerCase());
        setDeviceStatus(info.data.statusInfo);
        if (info.data.type.toLowerCase() == "windows"){
          getApiDelegated(`/device/${id}/bitlocker/`).then((keys) => {
            keys.data.length > 0
              ? setDeviceBitlocker(keys.data)
              : setDeviceBitlocker(false);
          });
        } else if (info.data.type.toLowerCase() == "macos"){
          getApi(`/device/${id}/filevault/`).then((keys) => {
            keys.data.length > 0
              ? setDeviceBitlocker(keys.data)
              : setDeviceBitlocker(false);
          });}
      });
      getApi(`/device/${id}/apps`, {}).then((apps) => {
        setDeviceApps(apps.data);
      });
      
    } else {
      setDeviceEnrolled(false);
      getApi(`/device/unenrolled/${id}/`, {}).then((info) => {
        setDeviceInfo(info.data);
        setDeviceMode(info.data.type.toLowerCase());
        setDeviceStatus(info.data.statusInfo);
      });
    }
  }, [reload, id]);
  useEffect(() => {
    setDeviceInfo(false);
    setDeviceStatus(null);
  }, [id]);

  function toggleSync() {
    setSync(!sync);
  }
  function toggleReboot() {
    setReboot(!reboot);
  }
  function toggleReset() {
    setReset(!reset);
  }
  function toggleRemove() {
    setRemove(!remove);
  }
  function toggleResetPasscode() {
    setResetPasscode(!resetPasscode);
  }
  function toggleAssign() {
    deviceEnrolled
      ? setAssign(!assign)
      : setAssignUnenrolled(!assignUnenrolled);
  }
  useEffect(() => {
    if (deviceInfo) {
      if (deviceMode != deviceInfo.type) {
        // navigate("/device");
        setDeviceMode(deviceInfo.type.toLowerCase());
      }
    }
  }, [deviceMode]);

  const enrolledButtons = (
    <>
      <ActionButton
        id="sync"
        Icon={SyncLogo}
        subtle
        onClick={toggleSync}
        tooltip={`tooltip.device.${deviceMode}.sync`}
      >
        {t("pages.device.sync")}
      </ActionButton>

      {deviceMode == "ipad" && (
        <ActionButton
          id="passcode"
          Icon={PasscodeLogo}
          onClick={toggleResetPasscode}
          tooltip={`tooltip.device.${deviceMode}.passcode`}
          subtle
        >
          {t("pages.device.passcode")}
        </ActionButton>
      )}
      {deviceMode == "windows" || deviceMode == "macos" && (
        <ActionButton
          id="reset"
          Icon={ResetLogo}
          onClick={toggleReset}
          tooltip={`tooltip.device.${deviceMode}.reset`}
          subtle
        >
          {t("pages.device.reset")}
        </ActionButton>
      )}
      <ActionButton
        id="assign"
        Icon={AssignLogo}
        onClick={toggleAssign}
        tooltip={`tooltip.device.${deviceMode}.assign`}
        subtle
      >
        {t("pages.device.assign")}
      </ActionButton>
      <MoreButtons>
        {deviceMode == "ipad" && (
          <ActionButton
            id="reset"
            Icon={ResetLogo}
            onClick={toggleReset}
            tooltip={`tooltip.device.${deviceMode}.reset`}
            position="bottom"
          >
            {t("pages.device.reset")}
          </ActionButton>
        )}
        <ActionButton
          id="reboot"
          Icon={RebootLogo}
          onClick={toggleReboot}
          tooltip={`tooltip.device.${deviceMode}.reboot`}
          position="bottom"
        >
          {t("pages.device.reboot")}
        </ActionButton>
        {userRole === "smp.administrator" && (
          <ActionButton
            id="remove"
            Icon={RemoveLogo}
            onClick={toggleRemove}
            tooltip={`tooltip.device.${deviceMode}.remove`}
            position="bottom"
          >
            {t("pages.device.remove")}
          </ActionButton>
        )}
      </MoreButtons>
    </>
  );
  const unenrolledButtons = (
    <>
      {deviceInfo && (
        <>
          <ActionButton
            id="remove"
            Icon={RemoveLogo}
            onClick={toggleRemove}
            tooltip={`tooltip.device.${deviceMode}.remove`}
            position="bottom"
            subtle
          >
            {t("pages.device.remove")}
          </ActionButton>
          <ActionButton
            id="assignUnenrolled"
            Icon={AssignLogo}
            onClick={toggleAssign}
            subtle
          >
            {t("pages.device.assign")}
          </ActionButton>
        </>
      )}
    </>
  );

  return (
    <>
      {/*Title Bar*/}
      <PageTitle
        title={deviceInfo && deviceInfo.deviceName}
        Icon={ArrobBackIcon}
        iconOnClick={() => navigate(-1)}
      >
        {deviceEnrolled ? enrolledButtons : unenrolledButtons}
      </PageTitle>
      {/* Content */}
      {deviceStatus && (
        <MetricStatusCard
          info={deviceStatus}
          fun={{ toggleAssign, toggleReboot }}
        />
      )}
      {deviceEnrolled && deviceInfo && (
        <PendingTasks tasks={deviceInfo.pendingTasks} />
      )}

      <div className="mx-auto max-w-6xl overflow-auto mb-12 space-y-5 h-full">
        {deviceEnrolled ? (
          deviceInfo &&
          (deviceInfo.type == "windows" ||deviceInfo.type == "macos" ? (
            <DeviceDetailsBar initial="basic" setSelectedTab={setSelectedTab}>
              <div id="basic">{t("pages.device_detail.bar.basic")}</div>
              <div id="system">{t("pages.device_detail.bar.system")}</div>
              <div id="apps">{t("pages.device_detail.bar.apps")}</div>
              <div id="security">{t("pages.device_detail.bar.security")}</div>
            </DeviceDetailsBar>
          ) : (
            <DeviceDetailsBar initial="basic" setSelectedTab={setSelectedTab}>
              <div id="basic">{t("pages.device_detail.bar.basic")}</div>
              <div id="system">{t("pages.device_detail.bar.system")}</div>
              <div id="apps">{t("pages.device_detail.bar.apps")}</div>
            </DeviceDetailsBar>
          ))
        ) : (
          <DeviceDetailsBar initial="basic" setSelectedTab={setSelectedTab}>
            <div id="basic">{t("pages.device_detail.bar.basic")}</div>
          </DeviceDetailsBar>
        )}
        {deviceInfo ? (
          <div className="flex w-full">
            <div className="w-2/3">
              {selectedTab == "basic" ? (
                <BasicInformation
                  deviceInfo={deviceInfo}
                  setDeviceInfo={setDeviceInfo}
                  toggleAssign={toggleAssign}
                  enrolled={deviceEnrolled}
                />
              ) : selectedTab == "system" ? (
                <SystemInfoBox deviceInfo={deviceInfo} />
              ) : selectedTab == "apps" ? (
                deviceApps ? (
                  <DeviceAppsBox deviceApps={deviceApps} />
                ) : (
                  <LoadingTable />
                )
              ) : selectedTab == "security" ? (
                deviceBitlocker != "loading" ? (
                  <DeviceSecurityBox
                    deviceInfo={deviceInfo}
                    bitlocker={deviceBitlocker}
                  />
                ) : (
                  <LoadingTable />
                )
              ) : (
                navigate(-1)
              )}
            </div>
            <div className="w-1/3">
              {deviceInfo && (
                <StatusBox
                  deviceInfo={deviceInfo}
                  deviceStatus={deviceStatus}
                  enrolled={deviceEnrolled}
                />
              )}
            </div>
          </div>
        ) : (
          <LoadingTable />
        )}
      </div>
      <div className="h-16"></div>
      {sync && (
        <DeviceActionModal
          action="sync"
          device={deviceInfo}
          setTaskId={setTaskId}
          toggle={toggleSync}
        />
      )}
      {reboot && (
        <DeviceActionModal
          action="reboot"
          device={deviceInfo}
          setDeviceInfo={setDeviceInfo}
          setTaskId={setTaskId}
          toggle={toggleReboot}
        />
      )}
      {reset && (
        <DeviceActionModal
          action="reset"
          device={deviceInfo}
          setDeviceInfo={setDeviceInfo}
          setTaskId={setTaskId}
          toggle={toggleReset}
        />
      )}
      {remove && (
        <DeviceActionModal
          action="remove"
          device={deviceInfo}
          setTaskId={setTaskId}
          toggle={toggleRemove}
        />
      )}
      {resetPasscode && (
        <DeviceActionModal
          action="resetPasscode"
          device={deviceInfo}
          setTaskId={setTaskId}
          toggle={toggleResetPasscode}
        />
      )}
      {assign && (
        <DeviceAssignModal
          action="assign"
          device={deviceInfo}
          showModal={assign}
          setTaskId={setTaskId}
          toggle={toggleAssign}
        />
      )}
      {assignUnenrolled && (
        <DeviceAssignUnenrolledModal
          action="assign"
          device={deviceInfo}
          showModal={assignUnenrolled}
          setTaskId={setTaskId}
          toggle={toggleAssign}
        />
      )}
      {taskId ? (
        <GroupProgress
          taskId={taskId}
          setTaskId={setTaskId}
          reload={setReload}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default DeviceDetail;

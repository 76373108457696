import React, { useContext, useEffect, useState } from "react";
import { PortalContext } from "../../PortalContext";
import { useTranslation } from "react-i18next";
import { ReactComponent as ComputerLogo } from "../icons/computer.svg";
import { ReactComponent as TabletLogo } from "../icons/tablet.svg";
import { ReactComponent as UnenrolledIcon } from "../icons/unenrolled.svg";
import { ReactComponent as FilterIcon } from "../icons/filter.svg";
import DeviceTableColumnSelect from "../device/DeviceTableColumnSelect";
import Tooltip from "./tooltip/Tooltip";
function DeviceModeBar({ unenrolled, setFilter, columns, setColumns, target }) {
  const { t } = useTranslation("global");
  const { deviceMode, setDeviceMode } = useContext(PortalContext);
  const [selected, setSelected] = useState(deviceMode);
  const [filtered, setFiltered] = useState(false);
  const handleSetFilter = () => {
    setFilter((p) => !p);
    setFiltered((p) => !p);
  };
  useEffect(() => {
    if (selected === "unenrolled") {
      unenrolled && unenrolled(true);
    } else {
      setDeviceMode(selected);
      unenrolled && unenrolled(false);
    }
  }, [selected]);
  useEffect(() => {
    if (selected === "windows" || selected === "ipad"|| selected === "macos") {
      setSelected(deviceMode);
    }
  }, [deviceMode]);

  return (
    <div className="w-full flex border-b-[1px] text-fblack border-fgray-200 z-20 sticky top-0 bg-white justify-between">
      <div className="flex">
        <Tooltip title={t(`tooltip.${target}.bar.windows`,"")}>
          {" "}
          <div
            className={
              `flex px-2 py-1 cursor-pointer border-b-4 z-10 ` +
              (selected == "windows"
                ? ` border-forange-400`
                : `border-transparent`)
            }
            onClick={() => setSelected("windows")}
          >
            <ComputerLogo className="h-4 w-4 m-auto" />
            <p className="text-center text-sm font-medium px-2 truncate m-auto">
              {t("pages.home.windows")}
            </p>
          </div>
        </Tooltip>
        <Tooltip title={t(`tooltip.${target}.bar.ipad`,"")}>
          <div
            className={
              `flex px-2 py-1 cursor-pointer border-b-4  ` +
              (selected == "ipad" ? `border-forange-400` : `border-transparent`)
            }
            onClick={() => setSelected("ipad")}
          >
            <TabletLogo className="h-4 w-4 m-auto" />
            <p className="text-center text-sm font-medium px-2 truncate m-auto">
              {t("pages.home.ipad")}
            </p>
          </div>
        </Tooltip>
        <Tooltip title={t(`tooltip.${target}.bar.macos`,"")}>
          <div
            className={
              `flex px-2 py-1 cursor-pointer border-b-4  ` +
              (selected == "macos" ? `border-forange-400` : `border-transparent`)
            }
            onClick={() => setSelected("macos")}
          >
            <ComputerLogo className="h-4 w-4 m-auto" />
            <p className="text-center text-sm font-medium px-2 truncate m-auto">
              {t("words.macos")}
            </p>
          </div>
        </Tooltip>
        {unenrolled && (
          <Tooltip title="tooltip.device.bar.unenrolled">
            <div
              className={
                `flex px-2 py-1 cursor-pointer border-b-4  ` +
                (selected == "unenrolled"
                  ? `border-forange-400`
                  : `border-transparent`)
              }
              onClick={() => setSelected("unenrolled")}
            >
              <UnenrolledIcon className="h-4 w-4 m-auto" />
              <p className="text-center text-sm font-medium px-2 truncate m-auto">
                {t("pages.device.unenrolled")}
              </p>
            </div>
          </Tooltip>
        )}
      </div>
      <div className="flex">
        {/* <div className=""> */}
        {columns && (
          <Tooltip title="tooltip.device.bar.columns">
            <DeviceTableColumnSelect
              columns={columns}
              setColumns={setColumns}
            />
          </Tooltip>
        )}

        {/* </div> */}
        {setFilter && (
          <Tooltip title="tooltip.device.bar.filter">
            <div
              className={`flex px-2 py-1 cursor-pointer  `}
              onClick={handleSetFilter}
            >
              <FilterIcon className="h-5 w-5 m-auto" />
              <p className="text-center text-sm font-medium px-2 truncate m-auto">
                {filtered ? t("words.hide_filter") : t("words.filter")}
              </p>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default DeviceModeBar;

import React from "react";
import { useTranslation } from "react-i18next";
import LoadingSidebar from "./loading/LoadingSidebar";
import { useCategory } from "../../hooks/useCategory";
import { useRoom } from "../../hooks/useRoom";
import { ReactComponent as Chevron } from "../icons/arrow.svg";
function Sidebar({ unassigned, filter, setFilter, globalId }) {
  const { t } = useTranslation("global");
  const categoryList = useCategory();
  const roomList = useRoom();
  function handleToggleFilter(name) {
    setFilter(name);
  }
  const SidebarItem = ({ filterCompare, children }) => {
    return (
      <li
        className={
          `rounded nav-item px-4 py-3 mx-4 list-none items-center cursor-pointer hover:font-medium font-normal active:rounded ` +
          (filter === filterCompare
            ? "bg-forange-400 text-white font-medium"
            : " hover:text-forange-400 ")
        }
        aria-current="page"
        key={filterCompare}
        onClick={(e) => {
          e.preventDefault();
          handleToggleFilter(filterCompare);
        }}
      >
        {children}
      </li>
    );
  };

  return (
    <>
      <div className="min-w-64 justify-end mr-5 mb-5">
        <nav className=" mr-5 w-64 mb-12 ">
          <ul className="nav flex flex-col w-full">
            {/* allDevices */}
            <SidebarItem filterCompare={globalId}>
              {t("sidebar.all_devices")}
            </SidebarItem>
            {/* Categories */}
            <SidebarGroup
              title={t("sidebar.category.title")}
              count={categoryList?.length || 0}
            >
              {categoryList ? (
                categoryList.map(({ id, displayName, shared, groupId }) => (
                  <SidebarItem filterCompare={groupId}>
                    {shared
                      ? t(
                          `categories.name.${displayName}`,
                          `${displayName.substring(7)} ${t(
                            "categories.name.shared"
                          )}`
                        )
                      : t(`categories.name.${displayName}`, displayName)}
                  </SidebarItem>
                ))
              ) : (
                <LoadingSidebar />
              )}
              {unassigned && categoryList && (
                <SidebarItem filterCompare={"No_Category"}>
                  {t("sidebar.no_category")}
                </SidebarItem>
              )}
            </SidebarGroup>
            {/* Rooms */}
            <SidebarGroup
              title={t("sidebar.room.title")}
              count={roomList?.length || 0}
            >
              {roomList ? (
                roomList.map(({ id, displayName }) => (
                  <SidebarItem filterCompare={id}>{t(displayName)}</SidebarItem>
                ))
              ) : (
                <LoadingSidebar />
              )}
              {unassigned && roomList && (
                <SidebarItem filterCompare="No_Room">
                  {t("sidebar.no_room")}
                </SidebarItem>
              )}
            </SidebarGroup>
          </ul>
        </nav>
      </div>
    </>
  );
}

export const SidebarGroup = ({ title, count, children }) => {
  return (
    <details className="group open:transition open:duration-300" open>
      <summary className="px-4 py-3 mx-4 list-none flex flex-wrap items-center cursor-pointer focus-visible:outline-none rounded group-open:rounded-b-none relative text-fgray-300 ">
        <Chevron className=" group-open:rotate-180 transition duration-300" />
        <h3 className="font-medium text-left">
          {title} ({count || 0})
        </h3>
      </summary>
      <ul className="open:transition open:duration-300">{children}</ul>
    </details>
  );
};
export default Sidebar;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MultilineTranslation from "../layout/MultilineTranslation";
import OffboardModal from "./OffboardModal";
function Offboard() {
  const { t } = useTranslation("global");
  const [offboardModal, setOffboardModal] = useState(false);

  const toggleOffboardModal = () => {
    setOffboardModal(!offboardModal);
  };

  return (
    <div className="w-full">
      <MultilineTranslation
        text={t("pages.settings.account.offboard.explanation_a")}
      />
      <span className="text-red-600 font-medium">
        {t("pages.settings.account.offboard.explanation_b")}</span>
      
      <div className="my-4 flex">
        <button
          className={`flex bg-forange-400  rounded-lg text-base font-medium py-3 px-5 focus:outline-none  text-black mx-1  hover:text-white `}
          id="sync"
          onClick={() =>
            window.open("mailto:support@numiato.de?subject=Offboard Question")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
            />
          </svg>

          <span>{t("pages.settings.offboard.contact")}</span>
        </button>
        <button
          className={`flex bg-fgray-100  rounded-lg text-base font-medium py-3 px-5 focus:outline-none  text-black mx-1 hover:bg-red-600 hover:text-white `}
          id="sync"
          onClick={toggleOffboardModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
          <span>{t("pages.settings.offboard.remove_all")}</span>
        </button>
      </div>
      {offboardModal && <OffboardModal toggle={toggleOffboardModal} />}
    </div>
  );
}

export default Offboard;

import React from "react";
import { useTranslation } from "react-i18next";
import MultilineTranslation from "./../layout/MultilineTranslation";

function AdminAuthorization({ authURL }) {
  const { t } = useTranslation("global");
  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between ">
          <div className="text-2xl font-medium">
            {t("pages.authentication.authorization.title")}
          </div>
        </div>
      </div>
      {/*Main Container*/}
      <div className="p-6 max-w-6xl mx-auto space-y-6 overflow-y-auto overflow-x-hidden">
      <MultilineTranslation text={t("pages.authentication.authorization.body")} />
        
      </div>
    </>
  );
}

export default AdminAuthorization;

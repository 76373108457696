import React from "react";
import { Link } from "react-router-dom";

function ResultLine({ result, query, num }) {
  // query = "TOP-";
  const deviceName = result.deviceName.slice(0, 20);
  const upn = result.userPrincipalName.slice(0, 40);
  const name_split = deviceName.split(new RegExp(query, "i"));
  const upn_split = upn.split(new RegExp(query, "i"));
  const casedQueryName = deviceName.substring(
    deviceName.toLowerCase().indexOf(query.toLowerCase()),
    deviceName.toLowerCase().indexOf(query.toLowerCase()) + query.length
  );
  const casedQueryUpn = upn.substring(
    upn.toLowerCase().indexOf(query.toLowerCase()),
    upn.toLowerCase().indexOf(query.toLowerCase()) + query.length
  );
  return (
    <Link to={`/device/${result.id}/`}>
      <div
        className={
          `w-full p-2 justify-between bg-white flex hover:bg-forange-200 ` +
          (num === 0 && ` rounded-t-lg`)
        }
      >
        <p>
          {name_split.map((chunk, index) => (
            <span key={`name${index}`}>
              <span>{chunk}</span>
              <span className="text-forange-400">
                {index < name_split.length - 1 && casedQueryName}
              </span>
            </span>
          ))}
        </p>
        <p>
          {upn_split.map((chunk, index) => (
            <span key={`upn${index}`}>
              <span>{chunk}</span>
              <span className="text-forange-400">
                {index < upn_split.length - 1 && casedQueryUpn}
              </span>
            </span>
          ))}
        </p>
      </div>
    </Link>
  );
}

export default ResultLine;

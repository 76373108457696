import React from 'react';
import SignInButton from '../components/SignInButton';
import { useTranslation } from  'react-i18next';
import Footer from '../components/Footer';
function LoginPage() {
  const { t } = useTranslation("global");
  
  return (
    <>
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="shadow-md px-24 py-12 ">
          <div className="flex text-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 my-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <title>Login</title>
  <path d="M12 14l9-5-9-5-9 5 9 5z" />
  <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
</svg></div>
        
          <h1 className="self-center text-center text-xl font-strong">
            {t("pages.login.login")}
          </h1>

          <div className="flex text-center justify-center focus:border-none hover:border-none py-4">
          <SignInButton/>
          </div>
          <div className='text-xs max-w-md text-fgray-400 text-justify'>{t("pages.home.description")}</div>
        </div>
      </div>
      <Footer />

    </>);
}

export default LoginPage;

import React, { useState, useRef, useEffect } from "react";
import Chip from "./design/Chip";
import Field from "./design/Field";
import ActionButton from "./buttons/ActionButton";
import { useTranslation } from "react-i18next";
import BaseButton from "./buttons/BaseButton";
import { useToggleComponent } from "../../tools/hooks";
import Combobox from "./design/Combobox";
import { ReactComponent as ClearFilterIcon } from "../icons/remove.svg";
import IconButton from "./buttons/IconButton";
function FilterChip({ label, setFilter, options, clear }) {
  const { t } = useTranslation("global");
  const [expanded, setExpanded] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [labelText, setLabelText] = useState("");
  const [filterMode, setFilterMode] = useState("contains");
  const wrapperRef = useRef(null);
  useToggleComponent(wrapperRef, setExpanded);
  const toggle = () => {
    setExpanded(!expanded);
  };
  const clearFilter = () => {
    clean();
    toggle();
  };

  const clean = () => {
    setLabelText("");
    setFilter && setFilter("");
    setFilterMode("contains");
    setFilterValue("");
  };
  useEffect(() => {
    clean();
  }, [clear]);

  const applyFilter = () => {
    setLabelText(filterValue);
    if (options) {
      setFilter && setFilter(filterValue);
    } else {
      setFilter && setFilter(handleFilterMode(filterValue));
    }
    toggle();
  };
  const handleCancel = () => {
    if (filterValue == "") {
      setFilterValue("");
    }
    toggle();
  };
  const handleFilterMode = () => {
    switch (filterMode) {
      case "contains":
        return `*${filterValue}*`;
        break;
      case "startsWith":
        return `${filterValue}*`;
        break;
      case "endsWith":
        return `*${filterValue}`;
        break;
    }
  };
  const FilterCombobox = () => {
    return (
      <>
        <select
          onChange={(e) => setFilterMode(e.target.value)}
          className="w-full rounded-lg border border-fgray-300 py-2 px-4 mb-2"
        >
          <option value="contains" selected={filterMode === "contains"}>
            {t("filter.contains")}
          </option>
          <option value="startsWith" selected={filterMode === "startsWith"}>
            {t("filter.starts_with")}
          </option>
          <option value="endsWith" selected={filterMode === "endsWith"}>
            {t("filter.ends_with")}
          </option>
        </select>
      </>
    );
  };
  return (
    <>
      <div className="relative" ref={wrapperRef}>
        <div>
          <Chip arrow onClick={() => setExpanded((p) => !p)}>
            {t(label, label)}
            {labelText && `: ${labelText}`}
          </Chip>
        </div>

        {expanded && (
          <div className="px-5 py-2 absolute w-96 float border shadow-lg rounded-lg bg-white z-20 cursor-pointer">
            {options ? (
              <>
                {label && (
                  <div className="font-normal text-sx text-fgray-300 my-1">
                    {`${t(label, label)} ${t("words.is")}:`}
                  </div>
                )}
                <Combobox options={options} setSelected={setFilterValue} />
              </>
            ) : (
              <>
                <div className="font-normal text-sx text-fgray-300 my-1">
                  {t(label, label)}
                  {/* {t("filter.filter_mode")} */}
                </div>
                <FilterCombobox />
                <Field
                  // title={`${t(label, label)} ${t("words.contains")}:`}
                  title=" "
                  onChange={(e) => setFilterValue(e.target.value)}
                  value={filterValue}
                  autoFocus
                  onEnter={applyFilter}
                />
              </>
            )}
            <div className="flex my-5 justify-between">
              <div>
                <IconButton
                  Icon={ClearFilterIcon}
                  subtle
                  onClick={clearFilter}
                />
              </div>
              <div className="flex  justify-end">
                <ActionButton subtle onClick={handleCancel}>
                  {t("words.cancel")}
                </ActionButton>
                <BaseButton onClick={applyFilter}>
                  {t("words.apply")}
                </BaseButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FilterChip;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleSummary from "../layout/CollapsibleSummary";
import Profile from "./Profile";
import { postApi } from "../../tools/axiosInstances";
function Objekt({ id, policies, scope, mode, reload, setReload }) {
  const { t } = useTranslation("global");
  const [revertable, setRevertable] = useState(false);
  const checkRevertable = (rev) => {
    setRevertable((p) => p || rev);
  };
  useEffect(() => {
    setRevertable(false);
  }, [scope, reload]);

  const submitRestore = () => {
    let req_body = {
      kind: "unm",
      target: "pol",
      detail: {
        object: id,
        scope: scope,
      },
    };
    postApi("/tasks/", req_body).then((response) => {
    });
  };

  return (
    <>
      {
        <CollapsibleSummary
          reversable={revertable}
          title={t(`policy.${id}.displayName`)}
          triggerRestore={submitRestore}
          setReload={setReload}
        >
          {policies &&
            policies.map((profile, index) => (
              <Profile
                key={`prf.${index}`}
                profile={profile}
                scope={scope}
                mode={mode}
                reload={reload}
                checkRevertable={checkRevertable}
              ></Profile>
            ))}
        </CollapsibleSummary>
      }
    </>
  );
}

export default Objekt;

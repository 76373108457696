import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import TasksTable from "../components/progress/TasksTable";
import TaskCollapsible from "../components/progress/TaskCollapsible";
import LoadingTable from "../components/layout/loading/LoadingTable";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { PortalContext } from "../PortalContext";
import { useCheckLicenseStatus } from "../tools/utilties";
function Progress() {
  const { apiToken } = useContext(PortalContext);
  const [taskList, setTaskList] = useState(false);
  const { t } = useTranslation("global");
  let socket = null;
  useCheckLicenseStatus();
  useEffect(() => {
    socket = new W3CWebSocket(
      `${process.env.REACT_APP_WS_ENDPOINT}/ws/notification/${apiToken}/`
    );

    socket.onopen = () => {};
    socket.onmessage = (message) => {
      setTaskList(JSON.parse(message.data).tasks);
    };
    return () => {
      socket = null;
    };
  }, [apiToken]);

  return (
    <>
      {/*Title Bar*/}
      <div className="w-full shadow-sm">
        <div className="mx-auto max-w-6xl pt-11 pb-4 px-4 flex justify-between ">
          <div className="text-2xl font-medium">
            {t("pages.progress.title")}
          </div>
          <div className="justify-end">{/* Buttons goes here */}</div>
        </div>
      </div>
      {/* Content */}
      <div className="mx-auto max-w-6xl m-5 overflow-auto mb-16">
        {taskList ? (<>
        <div className="flex px-4 py-2 text-fgray-300">
          <p className="w-72 px-8">{t('pages.progress.table.time')}</p>
          <p className="px-7">{t('pages.progress.table.action')}</p>
        </div>
          {taskList.map((task, index) => (
            <TaskCollapsible task={task} id={`tskColl_${index}`} />
          ))}</>
        ) : (
          <LoadingTable />
        )}
        {/* {taskList ? <TasksTable tasksData={taskList} /> : <LoadingTable />} */}
      </div>
    </>
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default Progress;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../layout/buttons/ActionButton";
import ModalCard, {
  ModalCardTitle,
  ModalCardButtons,
  ModalCardBody,
} from "../layout/ModalCard";
import { useToggleComponent } from "../../tools/hooks";
import StandardButton from "../layout/buttons/StandardButton";
import { postApi } from "../../tools/axiosInstances";
import Tooltip from "../layout/tooltip/Tooltip";
function PrinterActionButton({
  target,
  kind,
  title,
  selected,
  Icon,
  tooltip,
  clear,
  filter,
  more,
  ...attributes
}) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const toggle = () => selected.length > 0 && setDisplayModal((v) => !v);
  const hide = () => setDisplayModal(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, hide);
  const action = () => {
    switch (kind) {
      case "asg":
        return "assign";
        break;
      case "usg":
        return "unassign";
        break;
      case "unm":
        return "unmanage";
        break;
      case "del":
        return "delete";
        break;
      default:
        return "assign";
        break;
    }
  };
  const handleActionOnClick = () => {
    setLoading(true);
    let reqBody = { kind: kind, target: target, detail: [] };
    selected.map((printer) => {
      reqBody.detail.push({
        printerId: printer.original?.id || printer.id,
        target: filter,
      });
    });
    postApi("/tasks/", reqBody).then((response) => {
      toggle();
      setLoading(false);
      clear && clear();
    });
  };
  return (
    <>
      {more ? (
        <Tooltip title={tooltip} {...attributes}>
          <button
            className="p-2 flex m-auto w-full hover:font-medium font-normal hover:bg-forange-100 hover:text-forange-400 hover:border-forange-400 rounded"
            onClick={toggle}
            id={`${target}_${kind}`}
          >
            <Icon className="h-6 w-6 mr-2" />
            {t(title, title)}
          </button>
        </Tooltip>
      ) : (
        <ActionButton
          id={`${target}_${kind}`}
          Icon={Icon}
          onClick={toggle}
          tooltip={tooltip}
          subtle
        >
          {t(title, title)}
        </ActionButton>
      )}
      {displayModal && selected.length > 0 && (
        <ModalCard wrapper={wrapper}>
          <ModalCardTitle>
            {t(`pages.printer.modal.title_${action()}`, {
              target: selected.map(
                (row) => row.original?.displayName || row.displayName
              ),
              count: selected.length,
            })}
          </ModalCardTitle>
          <ModalCardBody>
            {t(`pages.printer.modal.confirm_${action()}`, {
              target: selected.map(
                (row) => row.original?.displayName || row.displayName
              ),
              count: selected.length,
            })}
          </ModalCardBody>
          <ModalCardButtons>
            <StandardButton onClick={toggle} subtle>
              {t("actions.cancel")}
            </StandardButton>
            <StandardButton onClick={handleActionOnClick} loading={loading}>
              {t(`actions.${action()}`)}
            </StandardButton>
          </ModalCardButtons>
        </ModalCard>
      )}
    </>
  );
}

export default PrinterActionButton;

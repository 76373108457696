import react from "react";
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  UncertainCompatible,
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes,
} from "@silevis/reactgrid";
import { ReactComponent as OK } from "../../icons/ok.svg";
import { ReactComponent as Error } from "../../icons/warning.svg";

export class StatusCellTemplate {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, "text", "string");
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }
  handleKeyDown(cell, keycode, ctrl, shift, alt) {
    if (!ctrl && !alt & isAlphaNumericKey(keycode))
      return { cell, enableEditMode: true };
    return {
      cell,
      enableEditMode: keycode === keyCodes.POINTER || keycode == keyCodes.ENTER,
    };
  }
  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }
  render(cell, isInEditMode, onCellChanged) {
    if (!isInEditMode) {
      switch (cell.text.toLowerCase()) {
        case "hhs":
        case "smm":
        case "mpi":
          return (
            <div className="h-6">
              <OK className="h-6 w-6 text-fstatus-200" />
            </div>
          );
          break;
        case "unk":
          return <Error className="h-6 w-6 text-red-600" />;
          break;
        default:
          return <></>;
          break;
      }
    }
    return <></>
    return (
      <input
        ref={(input) => {
          input && input.focus();
        }}
        defaultValue={cell.text}
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            false
          )
        }
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
        }}
      />
    );
  }
}
